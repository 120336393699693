import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { authApi } from "./api/authApi";

import { userApi } from "./api/userApi";
import userReducer from "./features/userSlice";
import domainReducer from "./features/domainSlice";
import accountReducer from "./features/accountSlice";

import { newsApi } from "./api/newsApi";
import { domainApi } from "./api/domainApi";
import { typesApi } from "./api/typesApi";
import { subscriptionApi } from "./api/subscriptionApi";
import { metricsApi } from "./api/metricsApi";
import { accountsApi } from "./api/accountsApi";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [typesApi.reducerPath]: typesApi.reducer,
    [metricsApi.reducerPath]: metricsApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    // Connect the PostApi reducer to the store

    [domainApi.reducerPath]: domainApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    userState: userReducer,
    domainState: domainReducer,
    accountState: accountReducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      userApi.middleware,
      // Add the PostApi middleware to the store

      newsApi.middleware,
      typesApi.middleware,
      metricsApi.middleware,
      domainApi.middleware,
      subscriptionApi.middleware,
      accountsApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
