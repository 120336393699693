import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import AuthMiddleware from "./Helpers/AuthMiddleware";
import { store } from "./redux/store";
import "./App.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { i18n } from "./i18n/i18n";
import ScrollToTop from "./hooks/scrollToTop";

let theme = createTheme({
  typography: {
    fontFamily: `"Anek Telugu", sans-serif`,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <CookiesProvider>
            <AuthMiddleware>
              <App />
            </AuthMiddleware>
          </CookiesProvider>
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
