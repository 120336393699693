import { Box, Container, Grid, styled, Toolbar } from "@mui/material";

import "./news-list.scss";
import { useNavigate } from "react-router-dom";

import "./home-page.scss";
import KpiCard, { CardType } from "../components/KpiCard";
import { metricsApi, useSearchNewsMutation } from "../redux/api/metricsApi";

import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchField from "../components/SearchField";
import FullScreenLoader from "../components/FullScreenLoader";
import { CustomTable, TableType } from "../components/CustomTable";
import { IGetMetricsRequest, IGetMetricsResponse } from "../redux/api/types";
import ComponentLoader from "../components/ComponentLoader";
import { useAppDispatch, useAppSelector } from "../redux/store";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 60,
    paddingBottom: 14,
  },
}));

const formatDate = (date: Date) => {
  if (date) {
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }
};

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 15);

  const [startDate, setStartDate] = useState<Date>(currentDate);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isDateModified, setIsDateModified] = useState<boolean>(false);
  const [kpisByNewsId, setKpisByNewsId] = useState<any>();
  const [totalLoads, setTotalLoads] = useState<number>(0);
  const [totalPlays, setTotalPlays] = useState<number>(0);
  const [totalQuarters, setTotalQuarters] = useState<number[]>([]);
  const [playerNews, setPlayersNews] = useState<number>(0);
  const [mostListenedNew, setMostListenedNew] = useState<string>();
  const [totalListened, setTotalListened] = useState<number[]>();
  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [filteredResult, setFilteredResult] = useState<IGetMetricsResponse>();

  const [searchNew, { error, isSuccess, isError }] = useSearchNewsMutation();
  // const dispatcher = useAppDispatch();
  const currentDomain = useAppSelector(
    (state) => state.domainState.currentDomain
  );
  const domainStr = sessionStorage.getItem("currentDomain");
  const domainJson =
    domainStr && domainStr !== "" ? JSON.parse(domainStr) : null;
  const accountStr = sessionStorage.getItem("currentAccount");
  const username = sessionStorage.getItem("username");

  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;

  const { data, isLoading, isFetching } =
    metricsApi.endpoints.getMetrics.useQuery(
      {
        account_filter: accountJson?.id,
        account_id: accountJson?.id,
        domain_filter: domainJson?.id,
        metrics_fecha_fin: formatDate(endDate),
        metrics_fecha_inicio: formatDate(startDate),
        page: 1,
        limit: 100,
      },
      {
        skip: !accountJson?.id || !endDate || !startDate,
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter" && searchText && searchText !== "") {
        setLoading(true);
        event.preventDefault();
        const body = {
          account_filter: accountJson?.id,
          account_id: accountJson?.id,
          domain_filter: domainJson?.id,
          news_id: searchText,
          metrics_fecha_fin: formatDate(endDate),
          metrics_fecha_inicio: formatDate(startDate),
          page: 1,
          limit: 100,
        } as IGetMetricsRequest;

        searchNew(body)
          .then((res: any) => {
            setFilteredResult(res.data as IGetMetricsResponse);
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [searchText, searchNew]);

  useEffect(() => {
    // refetch();
    if (filteredResult?.result) {
      buildStateVars(filteredResult);
    } else if (data && data?.result) {
      buildStateVars(data);
    }
  }, [data, filteredResult]);

  useEffect(() => {
    if (
      formatDate(startDate) !== formatDate(currentDate) ||
      formatDate(endDate) !== formatDate(new Date())
    ) {
      setIsDateModified(true);
    } else {
      setIsDateModified(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!startDate) {
      setStartDate(currentDate);
    }
    if (!endDate) {
      setEndDate(new Date());
    }
  }, [startDate, endDate]);

  const buildStateVars = (values: IGetMetricsResponse) => {
    const result = values.result;
    const bigNumbers = result.kpis_big_numbers;
    setKpisByNewsId(result.kpis_by_news_id);
    setTotalLoads(bigNumbers.loads);
    setTotalPlays(bigNumbers.plays);
    setTotalQuarters([
      bigNumbers.q1,
      bigNumbers.q2,
      bigNumbers.q3,
      bigNumbers.q4,
    ]);
    setPlayersNews(bigNumbers.news_with_player);
    setMostListenedNew(bigNumbers.most_listened_news);
    setTotalListened(getTotalListened(bigNumbers.total_listening_time));
  };
  const emptyStateVars = () => {
    setKpisByNewsId("-");
    setTotalLoads(0);
    setTotalPlays(0);
    setTotalQuarters([0, 0, 0, 0]);
    setPlayersNews(0);
    setMostListenedNew("-");
    setTotalListened(getTotalListened(0));
  };

  const getTotalListened = (totalTime: number) => {
    const values = [] as number[];
    let seconds = totalTime;
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;
    values.push(hours);
    values.push(minutes);
    values.push(seconds);
    return values;
  };

  const handleSearch = (event: any) => {
    const newId = event.target.value;
    setSearchText(newId);
  };

  const startSearchHandler = () => {
    if (endDate && startDate) {
      setLoading(true);

      const body = {
        account_filter: accountJson?.id,
        account_id: accountJson?.id,
        domain_filter: domainJson?.id,
        news_id: searchText,
        metrics_fecha_fin: formatDate(endDate),
        metrics_fecha_inicio: formatDate(startDate),
        page: 1,
        limit: 100,
      } as IGetMetricsRequest;

      searchNew(body)
        .then((res: any) => {
          setFilteredResult(res.data as IGetMetricsResponse);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const clearHandler = () => {
    setLoading(true);
    setSearchText("");
    const body = {
      account_filter: accountJson?.id,
      account_id: accountJson?.id,
      domain_filter: domainJson?.id,
      metrics_fecha_fin: formatDate(endDate),
      metrics_fecha_inicio: formatDate(startDate),
      page: 1,
      limit: 100,
    } as IGetMetricsRequest;
    searchNew(body)
      .then((res: any) => {
        setFilteredResult(res.data as IGetMetricsResponse);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  const handleListAudio = () => {
    navigate("/news");
  };

  if (loading || isLoading || isFetching) {
    return <FullScreenLoader />;
  }
  return (
    <Container maxWidth={false} className="home_page">
      <Container>
        <Box display={"flex"} flexDirection={"column"}>
          <StyledToolbar disableGutters>
            <Box display={"flex"} flexDirection={"column"}>
              <span className="header_txt">Hola, {username}</span>
              <Box display={"flex"} alignItems={"flex-end"} pt={2}>
                <Box display={"column"}>
                  <span className="datepicker_label">Fecha inicio</span>

                  <DatePicker
                    selected={startDate}
                    clearButtonClassName={"clear_button"}
                    isClearable={isDateModified}
                    onChange={(date: Date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat={"dd/MM/yyyy"}
                    className="picker_input"
                  />
                </Box>

                <Box display={"flex"} flexDirection={"column"}>
                  <span className="datepicker_label">Hasta</span>
                  <DatePicker
                    selected={endDate}
                    isClearable={isDateModified}
                    onChange={(date: Date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat={"dd/MM/yyyy"}
                    className="picker_input"
                  />
                </Box>
                <SearchField
                  onSearch={handleSearch}
                  onClickSearch={startSearchHandler}
                  onClickClear={clearHandler}
                  currentValue={searchText}
                  label={"Buscar..."}
                />
              </Box>
            </Box>
          </StyledToolbar>
          {loading ? null : (
            <Grid
              container
              spacing={2}
              direction={"row"}
              alignItems={"stretch"}
              sx={{ flexGrow: 1 }}
            >
              <Grid item xs={2}>
                <KpiCard
                  key={1}
                  topText="Loads Totales"
                  bottomNumber={totalLoads}
                  type={CardType.kpiNumber}
                />
              </Grid>
              <Grid item xs={2}>
                <KpiCard
                  key={2}
                  topText="Plays totales"
                  bottomNumber={totalPlays}
                  type={CardType.kpiNumber}
                />
              </Grid>
              <Grid item xs={2}>
                <KpiCard
                  key={3}
                  topText="Totales"
                  quarterValues={totalQuarters}
                  type={CardType.quarters}
                />
              </Grid>
              <Grid item xs={2}>
                <KpiCard
                  key={4}
                  topText="Noticias con player"
                  bottomNumber={playerNews}
                  type={CardType.kpiNumber}
                />
              </Grid>
              <Grid
                item
                xs={2}
                onClick={handleListAudio}
                sx={{
                  cursor: "pointer",
                }}
              >
                <KpiCard
                  key={5}
                  topText="Noticia más escuchada en las últimas 24h"
                  type={CardType.kpiString}
                  bottomText={mostListenedNew}
                />
              </Grid>
              <Grid item xs={2}>
                <KpiCard
                  key={6}
                  topText="Tiempo total de escucha"
                  quarterValues={totalListened}
                  type={CardType.timers}
                />
              </Grid>
              {/* <Box width={"33%"}>
            <KpiCard
              topText="Listado de Audios"
              bottomText="Consultar"
              icon={CardIconType.arrow}
              type={CardType.button}
              action={handleListAudio}
            />
          </Box> */}
            </Grid>
          )}
        </Box>
        <Box mt={"32px"} display={"flex"} flexDirection={"column"}>
          <Box pb={1}>
            <span className="subheader_txt">Noticias más escuchadas</span>
          </Box>
          {loading ? (
            <ComponentLoader />
          ) : (
            <CustomTable
              values={kpisByNewsId}
              type={TableType.dashboard}
              checkbox={false}
            />
          )}
          {/* </Box> */}
        </Box>
      </Container>
      {/* <Box className="img_container"> */}

    </Container>
  );
};

export default Home;
