import {
  Box,
  Container,
  Toolbar,
  styled,
  TextField,
  Modal,
  Backdrop,
  Fade,
  Slider,
  Stack,
  Switch,
} from "@mui/material";
import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomButton, {
  ButtonIconType,
  ButtonSize,
  ButtonVariant,
} from "../components/button/CustomButton";
import DomainList, { ListType } from "../components/CustomList";
// import FullScreenLoader from "../components/FullScreenLoader";
import { domainApi } from "../redux/api/domainApi";
import {
  extractionTypes,
  IDomain,
  InputDropdown,
  ITypesVoice,
  IUpdateDomainRequest,
} from "../redux/api/types";
import { useAppDispatch, useAppSelector } from "../redux/store";
import "./account-management.scss";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

import CloseIcon from "@mui/icons-material/Close";
import Dropdown, { BarSize, BarVariant } from "../components/Dropdown";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { boolean, number, object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput, { FormInputType } from "../components/FormInput";
import { typesApi } from "../redux/api/typesApi";
import { accountsApi } from "../redux/api/accountsApi";
import FullScreenLoader from "../components/FullScreenLoader";
import { subscriptionApi } from "../redux/api/subscriptionApi";
import { userApi } from "../redux/api/userApi";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 60,
    paddingBottom: 14,
  },
}));

const modalStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  paddingTop: 4,
  paddingLeft: 4,
  paddingRight: 4,
  bgcolor: "#FFFFFF",
  boxShadow: "0px 2px 8px rgba(39, 65, 95, 0.2)",
  borderRadius: "8px",
};

const LoadingButton = styled(_LoadingButton)`
  border-radius: 8px;
  padding: 0.6rem 0;
  background-color: #001834;
  color: #ffffff;
  font-weight: 500;
  text-transform: none;

  &:hover {
    background-color: #ffc700;
    box-shadow: none;
    color: #001834;
  }
  ,
  &:active {
    background-color: #27415f;
    box-shadow: none;
    color: #ffffff;
  }
`;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#F9FBFF",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#001834",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const newDomainSchema = object({
  domain: string().min(1, "Debes introducir el nombre del dominio").max(100),
  object_id: string()
    .min(1, "Debes introducir el ID del objeto")
    .max(100)
    .optional(),
});
const editSchema = object({});

export type NewDomainInput = TypeOf<typeof newDomainSchema>;
export type DomainEditInput = TypeOf<typeof editSchema>;

const AccountManagement = () => {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pitch, setPitch] = useState(0);
  const [speed, setSpeed] = useState(1);
  const [tag, setTag] = useState(false);
  const [automaticPublish, setAutomaticPublis] = useState(false);
  const [pitchModal, setPitchModal] = useState(0);
  const [speedModal, setSpeedModal] = useState(1);
  const [voices, setVoices] = useState<InputDropdown[]>([]);
  const [voiceTypes, setVoiceTypes] = useState<InputDropdown[]>([]);
  const [selectedVoice, setSelectedVoice] = useState<InputDropdown>();
  const [selectedExtractionType, setSelectedExtractionType] =
    useState<InputDropdown>();
  const [selectedVoiceModal, setSelectedVoiceModal] = useState<InputDropdown>();
  const [selectedVoiceType, setSelectedVoiceType] = useState<InputDropdown>();
  const [selectedVoiceTypeModal, setSelectedVoiceTypeModal] =
    useState<InputDropdown>();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  // const domains = useAppSelector((state: any) => state.domainState.domains);
  const [openModal, setOpenModal] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const domainStr = sessionStorage.getItem("currentDomain");
  const domainJson =
    domainStr && domainStr !== "" ? JSON.parse(domainStr) : null;
  const accountStr = sessionStorage.getItem("currentAccount");
  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;
  const [editDomainSelected, setEditDomainSelected] =
    useState<IDomain | null>();
  const userId = sessionStorage.getItem("user_id");
  const domainsSelector = useAppSelector((state) => state.domainState.domains);
  const accountsSelector = useAppSelector(
    (state) => state.accountState.accounts
  );
  const handleOpen = () => {
    // setSelectedExtractionType(null)
    // setSelectedVoiceType(null)
    // setSelectedVoice(null)
    setTag(false);
    setAutomaticPublis(false);
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);

  const methods = useForm<NewDomainInput>({
    resolver: zodResolver(newDomainSchema),
  });

  const methodsEdit = useForm<NewDomainInput>({
    resolver: zodResolver(editSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;
  const { reset: resetEdit, handleSubmit: handleSubmitEdit } = methodsEdit;

  const handleOpenEdit = (item: IDomain) => {
    console.log(item);
    setEditDomainSelected(item);
    setOpenEditModal(true);
  };
  const handleCloseEdit = () => {
    setEditDomainSelected(null);
    setOpenEditModal(false);
  };

  const queryMultiple = () => {
    const res1 = domainApi.endpoints.listDomains.useQuery(
      {
        account_id: accountJson?.id,
        origin_request: "account",
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res2 = accountsApi.endpoints.getAccounts.useQuery(
      {
        user_id: userId ? userId : "",
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res3 = typesApi.endpoints.getT2params.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res4 = subscriptionApi.endpoints.getSubscriptionPlans.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res5 = subscriptionApi.endpoints.getCurrentPeriodEnd.useQuery(
      {
        account_id: accountJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res6 = userApi.endpoints.getUserData.useQuery(
      {
        account_id: accountJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res7 = userApi.endpoints.getUserRole.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    return [
      res1 as any,
      res2 as any,
      res3 as any,
      res4 as any,
      res5 as any,
      res6 as any,
      res7 as any,
    ];
  };

  const [
    { isLoading: loading1, data: res1, refetch: refetchDomain },
    { isLoading: loading2, data: res2, refetch: refetchAccounts },
    { isLoading: loading3, data: res3, refetch: refetchParams },
    { isLoading: loading4, data: res4, refetch: refetchSus },
    { isLoading: loading5, data: res5, refetch: refetchPrice },
    { isLoading: loading6, data: res6, refetch: refetchUserData },
    { isLoading: loading7, data: res7, refetch: refetchUserRole },
  ] = queryMultiple();

  useEffect(() => {
    setVoiceTypes([]);
    if (res3?.result?.voice?.length) {
      const mapped = res3?.result?.voice.map((val: any, index: any) => {
        return createVoiceType(val, index);
      });
      setVoiceTypes(mapped);

      if (
        res3?.result?.default_values &&
        Object.keys(res3?.result?.default_values).length > 0
      ) {
        const defaultValues = res3.result.default_values as any;
        setPitch(
          defaultValues.default_pitch
            ? parseInt(defaultValues.default_pitch)
            : 0
        );
        setSpeed(
          defaultValues.default_speed
            ? parseInt(defaultValues.default_speed)
            : 1
        );
        const checkType = mapped.filter(
          (v: any) => v.name === defaultValues.default_voice_type
        )[0];
        setSelectedVoiceType(checkType);
        setSelectedVoice({
          id: "",
          name: defaultValues.default_voice_name,
        });
      }
    }
  }, [res3]);

  useEffect(() => {
    if (res7 && (res7.role === "viewer" || res7.role === "editor")) {
      navigate("/");
    }
  }, [res7, domainsSelector, accountsSelector]);

  useEffect(() => {
    setVoices([]);
    if (selectedVoiceType) {
      buildVoices(selectedVoiceType);
    }
  }, [selectedVoiceType]);

  useEffect(() => {
    if (editDomainSelected && editDomainSelected.voice_type) {
      buildVoices({
        id: "",
        name: editDomainSelected.voice_type,
      });
    }
  }, [editDomainSelected]);

  useEffect(() => {
    setVoices([]);
    if (selectedVoiceTypeModal) {
      buildVoices(selectedVoiceTypeModal);
    }
  }, [selectedVoiceTypeModal]);

  const buildVoices = (voice: InputDropdown) => {
    const filtered = res3?.result?.voice.filter(
      (vt: any) => vt.voice_type === voice.name
    );

    if (filtered?.length) {
      const mapped = filtered[0].voice_name.map((val: any, index: any) => {
        return createVoice(val, index);
      });

      setVoices(mapped);
    } else {
      setVoices([]);
    }
  };

  function createVoiceType(value: ITypesVoice, index: number): InputDropdown {
    return {
      id: index.toString(),
      name: value.voice_type,
    };
  }

  function createVoice(value: string, index: number): InputDropdown {
    return {
      id: index.toString(),
      name: value,
    };
  }

  const handleOnSwitchDomain = async (domain: IDomain, value: string) => {
    console.log("eepp", domain);
    if (domain) {
      let newStatus = value ? "enabled" : "disabled";
      setLoading(true);
      const res = await dispatch(
        domainApi.endpoints.updateDomain.initiate(
          {
            account_id: accountJson?.id,
            domain_id: domain.domain_id,
            domain_status: newStatus,
            domain_name: domain.domain_name,
          },
          { forceRefetch: true }
        )
      );
      await refetchDomain();
      // if (res.isSuccess) {
      //   toast.success('Dominio actualizado correctamente', {
      //     position: "top-right",
      //   });
      // } else {
      //   toast.error('No se ha podido actualizado el dominio', {
      //     position: "top-right",
      //   });
      // }
      setLoading(false);
    }
  };

  const handleUpdateSubscription = () => {
    navigate("/account/subscription");
  };

  const handleChangeSpeed = (event: any, value: any) => {
    setSpeed(value);
  };
  const handleChangePitch = (event: any, value: any) => {
    setPitch(value);
  };

  const handleChangeSpeedModal = (event: any, value: any) => {
    setSpeedModal(value);
  };
  const handleChangePitchModal = (event: any, value: any) => {
    setPitchModal(value);
  };

  const handleChangePitchEditModal = (event: any, value: any) => {
    setEditDomainSelected(
      (prevState) =>
      ({
        ...prevState,
        pitch: event.target.value,
      } as IDomain)
    );
  };

  const handleChangeSpeedEditModal = (event: any, value: any) => {
    setEditDomainSelected(
      (prevState) =>
      ({
        ...prevState,
        speed: event.target.value,
      } as IDomain)
    );
  };

  const onSwitchTag = (checked: boolean) => {
    setTag(checked);
  };

  const onSwitchPublish = (checked: boolean) => {
    setAutomaticPublis(checked);
  };

  const handleUpdateAudio = async () => {
    const res = await dispatch(
      accountsApi.endpoints.setAccountParams.initiate({
        account_id: accountJson?.id,
        default_pitch: pitch.toString(),
        default_speed: speed.toString(),
        default_voice_type: selectedVoiceType ? selectedVoiceType?.name : "",
        default_voice_name: selectedVoice ? selectedVoice?.name : "",
      })
    );
    if (res.isSuccess) {
      toast.success("Audio actualizado correctamente", {
        position: "top-right",
      });
    } else {
      toast.error("No se ha podido actualizar el audio", {
        position: "top-right",
      });
    }
  };

  const getCurrentVoiceType = () => {
    return selectedVoiceType;
  };

  const getCurrentVoice = () => {
    return selectedVoice;
  };

  const getVoiceTypeValue = () => {
    if (editDomainSelected) {
      return voiceTypes.filter(
        (vt) => vt.name === editDomainSelected.voice_type
      )[0]?.name;
    }
    return null;
  };

  const getVoiceValue = () => {
    if (editDomainSelected) {
      return editDomainSelected.voice_name;
    }
    return null;
  };

  const onSubmitHandler: SubmitHandler<NewDomainInput> = async (values) => {
    setButtonLoading(true);
    const res = await dispatch(
      domainApi.endpoints.addDomain.initiate({
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
        domain_name: values.domain,
        domain_extraction_type: selectedExtractionType?.name,
        domain_text_object_id: values.object_id,
        remove_tags_html: tag,
        automatic_publication: automaticPublish,
        domain_default_voice_name: selectedVoiceModal?.name,
        domain_default_voice_type: selectedVoiceTypeModal?.name,
        domain_default_pitch: pitchModal,
        domain_default_speed: speedModal,
      })
    );
    setButtonLoading(false);
    if (res.isSuccess) {
      toast.success("Dominio creado correctamente", {
        position: "top-right",
      });
    } else {
      toast.error("No se ha podido crear el dominio", {
        position: "top-right",
      });
    }
    refetchDomain();
    handleClose();
  };

  const onSubmitUpdateHandler: SubmitHandler<any> = async (values) => {
    if (editDomainSelected) {
      setButtonLoading(true);

      const res = await dispatch(
        domainApi.endpoints.updateDomain.initiate({
          account_id: accountJson?.id,
          domain_id: editDomainSelected.domain_id,
          domain_name: editDomainSelected.domain_name,
          domain_extraction_type: editDomainSelected.extraction_type,
          domain_text_object_id: editDomainSelected.extraction_object,
          domain_default_voice_type: editDomainSelected.voice_type,
          domain_default_voice_name: editDomainSelected.voice_name,
          domain_default_pitch: editDomainSelected.pitch,
          domain_default_speed: editDomainSelected.speed,
          automatic_publication: editDomainSelected.automatic_publication,
          remove_tags_html: editDomainSelected.strip_tags,
        })
      );
      setButtonLoading(false);
      if (res.isSuccess) {
        toast.success("Dominio actualizado correctamente", {
          position: "top-right",
        });
      } else {
        toast.error("No se ha podido actualizado el dominio", {
          position: "top-right",
        });
      }
      refetchDomain();
      handleCloseEdit();
    }
  };

  const getCurrentPrice = () => {
    if (res4 && res4.plans && res4.actual_plan_id) {
      return res4.plans.filter((p: any) => p.plan_id === res4.actual_plan_id)[0]
        ?.plan_alias;
    }
    return null;
  };

  if (
    loading ||
    loading1 ||
    loading2 ||
    loading3 ||
    loading4 ||
    loading5 ||
    loading6 ||
    loading7
  ) {
    return <FullScreenLoader />;
  } else {
    return (
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: "#ECF2F9",
          overflow: "hidden",
          paddingTop: 10,
          paddingRight: "10% !important",
          paddingLeft: "5% !important",
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            paddingTop: "30px",
            paddingLeft: "8% !important",
            height: "100vh",
          }}
        >
          <StyledToolbar disableGutters>
            <span className="header_txt">
              {/* {`${t("news-create.create-audio")}`} */}
              Gestión de cuenta
            </span>
          </StyledToolbar>

          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Box className="box_container_account">
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                pb={2}
              >
                <Box>
                  <span className="subheader_txt">Gestión de dominios</span>
                </Box>

                <CustomButton
                  variant={ButtonVariant.primary}
                  action={handleOpen}
                  size={ButtonSize.large}
                  icon={ButtonIconType.plus}
                  text={"Añadir nuevo"}
                />
              </Box>
              <DomainList
                values={res1?.domains}
                onEdit={handleOpenEdit}
                type={ListType.domain}
                onSwitch={handleOnSwitchDomain}
              />
            </Box>
            <Box className="box_container_account">
              <span className="subheader_txt">Audio</span>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <Box className="box_selector" pb={"20px"} pt={3}>
                  <Box className="selector" mr={"20px"}>
                    <span className="top_label">Tipo de voz</span>
                    <Dropdown
                      label="Voz"
                      onChange={(voiceTypeInput: InputDropdown) =>
                        setSelectedVoiceType(voiceTypeInput)
                      }
                      values={voiceTypes}
                      defaultSelected={getCurrentVoiceType()}
                      key={1}
                      multiple={false}
                      size={BarSize.full}
                      variant={BarVariant.form}
                    />
                  </Box>
                  <Box className="selector">
                    <span className="top_label">Voz</span>
                    <Dropdown
                      label="Voz"
                      onChange={(voiceInput: InputDropdown) =>
                        setSelectedVoice(voiceInput)
                      }
                      defaultSelected={getCurrentVoice()}
                      values={voices}
                      key={2}
                      multiple={false}
                      size={BarSize.full}
                      variant={BarVariant.form}
                    />
                  </Box>
                </Box>

                <Box className="box_selector">
                  <Box className="selector" mr={"20px"}>
                    <span className="top_label">Pitch</span>
                    <Slider
                      value={pitch}
                      valueLabelDisplay="auto"
                      max={20}
                      min={-20}
                      onChange={(event, value) =>
                        handleChangePitch(event, value)
                      }
                      size={"small"}
                      sx={{
                        color: "#27415F",
                      }}
                    />
                  </Box>
                  <Box className="selector">
                    <span className="top_label">Velocidad</span>
                    <Slider
                      value={speed}
                      max={4}
                      valueLabelDisplay="auto"
                      min={0.25}
                      onChange={(event, value) =>
                        handleChangeSpeed(event, value)
                      }
                      size={"small"}
                      sx={{
                        color: "#27415F",
                      }}
                    />
                  </Box>
                </Box>
                <Box display={"flex"} justifyContent={"end"} mt={"30px"}>
                  <CustomButton
                    variant={ButtonVariant.primary}
                    size={ButtonSize.small}
                    text={"Actualizar Audio"}
                    action={handleUpdateAudio}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
            }}
          >
            <Box className="box_bottom_container">
              <span className="subheader_txt">
                {/* {`${t("news-create.create-audio")}`} */}
                Usuario principal
              </span>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "100%" },
                }}
                noValidate
                pt={"20px"}
                pb={"20px"}
                autoComplete="off"
              >
                <span className="top_label">Nombre del usuario</span>
                <TextField
                  placeholder="Nombre"
                  disabled={true}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "20px",
                    "& .MuiOutlinedInput-root": {
                      height: "45px",
                      "& fieldset": {
                        borderColor: "#27415F",
                      },
                      // '&:hover fieldset': {
                      //   borderColor: 'white',
                      // },
                      // '&.Mui-focused fieldset': {
                      //   borderColor: 'yellow',
                      // },
                    },
                  }}
                  value={res6?.owner_name}
                />
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Box width={"100%"} mr={"20px"}>
                    <span className="top_label">Cargo</span>
                    <TextField
                      placeholder="Cargo"
                      disabled={true}
                      value={res6?.owner_cargo}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "20px",
                        "& .MuiOutlinedInput-root": {
                          height: "45px",
                          "& fieldset": {
                            borderColor: "#27415F",
                          },
                          // '&:hover fieldset': {
                          //   borderColor: 'white',
                          // },
                          // '&.Mui-focused fieldset': {
                          //   borderColor: 'yellow',
                          // },
                        },
                      }}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <span className="top_label">E-mail</span>
                    <TextField
                      placeholder="E-mail"
                      disabled={true}
                      value={res6?.owner_email}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "20px",
                        "& .MuiOutlinedInput-root": {
                          height: "45px",
                          "& fieldset": {
                            borderColor: "#27415F",
                          },
                          // '&:hover fieldset': {
                          //   borderColor: 'white',
                          // },
                          // '&.Mui-focused fieldset': {
                          //   borderColor: 'yellow',
                          // },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="box_bottom_container right">
              <span className="subheader_txt">
                {/* {`${t("news-create.create-audio")}`} */}
                Suscripción activa
              </span>
              <Box
                display={"flex"}
                pt={"20px"}
                pb={"20px"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Box display={"flex"} flexDirection={"column"} width={"50%"}>
                  <span className="top_label">Tipo de plan</span>
                  <span className="non_editable_field">
                    {getCurrentPrice()}
                  </span>
                </Box>
                <Box display={"flex"} flexDirection={"column"} width={"50%"}>
                  <span className="top_label">Precio</span>
                  <span className="non_editable_field">
                    {res4?.actual_plan_pricing}€/mes
                  </span>
                </Box>
              </Box>
              <Box
                display={"flex"}
                pt={"20px"}
                pb={"20px"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Box display={"flex"} flexDirection={"column"} width={"50%"}>
                  <span className="top_label">Fecha de renovación</span>
                  <span className="non_editable_field">
                    {res5?.end_subscription_date}
                  </span>
                </Box>
              </Box>
              {res7?.role !== "admin" ? (
                <Box display={"flex"} mt={"30px"} width={"50%"}>
                  <CustomButton
                    variant={ButtonVariant.primary}
                    size={ButtonSize.large}
                    fullSize={true}
                    text={"Actualizar suscripción"}
                    action={handleUpdateSubscription}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
        </Container>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          disableAutoFocus={true}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Box sx={modalStyle}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Box>
                  <span className="subheader_txt">Añadir nuevo dominio</span>
                </Box>
                <Box>
                  <CloseIcon className="close_icon" onClick={handleClose} />
                </Box>
              </Box>
              <FormProvider {...methods}>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmitHandler)}
                  noValidate
                  autoComplete="off"
                  alignItems={"center"}
                  maxWidth="27rem"
                  width="100%"
                  pt={"20px"}
                  pb={"20px"}
                >
                  <FormInput
                    formType={FormInputType.input}
                    name="domain"
                    label="Dominio"
                    className="input_txt"
                    type="domain"
                    placeholder="Escribe el dominio"
                    defaultValue={""}
                  />
                  <FormInput
                    formType={FormInputType.dropdown}
                    valuesDropdown={extractionTypes}
                    selectedDropdown={selectedExtractionType}
                    onChangeAction={(extInput: InputDropdown) => {
                      setSelectedExtractionType(extInput);
                    }}
                    name="extraction"
                    label="Tipo de extracción"
                    className="input_txt"
                    type="extraction"
                    placeholder="Escribe el tipo de extracción"
                  />
                  {selectedExtractionType?.name === "html_object" ? (
                    <FormInput
                      formType={FormInputType.input}
                      name="object_id"
                      label="ID del objeto"
                      className="input_txt"
                      type="object_id"
                      placeholder="Escribe el ID del objeto"
                      defaultValue={""}
                    />
                  ) : null}

                  <FormInput
                    name="voice_type"
                    label="Tipo de voz"
                    className="input_txt"
                    type="voice_type"
                    formType={FormInputType.dropdown}
                    valuesDropdown={voiceTypes}
                    selectedDropdown={selectedVoiceTypeModal}
                    onChangeAction={(voiceTypeInput: InputDropdown) =>
                      setSelectedVoiceTypeModal(voiceTypeInput)
                    }
                  />

                  <FormInput
                    name="voice"
                    label="Voz"
                    className="input_txt"
                    type="voice"
                    formType={FormInputType.dropdown}
                    selectedDropdown={selectedVoiceModal}
                    valuesDropdown={voices}
                    onChangeAction={(voiceInput: InputDropdown) =>
                      setSelectedVoiceModal(voiceInput)
                    }
                  />

                  <FormInput
                    name="pitch"
                    label="Pitch"
                    className="input_txt"
                    type="pitch"
                    maxValue={20}
                    minValue={-20}
                    valuePitch={pitchModal}
                    formType={FormInputType.slider}
                    onChangeAction={(event: any, value: any) =>
                      handleChangePitchModal(event, value)
                    }
                  />
                  <FormInput
                    name="speed"
                    label="Velocidad"
                    className="input_txt"
                    type="speed"
                    maxValue={4}
                    minValue={0.25}
                    valuePitch={speedModal}
                    formType={FormInputType.slider}
                    onChangeAction={(event: any, value: any) =>
                      handleChangeSpeedModal(event, value)
                    }
                  />

                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    mt={2}
                    mb={2}
                  >
                    <Box display={"flex"} flexDirection={"column"}>
                      <span className="top_label">Strip tags automático</span>
                      <AntSwitch
                        checked={tag}
                        value={"off"}
                        onChange={(e) => onSwitchTag(e.target.checked)}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <span className="top_label">
                        Publicación automática de noticias
                      </span>

                      <AntSwitch
                        checked={automaticPublish}
                        value={"off"}
                        onChange={(e) => onSwitchPublish(e.target.checked)}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                    </Box>
                  </Stack>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    disableElevation
                    type="submit"
                    loading={buttonLoading}
                  >
                    Añadir dominio
                  </LoadingButton>
                  {/* <Box display={"flex"} width={"100%"}>
                  <CustomButton
                    variant={ButtonVariant.primary}
                    size={ButtonSize.large}
                    fullSize={true}
                    text={"Añadir nuevo dominio"}
                    action={handleAddDomain}
                  />
                </Box> */}
                </Box>
              </FormProvider>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          disableAutoFocus={true}
          open={openEditModal}
          onClose={handleCloseEdit}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openEditModal}>
            <Box sx={modalStyle}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Box>
                  <span className="subheader_txt">Editar dominio</span>
                </Box>
                <Box>
                  <CloseIcon className="close_icon" onClick={handleCloseEdit} />
                </Box>
              </Box>

              <FormProvider {...methodsEdit}>
                <Box
                  component="form"
                  onSubmit={handleSubmitEdit(onSubmitUpdateHandler)}
                  noValidate
                  autoComplete="off"
                  alignItems={"center"}
                  maxWidth="27rem"
                  width="100%"
                  pt={"20px"}
                  pb={"20px"}
                >
                  <FormInput
                    formType={FormInputType.input}
                    name="domain"
                    label="Dominio"
                    className="input_txt"
                    type="domain"
                    placeholder="Escribe el dominio"
                    value={editDomainSelected?.domain_name}
                    onChange={(e) => {
                      setEditDomainSelected(
                        (prevState) =>
                        ({
                          ...prevState,
                          domain_name: e.target.value,
                        } as IDomain)
                      );
                    }}
                  />
                  <FormInput
                    formType={FormInputType.dropdown}
                    name="extraction"
                    label="Tipo de extracción"
                    className="input_txt"
                    type="extraction"
                    placeholder="Escribe el tipo de extracción"
                    valuesDropdown={extractionTypes}
                    selectedDropdown={editDomainSelected?.extraction_type}
                    onChangeAction={(e: any) => {
                      setEditDomainSelected(
                        (prevState) =>
                        ({
                          ...prevState,
                          extraction_type: e.name,
                        } as IDomain)
                      );
                    }}
                  />
                  {editDomainSelected?.extraction_type === "html_object" ? (
                    <FormInput
                      formType={FormInputType.input}
                      name="object_id"
                      label="ID del objeto"
                      className="input_txt"
                      type="object_id"
                      placeholder="Escribe el ID del objeto"
                      value={editDomainSelected?.extraction_object}
                      onChange={(e) => {
                        setEditDomainSelected(
                          (prevState) =>
                          ({
                            ...prevState,
                            extraction_object: e.target.value,
                          } as IDomain)
                        );
                      }}
                    />
                  ) : null}

                  <FormInput
                    name="voice_type"
                    label="Tipo de voz"
                    className="input_txt"
                    type="voice_type"
                    formType={FormInputType.dropdown}
                    valuesDropdown={voiceTypes}
                    selectedDropdown={getVoiceTypeValue()}
                    // value={getVoiceTypeValue()}
                    onChangeAction={(voiceTypeInput: InputDropdown) =>
                      setEditDomainSelected(
                        (prevState) =>
                        ({
                          ...prevState,
                          voice_type: voiceTypeInput.name,
                        } as IDomain)
                      )
                    }
                  />

                  <FormInput
                    name="voice"
                    label="Voz"
                    className="input_txt"
                    type="voice"
                    formType={FormInputType.dropdown}
                    valuesDropdown={voices}
                    selectedDropdown={getVoiceValue()}
                    onChangeAction={(voiceInput: InputDropdown) =>
                      setEditDomainSelected(
                        (prevState) =>
                        ({
                          ...prevState,
                          voice_name: voiceInput.name,
                        } as IDomain)
                      )
                    }
                  />

                  <FormInput
                    name="pitch"
                    label="Pitch"
                    className="input_txt"
                    type="pitch"
                    formType={FormInputType.slider}
                    onChangeAction={(event: any, value: any) =>
                      handleChangePitchEditModal(event, value)
                    }
                    valuePitch={
                      editDomainSelected?.pitch ? editDomainSelected?.pitch : 0
                    }
                    maxValue={20}
                    minValue={-20}
                  />
                  <FormInput
                    name="speed"
                    label="Velocidad"
                    className="input_txt"
                    type="speed"
                    formType={FormInputType.slider}
                    valuePitch={
                      editDomainSelected?.speed ? editDomainSelected?.speed : 1
                    }
                    onChangeAction={(event: any, value: any) =>
                      handleChangeSpeedEditModal(event, value)
                    }
                    maxValue={20}
                    minValue={-20}
                  />

                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    mt={2}
                    mb={2}
                  >
                    <Box display={"flex"} flexDirection={"column"}>
                      <span className="top_label">Strip tags automático</span>
                      <AntSwitch
                        checked={editDomainSelected?.strip_tags}
                        value={"off"}
                        onChange={(e) => {
                          setEditDomainSelected(
                            (prevState) =>
                            ({
                              ...prevState,
                              strip_tags: e.target.checked,
                            } as IDomain)
                          );
                        }}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <span className="top_label">
                        Publicación automática de noticias
                      </span>

                      <AntSwitch
                        checked={editDomainSelected?.automatic_publication}
                        value={"off"}
                        onChange={(e) => {
                          setEditDomainSelected(
                            (prevState) =>
                            ({
                              ...prevState,
                              automatic_publication: e.target.checked,
                            } as IDomain)
                          );
                        }}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                    </Box>
                  </Stack>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    disableElevation
                    type="submit"
                    loading={buttonLoading}
                  >
                    Actualizar dominio
                  </LoadingButton>
                  {/* <Box display={"flex"} width={"100%"}>
                  <CustomButton
                    variant={ButtonVariant.primary}
                    size={ButtonSize.large}
                    fullSize={true}
                    text={"Añadir nuevo dominio"}
                    action={handleAddDomain}
                  />
                </Box> */}
                </Box>
              </FormProvider>
            </Box>
          </Fade>
        </Modal>
      </Container>
    );
  }
};

export default withTranslation()(AccountManagement);
