import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { logout } from "../features/userSlice";

const baseUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/`;

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  mode: "cors",
  baseUrl,
});

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result: any = await baseQuery(args, api, extraOptions);
  if ((result.error?.data as any)?.message === "You are not logged in") {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          { credentials: "include", url: "auth/refresh" },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          // Retry the initial query
          result = await baseQuery(args, api, extraOptions);
          // console.log(result)
        } else {
          api.dispatch(logout());
          window.location.href = "/login";
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
      // console.log(result)
    }
  } else {
    // console.log('RES', result)
    // console.log('API', api)
    if (result.error?.status === 401) {
      // sessionStorage.setItem('token', '')
    } else {
      const data = result.data;
      if (data?.token && data?.user_id) {
        sessionStorage.setItem("token", data.token);
      }
      if (data?.user_id) {
        sessionStorage.setItem("user_id", data.user_id);
      }
      if (data?.user_name) {
        sessionStorage.setItem("username", data.user_name);
      }
    }
  }
  // console.log(result)

  return result;
};

export default customFetchBase;
