import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout";
import LoginPage from "./pages/login.page";
import RegisterPage from "./pages/register.page";
import UnauthorizePage from "./pages/unauthorize.page";
import RequireUser from "./components/requireUser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetPasswordPage from "./pages/reset-password.page";
import ForgotPasswordPage from "./pages/forgot-password.page";
import NewsList from "./pages/news-list";
import NewsCreate from "./pages/news-create";
import Home from "./pages/home.page";
import NewsEdit from "./pages/news-edit";
import AccountManagement from "./pages/account-management";
import UserManagement from "./pages/user-management";
import ChangeSubscription from "./pages/change-subscription";
import useWindowDimensions from "./hooks/useWindowDimensions";
import AccountManagementAdmin from "./pages/account-management-admin";
import UserManagementAdmin from "./pages/user-management-admin";
import DomainManagementAdmin from "./pages/domain-management-admin";

function App() {
  const { height, width } = useWindowDimensions();

  return (
    <>
      <CssBaseline />
      <ToastContainer
        position="top-left"
        autoClose={1000}
        hideProgressBar={true}
      // style={{ width: width, top: '45px', position: 'absolute' }}
      />
      <Routes>
        {/* Private Route */}
        <Route element={<RequireUser allowedRoles={["user", "admin"]} />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/news" element={<NewsList />} />
            <Route path="/news/create" element={<NewsCreate />} />
            {/* <Route path="/news/edit" element={<NewsEdit />} /> */}
            <Route path="/news/edit/:id" element={<NewsEdit />} />
            <Route
              path="account/subscription"
              element={<ChangeSubscription />}
            />
            <Route path="/account" element={<AccountManagement />} />
            <Route path="/users" element={<UserManagement />} />
            <Route path="/account-admin" element={<AccountManagementAdmin />} />
            <Route path="/users-admin" element={<UserManagementAdmin />} />
            <Route path="/domains-admin" element={<DomainManagementAdmin />} />
          </Route>
          <Route path="unauthorized" element={<UnauthorizePage />} />
        </Route>
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="resetpassword" element={<ResetPasswordPage />}>
          <Route path=":resetToken" element={<ResetPasswordPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
