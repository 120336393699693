import React, { useEffect, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./Dropdown.styles.scss";
import InputBase from "@mui/material/InputBase";

import { styled } from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";
import { InputDropdown } from "../redux/api/types";
import { useAppSelector } from "../redux/store";

interface Props {
  label?: string;
  multiple: boolean;
  values: InputDropdown[];
  onChange: Function;
  size: BarSize;
  variant: BarVariant;
  icon?: any;
  deselect?: boolean;
  height?: number;
  defaultSelected?: any;
}

export enum BarVariant {
  filters,
  header,
  account,
  selector,
  form,
  img,
}

export enum BarSize {
  full = "100%",
  medium = 14,
  small = 12,
  xsmall = 10,
}

const StyledSelect = styled(InputBase)(({ theme }) => ({
  margin: "0px !important",
  "& .MuiInputBase-input": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px rgba(39, 65, 95, 0.2)",
    radius: "4px",
    color: "#001834",
    padding: "9px 12px 9px 12px",
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "& .MuiSelect-icon": {
    color: "#8A98A9",
    width: "16px",
  },
  "& .MuiMenuItem-root": {
    background: "red",
  },
}));

const StyledImgSelect = styled(InputBase)(({ theme }) => ({
  margin: "0px !important",

  "& .MuiInputBase-input": {
    background: "rgba(52, 52, 52, 0)",
    radius: "4px",
    color: "rgba(52, 52, 52, 0)",
    width: "5px",
    height: "30px",
    position: "absolute",
    padding: "9px 12px 9px 12px",
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  // "& .MuiList-root":{

  // },
}));

const StyledHeader = styled(InputBase)(({ theme }) => ({
  width: "225px",
  "& .MuiButtonBase-root-MuiMenuItem-root": {
    background: "pink",
    border: "0.5px solid white",
  },
  "& .MuiButtonBase-root": {
    background: "red",
    border: "0.5px solid white",

    "& .MuiMenuItem-root": {
      background: "green",
    },
  },
  "& .MuiInputBase-input": {
    background: "#001834",
    border: "0.5px solid white",
    radius: "4px",
    color: "white",
    padding: "9px 12px 9px 12px",
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    height: "30px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "& .MuiTypography-input": {
    fontSize: "11px",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
}));

const StyledHeaderAccount = styled(InputBase)(({ theme }) => ({
  width: "120px",
  right: "20px",

  // "& .MuiMenuItem-root.Mui-selected:hover": {
  //   backgroundColor: "#EFF2F6 !important",
  //   color: "#8A98A9 !important",
  // },
  "& .MuiButtonBase-root-MuiMenuItem-root": {
    background: "pink",
    border: "0.5px solid white",
  },
  "& .MuiButtonBase-root": {
    background: "red",

    border: "0.5px solid white",

    "& .MuiMenuItem-root": {
      background: "green",
    },
  },
  "& .MuiInputBase-input": {
    background: "#001834",
    border: "0.5px solid white",
    radius: "4px",
    color: "white",
    padding: "9px 12px 9px 12px",
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    height: "31px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "& .MuiTypography-input": {
    fontSize: "11px",
  },
  "& .MuiTypography-root": {
    // fontSize: "11px",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
}));

const StyledFilterSelect = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px rgba(39, 65, 95, 0.2)",
    radius: "4px",
    color: "#001834",
    // height: "35px",
    padding: "2px 0px 2px 5px",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "& .MuiSelect-icon": {
    color: "#8A98A9",
    width: "16px",
  },
  "&.	.MuiMenuItem-root": {
    background: "red",
  },
}));

const StyledFormSelect = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    background: "#FFFFFF",
    height: "25px",
    display: "flex",
    alignItems: "center",
    border: "0.5px solid #27415F",
    radius: "4px",
    color: "#8A98A9",
    padding: "9px 12px 9px 12px",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  "&:selected": {
    boxShadow: "none",
    backgroundColor: "#27415F",
    color: "#FFFFFF",
  },
  "& .MuiSelect-icon": {
    color: "#8A98A9",
    width: "16px",
  },

  // "& .MuiInputBase-input": {
  //   backgroundColor: "#27415F",
  //   color: '#FFFFFF',
  // },
}));

const getPropsMenu = (variant: BarVariant) => {
  switch (variant) {
    case BarVariant.selector:
      return {
        PaperProps: {
          sx: {
            "& .MuiMenuItem-root": {
              color: "#8A98A9",
            },
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: "#27415F",
              color: "#FFFFFF",
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "#EFF2F6",
            },
            "& .MuiMenuItem-root.Mui-selected:hover": {
              backgroundColor: "#EFF2F6",
            },
            "&. MuiListItemText-root": {
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "11px",
              lineHeight: "15px",
              color: "#8A98A9",
            },
          },
        },
      };
    case BarVariant.img:
      return {
        PaperProps: {
          sx: {
            "& .MuiMenuItem-root": {
              width: "200px",
            },

            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: "#FFFFFF",
              // color: "#FFFFFF",
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "#EFF2F6",
            },
            "& .MuiMenuItem-root.Mui-selected:hover": {
              backgroundColor: "#EFF2F6",
            },

            "&. MuiListItemText-root": {
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "11px",
              lineHeight: "15px",
              color: "#8A98A9",
            },
          },
        },
      };
    case BarVariant.filters:
      return {
        PaperProps: {
          sx: {
            "& .MuiMenuItem-root": {
              color: "#8A98A9",
            },
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: "#27415F",
              color: "#FFFFFF",
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "#EFF2F6",
            },
            "& .MuiMenuItem-root.Mui-selected:hover": {
              backgroundColor: "#EFF2F6",
            },
            "&. MuiListItemText-root": {
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "11px",
              lineHeight: "15px",
              color: "#8A98A9",
            },
          },
        },
      };
    case BarVariant.form:
      return {
        PaperProps: {
          sx: {
            "& .MuiMenuItem-root": {
              color: "#8A98A9",
            },
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: "#27415F",
              color: "#FFFFFF",
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "#EFF2F6",
            },
            "& .MuiMenuItem-root.Mui-selected:hover": {
              backgroundColor: "#EFF2F6",
            },
            "&. MuiListItemText-root": {
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "11px",
              lineHeight: "15px",
              color: "#8A98A9",
            },
          },
        },
      };
    default:
      return {
        PaperProps: {
          sx: {
            "& .MuiMenuItem-root": {
              color: "#8A98A9",
            },
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: "#27415F",
              color: "#FFFFFF",
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: "EFF2F6",
            },
            "& .MuiMenuItem-root.Mui-selected:hover": {
              backgroundColor: "#EFF2F6",
              color: '#8A98A9'
            },
            "&. MuiListItemText-root": {
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "11px",
              lineHeight: "15px",
              color: "#8A98A9",
            },
          },
        },
      };
  }
};

const getLabelClass = (state: string) => {
  switch (state) {
    case "Activo":
      return "inside_txt active";
    case "Procesando":
      return "inside_txt processing";
    case "Error":
      return "inside_txt error";
    case "Borrador":
      return "inside_txt draft";
    case "Inactivo":
      return "inside_txt inactive";
    case "--":
      return "inside_txt none";
    default:
      return "";
  }
};

const Dropdown: React.FC<Props> = ({
  label,
  values,
  onChange,
  multiple,
  size,
  variant,
  icon,
  deselect,
  height,
  defaultSelected,
}) => {
  const [current, setCurrent] = useState<any>();
  const isAdmin = useAppSelector((state) => state.userState.isAdmin);

  const handleChange = (event: SelectChangeEvent) => {
    if (deselect) {
      const {
        target: { value },
      } = event;
      let filtered = values.find((v) => v.name === value);
      // setSelected('')
      onChange(filtered);
    } else {
      const {
        target: { value },
      } = event;
      let filtered = values.find((v) => v.name === value);
      onChange(filtered);
    }
  };
  const getWidth = (size: BarSize) => {
    switch (size) {
      case BarSize.full:
        return "100%";
      case BarSize.medium:
        return 300;
      case BarSize.small:
        return 100;
      default:
        return 50;
    }
  };

  const getInputVariant = (variant: BarVariant) => {
    switch (variant) {
      case BarVariant.selector:
        return <StyledSelect />;
      case BarVariant.filters:
        return <StyledFilterSelect />;
      case BarVariant.form:
        return <StyledFormSelect />;
      case BarVariant.img:
        return <StyledImgSelect />;
      case BarVariant.account:
        return <StyledHeaderAccount />;
      default:
        return <StyledHeader />;
    }
  };

  const getDefaultValue = () => {
    if (variant === BarVariant.header) {
      if (sessionStorage.getItem("currentDomain")) {
        let current = sessionStorage.getItem("currentDomain");
        if (current) {
          const ss = JSON.parse(current);
          return ss.name;
        } else {
          return null;
        }
      }
    } else if (variant === BarVariant.account) {
      if (sessionStorage.getItem("currentAccount")) {
        let current = sessionStorage.getItem("currentAccount");
        if (current) {
          const ss = JSON.parse(current);
          return ss.name;
        } else {
          return null;
        }
      }
    } else if (variant === BarVariant.form && defaultSelected) {
      return defaultSelected.name ? defaultSelected.name : defaultSelected;
    } else if (variant === BarVariant.img) {
      return null;
    } else if (variant === BarVariant.filters && defaultSelected) {
      return defaultSelected.name;
    }
  };

  return (
    <div className="dropdown">
      <FormControl sx={{ mr: 1, width: getWidth(size), pt: -3 }}>
        <Select
          variant="outlined"
          MenuProps={getPropsMenu(variant)}
          input={getInputVariant(variant)}
          defaultValue={getDefaultValue() ? getDefaultValue() : " "}
          defaultChecked={false}
          value={getDefaultValue() ? getDefaultValue() : null}
          IconComponent={icon ? icon : ExpandMoreRounded}
          onChange={handleChange}
        >
          {values
            ? values.map((value, index) => (
              <MenuItem
                key={index}
                value={value.name}
                disabled={value.name === "Admin"}
              >
                {/* {multiple ? (
                <Checkbox checked={personName.indexOf(name) > -1} />

              ) : null} */}
                <ListItemText
                  primary={value.name}
                  className={getLabelClass(value.name)}
                  sx={
                    variant === BarVariant.filters
                      ? {
                        "& .MuiTypography-root": {
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "12px",
                          lineHeight: "12px",
                          letterSpacing: "0.05em",
                        },
                      }
                      : null
                  }
                />
              </MenuItem>
            ))
            : null}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
