import { createApi } from "@reduxjs/toolkit/query/react";
import { setDomains } from "../features/domainSlice";
import customFetchBase from "./customFetchBase";
import {
  IAddDomainRequest,
  IAddDomainResponse,
  IDomainListRequest,
  IDomainListResponse,
  IUpdateDomainRequest,
} from "./types";

export const domainApi = createApi({
  reducerPath: "domainApi",
  baseQuery: customFetchBase,
  tagTypes: ["Domain"],
  endpoints: (builder) => ({
    listDomainsHeader: builder.query<IDomainListResponse, IDomainListRequest>({
      query(body) {
        return {
          url: `/domain_list`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
      transformResponse: (result: IDomainListResponse) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setDomains(data.domains));
        } catch (err: any) {
          if (err.error.status === 401) {
            window.location.href = "/login";
          }
          console.error(err);
        }
      },
    }),
    listDomains: builder.query<IDomainListResponse, IDomainListRequest>({
      query(body) {
        return {
          url: `/domain_list`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    updateDomain: builder.query<IDomainListResponse, IUpdateDomainRequest>({
      query(body) {
        return {
          url: `/domain_update`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    listDomainsSu: builder.query<IDomainListResponse, any>({
      query(body) {
        return {
          url: `/su/list_domains`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    addDomain: builder.query<IAddDomainResponse, IAddDomainRequest>({
      query(body) {
        return {
          url: `/domain_add`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
  }),
});

export const {
  useListDomainsQuery,
  useUpdateDomainQuery,
  useAddDomainQuery,
  useListDomainsSuQuery,
  useListDomainsHeaderQuery,
} = domainApi;
