import { createApi } from "@reduxjs/toolkit/query/react";
import { LoginInput } from "../../pages/login.page";
import { RegisterInput } from "../../pages/register.page";
import { setIsAdmin } from "../features/userSlice";
import customFetchBase from "./customFetchBase";
import {
  GenericResponse,
  ICheckAdminResponse,
  IResetPasswordRequest,
  IValidateAccountRequest,
  IValidateAccountResponse,
} from "./types";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    registerUser: builder.mutation<GenericResponse, RegisterInput>({
      query(data) {
        return {
          url: "register_account",
          method: "POST",
          body: data,
        };
      },
    }),
    validateAccount: builder.mutation<
      IValidateAccountResponse,
      IValidateAccountRequest
    >({
      query(data) {
        return {
          url: "validate_account",
          method: "POST",
          body: data,
        };
      },
    }),
    loginUser: builder.mutation<
      { message: string; token: string; su: string },
      LoginInput
    >({
      query(data) {
        let defaultUser = {
          email: data.email,
          pass: data.password,
        };

        return {
          url: "auth_user",
          method: "POST",
          body: defaultUser,
          // headers: {
          //   'Content-Type': 'application/json',
          //   "Access-Control-Allow-Headers": "*",
          //   "Access-Control-Allow-Origin": "*",
          //   "Access-Control-Allow-Methods": "*"
          // },
          // mode: 'cors',
          // credentials: 'include'
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIsAdmin(data.su === "true"));
          // await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {}
      },
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        let token = sessionStorage.getItem("token");
        return {
          url: "/logout",
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("user_id");
          sessionStorage.removeItem("currentDomain");
          sessionStorage.removeItem("currentAccount");
          sessionStorage.removeItem("username");
          // await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {}
      },
    }),
    verifyEmail: builder.mutation<GenericResponse, string>({
      query(verificationCode) {
        return {
          url: `auth/verifyemail/${verificationCode}`,
          credentials: "include",
        };
      },
    }),
    forgotPassword: builder.mutation<GenericResponse, { email: string }>({
      query(body) {
        return {
          url: `auth/forgotpassword`,
          method: "POST",
          credentials: "include",
          body,
        };
      },
    }),
    resetPassword: builder.mutation<GenericResponse, { email: string }>({
      query(body) {
        return {
          url: `/reset_password`,
          method: "POST",
          credentials: "include",
          body,
        };
      },
    }),
    checkAdmin: builder.query<ICheckAdminResponse, any>({
      query(body) {
        return {
          url: `/user_is_su`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "application/json",
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useLogoutUserMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useValidateAccountMutation,
  useCheckAdminQuery,
} = authApi;
