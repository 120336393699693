import { Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput, { FormInputType } from "../components/FormInput";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useRegisterUserMutation,
  useValidateAccountMutation,
} from "../redux/api/authApi";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import "./register.page.scss";
import { ReactComponent as Logo } from "../assets/voicetoyou_2.svg";

const LoadingButton = styled(_LoadingButton)`
  border-radius: 0px 0px 8px 8px;
  padding: 0.6rem 0;
  background-color: #001834;
  color: #ffffff;
  font-weight: 500;

  &:hover {
    background-color: #ffc700;
    box-shadow: none;
    color: #001834;
  }
  ,
  &:active {
    background-color: #27415f;
    box-shadow: none;
    color: #ffffff;
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: #2363eb;
  &:hover {
    text-decoration: underline;
  }
`;

const registerSchema = object({
  owner_names: string()
    .min(1, "Debes introducir tu nombre y apellidos")
    .max(100),
  owner_email: string()
    .min(1, "Debes introducir tu correo electrónico")
    .email("Este correo electrónico no es válido"),
  account_name: string()
    .min(1, "Debes introducir el nombre de la cuenta")
    .max(100),
  password: string()
    .min(1, "Debes introducir una contraseña")
    .min(8, "La contraseña debe tener más de 8 carácteres")
    .max(32, "La contraseña debe tener menos de 32 carácteres"),
  passwordConfirm: string().min(1, "Confirme su contraseña"),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "Las contraseñas no coinciden",
});
// .refine((data) => useValidateAccountMutation(data.account_name), {
//   path: ['account_name'],
//   message: 'La cuenta indicada ya existe'
// });

export type RegisterInput = TypeOf<typeof registerSchema>;

const RegisterPage = () => {
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });

  // 👇 Calling the Register Mutation
  const [registerUser, { isLoading, isSuccess, error, isError, data }] =
    useRegisterUserMutation();
  const [validateAccount] = useValidateAccountMutation();

  const navigate = useNavigate();

  const {
    // reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message);
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          })
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      // reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    // 👇 Executing the RegisterUser Mutation
    validateAccount(values).then((res: any) => {
      const accountExists = res?.data?.account_exists;
      if (accountExists) {
        toast.error("La cuenta indicada ya existe.", {
          position: "top-right",
        });
      } else {
        registerUser(values);
      }
    });
  };
  const onSubmitConfirmationHandler = () => {
    navigate("/login");
  };

  if (isSuccess) {
    return (
      <Container maxWidth={false} className={"verify_page"}>
        <Box className="logo">
          <Logo />
        </Box>

        <Box className="inside_container">
          <Typography
            textAlign="center"
            component="h1"
            className="verify_header"
          >
            Cuenta creada correctamente
          </Typography>
          <FormProvider {...methods}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              maxWidth="27rem"
              width="100%"
              mt={3}
              onSubmit={onSubmitConfirmationHandler}
            >
              <Box className="login_box">
                <Typography className="received">
                  Puedes entrar en tu cuenta con tus datos de acceso.
                </Typography>
              </Box>
              <LoadingButton
                variant="contained"
                fullWidth
                disableElevation
                type="submit"
                loading={isLoading}
              >
                Entrar
              </LoadingButton>
            </Box>
          </FormProvider>
        </Box>
      </Container>
    );
  }

  return (
    <Container
      maxWidth={false}
      className="register_page"
    // style={{ backgroundImage: `url(${Background})` }}
    >
      <Box className="logo">
        <Logo />
      </Box>
      <Box className="inside_container">
        <Typography className="login_header" textAlign="center" component="h1">
          Crear cuenta
        </Typography>

        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
            maxWidth="27rem"
            width="100%"
          >
            <Box className="login_box">
              <FormInput
                formType={FormInputType.input}
                name="owner_names"
                label="Nombre y apellidos"
                className="input_txt"
                type="name"
                placeholder="Introduce tu nombre y apellidos"
              />
              <FormInput
                formType={FormInputType.input}
                name="owner_email"
                label="E-mail"
                className="input_txt"
                type="email"
                placeholder="Introduce tu correo electrónico"
              />
              <FormInput
                formType={FormInputType.input}
                name="account_name"
                className="input_txt"
                label="Nombre de la cuenta"
                type="name"
                placeholder="Introduce un nombre de cuenta"
              />
              <FormInput
                formType={FormInputType.input}
                name="password"
                className="input_txt"
                label="Contraseña"
                type="password"
                placeholder="Introduce una contraseña segura"
              />
              <FormInput
                formType={FormInputType.input}
                name="passwordConfirm"
                label="Confirmar contraseña"
                type="password"
                placeholder="Confirma tu contraseña"
                className="input_txt"
              />
            </Box>

            <LoadingButton
              variant="contained"
              fullWidth
              disableElevation
              type="submit"
              loading={isLoading}
            >
              Registrarme
            </LoadingButton>
          </Box>

          <Typography className="no_account" sx={{ mt: "1rem" }}>
            ¿Ya tienes una cuenta?{" "}
            <LinkItem className="login_here" to="/login">
              Entra aquí
            </LinkItem>
          </Typography>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default RegisterPage;
