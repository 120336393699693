import { Box, Container, styled, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import CustomButton, {
  ButtonIconType,
} from "../components/button/CustomButton";
import { ButtonSize, ButtonVariant } from "../components/button/CustomButton";
import Dropdown, { BarSize, BarVariant } from "../components/Dropdown";
import FullScreenLoader from "../components/FullScreenLoader";

import SearchField from "../components/SearchField";
// import { useGetAllPostsQuery } from '../redux/api/postApi';
import "./news-list.scss";
import { useNavigate } from "react-router-dom";
import { newsApi, useListNewsMMutation } from "../redux/api/newsApi";
import {
  IGetMetricsRequest,
  IGetMetricsResponse,
  INewResponse,
  InputDropdown,
  stateValues,
  TableData,
} from "../redux/api/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector } from "../redux/store";
import { domainApi } from "../redux/api/domainApi";
import { CustomTable, TableType } from "../components/CustomTable";
import moment from "moment";
import { useSearchNewsMutation } from "../redux/api/metricsApi";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 60,
    paddingBottom: 14,
  },
}));

const EmptyStateBar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 12,
    gap: 10,
    minHeight: 60,
    paddingBottom: 14,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 8px rgba(39, 65, 95, 0.2)",
    borderRadius: 4,
  },
}));
const FiltersBar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 34,
  },
}));

function createData(value: INewResponse): TableData {
  var createdAt = moment(value.news_created_at, "DD-MM-YYYY, HH:mm:SS");
  var lastUpdate = moment(value.news_created_at, "DD-MM-YYYY, HH:mm:SS");

  const createdDate = new Date(createdAt.toDate());
  const updatedDate = new Date(lastUpdate.toDate());

  return {
    id: value.news_id,
    title: value.news_title,
    created: createdDate,
    updated: updatedDate,
    state: value.news_status,
    plays: value.news_reproductions,
  };
}

const NewsList = () => {
  // const { isLoading, isError, error, data: posts } = useGetAllPostsQuery();
  const navigate = useNavigate();
  const [news, setNews] = useState<TableData[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [finalText, setFinalText] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const domainStr = sessionStorage.getItem("currentDomain");
  const domainJson =
    domainStr && domainStr !== "" ? JSON.parse(domainStr) : null;
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<any>(
    { id: "1", name: "--" }
  );
  const [statusFilter, setStatusFilter] = useState<any>();
  const accountStr = sessionStorage.getItem("currentAccount");
  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;
  const currentDomain = useAppSelector(
    (state) => state.domainState.currentDomain
  );
  const currentAccount = useAppSelector(
    (state) => state.accountState.currentAccont
  );
  const [startSearch, setStartSearch] = useState<boolean>(false);
  // const [searchAudio] = useListNewsMMutation(
  //   {
  //     selectFromResult: ({ data }) => data,
  //   }
  // );

  // const domains = useAppSelector((state) => state.domainState.domains);

  const { data, isLoading, isFetching, refetch } =
    newsApi.endpoints.listNews.useQuery(
      {
        account_id: accountJson?.id,
        domain_filter: domainJson?.id,
        limit: 10000,
        news_id: finalText,
        status_filter: statusFilter,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );

  const buildNews = (newsArray: INewResponse[]) => {
    const mapped = newsArray.map((val) => {
      return createData(val);
    });
    setNews(mapped);
  };

  useEffect(() => {
    if (isLoading || isFetching) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (startSearch) {
      setStartSearch(false);
    }
  }, [startSearch]);

  useEffect(() => {
    if (selectedStatusFilter) {
      const val =
        selectedStatusFilter &&
          selectedStatusFilter.name &&
          selectedStatusFilter.name === "Activo"
          ? "enabled"
          : selectedStatusFilter &&
            selectedStatusFilter.name &&
            selectedStatusFilter.name === "Inactivo" ? "disabled" : undefined;
      setStatusFilter(val);
    } else {
      setStatusFilter(undefined);
    }
  }, [selectedStatusFilter]);
  // useEffect(() => {
  //   refetch();
  // }, [currentDomain, currentAccount]);

  useEffect(() => {
    setNews([]);
    if (data?.results?.length) {
      buildNews(data?.results);
    }
  }, [data]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter" && searchText && searchText !== "") {
        event.preventDefault();
        setStartSearch(true);
        setFinalText(searchText);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [searchText]);

  // useEffect(() => {
  // if (isError) {
  //   if (Array.isArray((error as any).data.error)) {
  //     (error as any).data.error.forEach((el: any) =>
  //       toast.error(el.message, {
  //         position: 'top-right',
  //       })
  //     );
  //   } else {
  //     toast.error((error as any).data.message, {
  //       position: 'top-right',
  //     });
  //   }
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoading]);

  if (loading) {
    return <FullScreenLoader />;
  }

  const handleSearch = (event: any) => {
    const newId = event.target.value;
    setSearchText(newId);
  };
  const handleClick = () => {
    navigate("/news/create");
  };
  const startSearchHandler = () => {
    setStartSearch(true);
    setFinalText(searchText);
  };

  const clearHandler = () => {
    setLoading(true);
    setSearchText("");
    setFinalText("");
    setStartSearch(true);
    setLoading(false);
  };

  const getEmptyState = () => {
    return (
      <EmptyStateBar disableGutters sx={{ mt: 3 }}>
        <Box display="flex" alignItems={"center"}>
          <span className="icon">
            <InfoOutlinedIcon />
          </span>
          <span className="title_bold">El listado de audios está vacío.</span>
          <span className="title"> ¿Deseas crear un nuevo?</span>
        </Box>
      </EmptyStateBar>
    );
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "#ECF2F9",
        height: "100vh",
        paddingTop: 10,
        paddingLeft: "10% !important",
        paddingRight: "10% !important",
      }}
    >
      <StyledToolbar disableGutters sx={{}}>
        <span className="header_txt">Listado de Audios</span>
        <Box display="flex" sx={{ ml: "auto" }}>
          <CustomButton
            variant={ButtonVariant.primary}
            size={ButtonSize.medium}
            icon={ButtonIconType.plus}
            text={"Crear Nuevo"}
            action={handleClick}
          />
        </Box>
      </StyledToolbar>
      {news?.length || statusFilter || finalText ? (
        <FiltersBar disableGutters sx={{ paddingBottom: "8px" }}>
          <Box display="flex" alignItems={"center"}>
            <div>
              <Dropdown
                label="Estado"
                onChange={(stateInput: InputDropdown) => {
                  setSelectedStatusFilter(stateInput);
                }}
                values={stateValues}
                defaultSelected={selectedStatusFilter}
                key={2}
                multiple={false}
                size={BarSize.small}
                variant={BarVariant.filters}
              />
            </div>

            <SearchField
              onSearch={handleSearch}
              onClickSearch={startSearchHandler}
              onClickClear={clearHandler}
              currentValue={searchText}
              label={"Buscar..."}
            />
          </Box>
        </FiltersBar>
      ) : (
        getEmptyState()
      )}
      <CustomTable type={TableType.newList} values={news} checkbox={true} />
    </Container>
  );
};

export default NewsList;
