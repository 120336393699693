import { Add } from "@mui/icons-material";
import { Button, styled } from "@mui/material";
import React from "react";
import "./CustomButton.styles.scss";

export enum ButtonVariant {
    primary,
    secondary,
}
export enum ButtonSize {
    large,
    medium,
    small,
    xsmall,
}
type IButtonProps = {
    variant: ButtonVariant;
    size: ButtonSize;
    text: string;
    icon?: ButtonIconType;
    action: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children?: React.ReactNode;
    disabled?: boolean;
    mr?: number;
    ml?: number;
    fullSize?: boolean;
    width?: number;
    height?: number;
};

export enum ButtonIconType {
    plus,
}

const PrimaryButton = styled(Button)({
    padding: "8px 22px 5px 14px",
    gap: "10px",
    backgroundColor: "#001834",
    borderRadius: "4px",
    textTransform: "capitalize",
    color: "#FFFFFF",

    "&:hover": {
        backgroundColor: "#FFC700",
        boxShadow: "none",
        color: "#001834",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#27415F",
        color: "#FFFFFF",
    },
    "&:focus": {
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const SecondaryButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    minHeight: "45px",
    border: "1px solid #001834",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "30px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#001834",

    "&:hover": {
        backgroundColor: "#001834",
        boxShadow: "none",
        color: "#FFFFFF",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#27415F",
        color: "#FFFFFF",
    },
    "&:focus": {
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const CustomButton: React.FC<IButtonProps> = ({
    variant,
    size,
    text,
    icon,
    action,
    mr,
    ml,
    width,
    height,
    fullSize,
}) => {
    const getIcon = () => {
        switch (icon) {
            case ButtonIconType.plus:
                return <Add />;
            default:
                return null;
        }
    };
    const getWidth = () => {
        if (fullSize) {
            return "100%";
        } else {
            switch (size) {
                case ButtonSize.xsmall:
                    return 144;
                case ButtonSize.small:
                    return 144;
                case ButtonSize.medium:
                    return 144;
                case ButtonSize.large:
                    return 200;
                default:
                    return 144;
            }
        }
    };

    const getHeight = () => {
        switch (size) {
            case ButtonSize.xsmall:
                return 34;
            case ButtonSize.small:
                return 38;
            case ButtonSize.medium:
                return 45;
            case ButtonSize.large:
                return 45;
            default:
                return 45;
        }
    };
    const getButton = () => {
        switch (variant) {
            case ButtonVariant.primary:
                return (
                    <PrimaryButton
                        variant="contained"
                        disableRipple
                        startIcon={getIcon()}
                        onClick={action}
                        sx={{
                            mr: mr ? mr : 0,
                            ml: ml ? ml : 0,
                            width: width ? width : getWidth(),
                            height: height ? height : getHeight(),
                        }}
                    >
                        <span className="button_txt">{text}</span>
                    </PrimaryButton>
                );
            case ButtonVariant.secondary:
                return (
                    <SecondaryButton
                        variant="contained"
                        disableRipple
                        startIcon={getIcon()}
                        onClick={action}
                        sx={{
                            mr: mr ? mr : 0,
                            ml: ml ? ml : 0,
                            width: width ? width : getWidth(),
                            height: height ? height : getHeight(),
                        }}
                    >
                        <span className="button_txt_secondary">{text}</span>
                    </SecondaryButton>
                );
            default:
                return (
                    <PrimaryButton
                        variant="contained"
                        disableRipple
                        startIcon={getIcon()}
                        onClick={action}
                        sx={{
                            mr: mr ? mr : 0,
                            ml: ml ? ml : 0,
                            width: width ? width : getWidth(),
                            height: height ? height : getHeight(),
                        }}
                    >
                        <span className="button_txt">{text}</span>
                    </PrimaryButton>
                );
        }
    };
    return <>{getButton()}</>;
};

export default CustomButton;
