// import { useCookies } from 'react-cookie';
import jwtDecode from "jwt-decode";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import UnauthorizePage from "../pages/unauthorize.page";
import { authApi, useLoginUserMutation } from "../redux/api/authApi";
import { userApi } from "../redux/api/userApi";
import { useAppSelector } from "../redux/store";
import FullScreenLoader from "./FullScreenLoader";
// import { userApi } from '../redux/api/userApi';
// import FullScreenLoader from './FullScreenLoader';
import { useAppDispatch } from "../redux/store";
import { setIsAdmin } from "../redux/features/userSlice";

const RequireUser = ({ allowedRoles }: { allowedRoles: string[] }) => {
  // const [cookies] = useCookies(['logged_in']);

  const location = useLocation();
  console.log(location);
  const token = sessionStorage.getItem("token");
  const dispatch = useAppDispatch();
  //
  if (token) {
    const decoded = jwtDecode(token) as any;
    console.log(decoded);
    const { data, isLoading, isFetching } =
      authApi.endpoints.checkAdmin.useQuery(null, {
        skip: false,
        refetchOnMountOrArgChange: true,
      });

    if (isLoading || isFetching) {
      return <FullScreenLoader />;
    }

    if (data) {
      console.log(data.is_su);
      const isSu = data.is_su === "true";
      dispatch(setIsAdmin(isSu));
    }

    if (decoded && decoded.exp < Date.now() / 1000) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
      console.log(location.pathname);
      if (location.pathname === "/" && data?.is_su === "true") {
        return <Navigate to="/account-admin" />;
      }
      if (location.pathname === "/account" && data?.is_su === "true") {
        return <Navigate to="/account-admin" />;
      }
      if (location.pathname === "/users" && data?.is_su === "true") {
        return <Navigate to="/users-admin" />;
      }

      if (location.pathname.includes("admin")) {
        if (allowedRoles.includes("admin") && data?.is_su === "true") {
          return <Outlet />;
        } else {
          return <UnauthorizePage />;
        }
      } else {
        return <Outlet />;
      }
    }
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireUser;
