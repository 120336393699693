import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import {
  IGetTaxDataRequest,
  IGetUserPaymentDataRequest,
  ISubscriptionPlansRequest,
  ISubscriptionPlansResponse,
  ISubscriptionPlansSuResponse,
  IUpdatePricesRequest,
  IUpdateSubscriptionRequest,
  IUpdateTaxDataRequest,
} from "./types";

export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getSubscriptionPlans: builder.query<
      ISubscriptionPlansResponse,
      ISubscriptionPlansRequest
    >({
      query(body) {
        return {
          url: `/get_subscription_plans`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    getSubscriptionPlansSu: builder.query<ISubscriptionPlansSuResponse, any>({
      query(body) {
        return {
          url: `/subscription_plans_list`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    updateSubscription: builder.query<void, IUpdateSubscriptionRequest>({
      query(body) {
        return {
          url: `/update_subscription`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    updateSubscriptionSu: builder.query<void, IUpdateSubscriptionRequest>({
      query(body) {
        return {
          url: `su/update_subscription`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    getCurrentPeriodEnd: builder.query<any, any>({
      query(body) {
        return {
          url: `/current_period_end`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    updatePrices: builder.query<any, IUpdatePricesRequest>({
      query(body) {
        return {
          url: `/su/change_pricing_plan`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    updateTaxData: builder.query<any, IUpdateTaxDataRequest>({
      query(body) {
        return {
          url: `/update_tax_data`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    getAccountTaxData: builder.query<any, IGetTaxDataRequest>({
      query(body) {
        return {
          url: `/account_tax_data`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    getUserPaymentData: builder.query<any, IGetUserPaymentDataRequest>({
      query(body) {
        return {
          url: `/payment_method_data`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
  }),
});

export const {
  useGetSubscriptionPlansQuery,
  useGetSubscriptionPlansSuQuery,
  useUpdateSubscriptionQuery,
  useUpdateTaxDataQuery,
  useGetAccountTaxDataQuery,
  useGetUserPaymentDataQuery,
  useGetCurrentPeriodEndQuery,
  useUpdateSubscriptionSuQuery,
} = subscriptionApi;
