import { Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput, { FormInputType } from "../components/FormInput";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useLoginUserMutation } from "../redux/api/authApi";
import "./login.page.scss";
import { ReactComponent as Logo } from "../assets/voicetoyou_2.svg";

const LoadingButton = styled(_LoadingButton)`
  border-radius: 0px 0px 8px 8px;
  padding: 0.6rem 0;
  background-color: #001834;
  color: #ffffff;
  font-weight: 500;

  &:hover {
    background-color: #ffc700;
    box-shadow: none;
    color: #001834;
  }
  ,
  &:active {
    background-color: #27415f;
    box-shadow: none;
    color: #ffffff;
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: #2363eb;
  &:hover {
    text-decoration: underline;
  }
`;

const loginSchema = object({
  email: string()
    .min(1, "Debes introducir tu correo electrónico")
    .email("Este correo electrónico no es válido"),
  password: string()
    .min(1, "Debes introducir una contraseña")
    .min(8, "La contraseña debe tener más de 8 carácteres")
    .max(32, "La contraseña debe tener menos de 32 carácteres"),
});

export type LoginInput = TypeOf<typeof loginSchema>;

const LoginPage = () => {
  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  // 👇 API Login Mutation
  const [loginUser, { isLoading, isError, error, isSuccess }] =
    useLoginUserMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const from = ((location.state as any)?.from.pathname as string) || "/";

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      // toast.success('You successfully logged in');
      navigate(from);
    }

    if (isError) {
      if (Array.isArray((error as any).data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          })
        );
      } else {
        toast.error((error as any).data?.message, {
          position: "top-right",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
    // 👇 Executing the loginUser Mutation
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("currentDomain");
    sessionStorage.removeItem("currentAccount");
    sessionStorage.removeItem("username");
    console.log(location);
    loginUser(values);
  };

  return (
    <Container maxWidth={false} className="login_page">
      <Box className="logo">
        <Logo />
      </Box>
      <Box className="inside_container">
        <Typography className="login_header" textAlign="center" component="h1">
          Bienvenido
        </Typography>

        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
            maxWidth="27rem"
            width="100%"
          >
            <Box className="login_box">
              <FormInput
                formType={FormInputType.input}
                name="email"
                label="E-mail"
                className="input_txt"
                type="email"
                placeholder="Email"
              />
              <FormInput
                formType={FormInputType.input}
                name="password"
                label="Contraseña"
                className="input_txt"
                type="password"
                placeholder="Introduce una contraseña segura"
              />

              <Typography className="forgot_password">
                <LinkItem to="/forgotpassword" style={{ color: "#333" }}>
                  ¿Olvidaste tu contraseña?
                </LinkItem>
              </Typography>
            </Box>

            <LoadingButton
              variant="contained"
              fullWidth
              disableElevation
              type="submit"
              loading={isLoading}
            >
              Entrar
            </LoadingButton>
          </Box>

          <Typography className="no_account" sx={{ mt: "1rem" }}>
            ¿No tienes cuenta?{" "}
            <LinkItem className="register_here" to="/register">
              Regístrate aquí
            </LinkItem>
          </Typography>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default LoginPage;
