import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import { IGetMetricsRequest, IGetMetricsResponse } from "./types";

export const metricsApi = createApi({
  reducerPath: "metricsApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getMetrics: builder.query<IGetMetricsResponse, IGetMetricsRequest>({
      query(body) {
        return {
          url: `/metrics`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    searchNews: builder.mutation<IGetMetricsResponse, IGetMetricsRequest>({
      query(body) {
        return {
          url: `/metrics`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
  }),
});

export const { useGetMetricsQuery, useSearchNewsMutation } = metricsApi;
