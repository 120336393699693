import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import "./CustomList.scss";
import {
    IconButton,
    ListItemText,
    Stack,
    Switch,
    Tooltip,
} from "@mui/material";
import { ReactComponent as EditIcon } from "../assets/Edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/Trash.svg";
import { ReactComponent as UpdateIcon } from "../assets/update.svg";
type IListProps = {
    values: any[];
    onEdit?: Function | null;
    onSwitch?: Function;
    type: ListType;
    newId?: string;
    onDelete?: Function | null;
    updateAccount?: Function;
};

export enum ListType {
    domain,
    user,
    drafts,
    accounts,
    userSu,
    domainSu,
}

const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#F9FBFF",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#001834",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));

const CustomList: React.FC<IListProps> = ({
    values,
    type,
    onEdit,
    onDelete,
    newId,
    onSwitch,
    updateAccount,
}) => {
    const getRolesViewerLabel = (roles: any) => {
        if (roles && roles.length) {
            const viewerDomains = roles.filter((r: any) => r.viewer)[0];
            if (viewerDomains?.viewer && viewerDomains?.viewer.length) {
                const mappedNames = viewerDomains?.viewer.join(", ");
                return (
                    <Tooltip title={mappedNames}>
                        <Box className="role_label">
                            <span className="plan_label_txt">Viewer</span>
                        </Box>
                    </Tooltip>
                );
            }
        }
    };

    const getRolesEditorLabel = (roles: any) => {
        if (roles && roles.length) {
            const editorDomains = roles.filter((r: any) => r.editor)[0];
            if (editorDomains?.editor && editorDomains?.editor.length) {
                const mappedNames = editorDomains?.editor.join(", ");
                return (
                    <Tooltip title={mappedNames}>
                        <Box className="role_label">
                            <span className="plan_label_txt">Editor</span>
                        </Box>
                    </Tooltip>
                );
            }
        }
    };
    const getRolesAdminLabel = (roles: any) => {
        if (roles && roles.length) {
            const adminDomains = roles.filter((r: any) => r.admin)[0];
            if (adminDomains?.admin && adminDomains?.admin.length) {
                const mappedNames = adminDomains?.admin.join(", ");
                return (
                    <Tooltip title={mappedNames}>
                        <Box className="role_label">
                            <span className="plan_label_txt">Admin</span>
                        </Box>
                    </Tooltip>
                );
            }
        }
    };

    const getRolesSuAdminLabel = (roles: any) => {
        if (roles && roles.length) {
            const suAdminDomains = roles.filter((r: any) => r.su)[0];
            if (suAdminDomains?.su && suAdminDomains?.su.length) {
                // const mappedNames = suAdminDomains?.su.join(", ");
                return (
                    // <Tooltip title={mappedNames}>
                    <Box className="role_label_su">
                        <span className="plan_label_su_txt">Super-Admin</span>
                    </Box>
                    // </Tooltip>
                );
            }
        }
    };

    const getRolesOwnerLabel = (roles: any) => {
        if (roles && roles.length) {
            const ownerRole = roles.filter((r: any) => r.owner)[0];
            const ownerAccounts = ownerRole?.owner.account;
            if (ownerAccounts && ownerAccounts.length) {
                const mappedNames = ownerAccounts?.join(", ");
                return (
                    <Tooltip title={mappedNames}>
                        <Box className="role_label_owner">
                            <span className="plan_label_owner_txt">Owner</span>
                        </Box>
                    </Tooltip>
                );
            }
        }
    };
    const getLabelStatus = (value: any) => {
        if (onDelete && type === ListType.userSu && onSwitch) {
            const switchValue = value?.user_status;
            const roles = value?.roles;

            switch (switchValue) {
                case "enabled":
                    return (
                        <>
                            {getRolesOwnerLabel(roles)}
                            {getRolesViewerLabel(roles)}
                            {getRolesEditorLabel(roles)}
                            {getRolesAdminLabel(roles)}
                            {getRolesSuAdminLabel(roles)}
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                mr={2}
                                ml={2}
                            >
                                <AntSwitch
                                    checked={true}
                                    onChange={(e) => onSwitch(value, e.target.checked)}
                                    inputProps={{ "aria-label": "ant design" }}
                                />
                            </Stack>
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon
                                    fontSize="small"
                                    className="trash_icon"
                                    onClick={() => onDelete(value)}
                                />
                            </IconButton>
                        </>
                    );
                case "disabled":
                    return (
                        <>
                            {getRolesOwnerLabel(roles)}
                            {getRolesViewerLabel(roles)}
                            {getRolesEditorLabel(roles)}
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                mr={2}
                                ml={2}
                            >
                                <AntSwitch
                                    checked={false}
                                    value={"off"}
                                    onChange={(e) => onSwitch(value, e.target.checked)}
                                    inputProps={{ "aria-label": "ant design" }}
                                />
                            </Stack>
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon
                                    fontSize="small"
                                    className="trash_icon"
                                    onClick={() => onDelete(value)}
                                />
                            </IconButton>
                        </>
                    );
                default:
                    return;
            }
        } else if (
            (type === ListType.domain ||
                type === ListType.accounts ||
                type === ListType.domainSu) &&
            onSwitch
        ) {
            const switchValue =
                type === ListType.accounts
                    ? value?.account_status
                    : type === ListType.domain || type === ListType.domainSu
                        ? value?.domain_status
                        : value?.user_status;
            switch (switchValue) {
                case "enabled":
                    return (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <>
                                {type === ListType.accounts && updateAccount ? (
                                    <Box
                                        className="plan_label"
                                        onClick={() => updateAccount(value)}
                                    >
                                        <span className="plan_label_txt">{value?.plan_name}</span>
                                        <UpdateIcon />
                                    </Box>
                                ) : type === ListType.accounts ? (
                                    <Box className="plan_label">
                                        <span className="plan_label_txt">{value?.plan_name}</span>
                                        <UpdateIcon />
                                    </Box>
                                ) : null}
                            </>

                            <AntSwitch
                                checked={true}
                                onChange={(e) => onSwitch(value, e.target.checked)}
                                inputProps={{ "aria-label": "ant design" }}
                            />
                        </Stack>
                    );
                case "disabled":
                    return (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <>
                                {type === ListType.accounts && updateAccount ? (
                                    <Box
                                        className="plan_label"
                                        onClick={() => updateAccount(value)}
                                    >
                                        <span className="plan_label_txt">{value?.plan_name}</span>
                                        <UpdateIcon />
                                    </Box>
                                ) : type === ListType.accounts ? (
                                    <Box className="plan_label">
                                        <span className="plan_label_txt">{value?.plan_name}</span>
                                        <UpdateIcon />
                                    </Box>
                                ) : null}
                            </>
                            <AntSwitch
                                checked={false}
                                value={"off"}
                                onChange={(e) => onSwitch(value, e.target.checked)}
                                inputProps={{ "aria-label": "ant design" }}
                            />
                        </Stack>
                    );
                default:
                    return;
            }
        } else if (type === ListType.drafts && value.name === newId) {
            return <div className="status_label">ACTIVO</div>;
        } else {
            switch (value.role) {
                case "admin":
                    return <div className="status_label">ADMIN</div>;
                case "owner":
                    return <div className="status_label">OWNER</div>;
                case "viewer":
                    return <div className="status_label">VIEWER</div>;
                case "editor":
                    return <div className="status_label">EDITOR</div>;
                default:
                    return;
            }
        }
    };
    return (
        <Box className="list">
            <Grid item xs={12} md={6}>
                <Demo
                    className={
                        type === ListType.domain
                            ? "grid_container short"
                            : "grid_container long"
                    }
                >
                    <List
                        style={{
                            maxHeight: "100%",
                            minHeight:
                                type === ListType.user || type === ListType.userSu
                                    ? "375px"
                                    : "150px",
                            overflow: "auto",
                            padding: 0,
                            height:
                                type === ListType.user || type === ListType.userSu
                                    ? 285
                                    : "auto",
                            paddingRight: "15px",
                        }}
                    >
                        {values &&
                            values.map((item) => {
                                return (
                                    <ListItem
                                        key={type === ListType.domain ? item.domain_id : item.id}
                                        className="item_list"
                                        secondaryAction={
                                            ListType.drafts === type ||
                                                ListType.domain === type ||
                                                ListType.userSu === type ||
                                                ListType.accounts === type ? null : onDelete ? (
                                                    <IconButton edge="end" aria-label="delete">
                                                        <DeleteIcon
                                                            fontSize="small"
                                                            className="trash_icon"
                                                            onClick={() => onDelete(item)}
                                                        />
                                                    </IconButton>
                                                ) : null
                                        }
                                    >
                                        {onEdit ? (
                                            <EditIcon
                                                fontSize="small"
                                                className="edit_icon"
                                                onClick={() => onEdit(item)}
                                            />
                                        ) : null}

                                        <ListItemText
                                            sx={{
                                                color: "#27415F",
                                                backgroundColor: "#FFFFFF",
                                                cursor: "pointer",
                                            }}
                                            onClick={
                                                onEdit
                                                    ? () => onEdit(item)
                                                    : () => console.log("Clicked")
                                            }
                                            primary={
                                                type === ListType.domain
                                                    ? item.domain_name
                                                    : type === ListType.domainSu
                                                        ? item.domain_name + " - " + item.domain_id
                                                        : type === ListType.accounts
                                                            ? item.account_name + " - " + item.account_id
                                                            : type === ListType.userSu
                                                                ? item.user_name + " - " + item.user_id
                                                                : type === ListType.drafts
                                                                    ? item.name
                                                                    : item.name
                                            }
                                        />
                                        {getLabelStatus(item)}
                                    </ListItem>
                                );
                            })}
                    </List>
                </Demo>
            </Grid>
        </Box>
    );
};
export default CustomList;
