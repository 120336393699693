import React, { useState } from "react";
// import InputBase from "@mui/material/InputBase";
import { InputAdornment, TextField } from "@mui/material";
import "./SearchField.styles.scss";
// import Paper from "@mui/material/Paper";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
// import DirectionsIcon from "@mui/icons-material/Directions";
// import { styled } from "@mui/system";

interface Props {
  onSearch: any;
  onClickSearch?: any;
  label?: string;
  onClickClear?: any;
  currentValue?: string;
}

export enum BarVariant {
  filters,
  header,
  selector,
}

export enum BarSize {
  medium = 14,
  small = 12,
  xsmall = 10,
}

// const SearchTextField = styled(TextField)({
//   boxShadow: "0px 2px 8px rgba(39, 65, 95, 0.2)",
//   borderRadius: "4px",

//   "& .MuiFilledInput-root": {
//     border: "0px",
//   },
//   "& .MuiFilledInput-input": {
//     fontStyle: "normal",
//     fontWeight: "400",
//     fontSize: "12px",
//     lineHeight: "18px",
//     color: "#8A98A9",
//   },
// });

const SearchField: React.FC<Props> = ({
  currentValue,
  onSearch,
  label,
  onClickSearch,
  onClickClear,
}) => {
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <TextField
      id="input-with-icon-textfield"
      onChange={onSearch}
      focused={isFocused}
      onFocus={() => setIsFocused(true)}
      sx={{
        width: "200px",
        height: "34px",
        padding: "5px 0px 2px 10px",
        background: "#FFFFFF",
        boxShadow: "0px 2px 8px rgba(39, 65, 95, 0.2)",
        borderRadius: "4px",
      }}
      placeholder={label}
      inputProps={{
        style:
          value && value !== ""
            ? {
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "18px",
              color: "#8A98A9",
            }
            : {
              fontStyle: "normal",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "18px",
              color: "#001834",
            },
      }}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="start">
            {isFocused ? (
              <div
                style={{ cursor: "pointer", display: "flex" }}
                onClick={onClickSearch}
              >
                <SearchIcon />
              </div>
            ) : null}

            {currentValue && currentValue !== '' ? (
              <div
                style={{ cursor: "pointer", display: "flex" }}
                onClick={onClickClear}
              >
                <ClearIcon fontSize="small" />
              </div>
            ) : null}

          </InputAdornment>
        ),
      }}
      variant="standard"
      value={currentValue}
    />
  );
};

export default SearchField;
