import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAccount } from "../api/types";

interface IAccountState {
  accounts: IAccount[] | [];
  currentAccont: IAccount | null;
}

const initialState: IAccountState = {
  accounts: [],
  currentAccont: null,
};

export const accountSlice = createSlice({
  initialState,
  name: "accountSlice",
  reducers: {
    reset: () => initialState,
    setCurrentAccount: (state, action: PayloadAction<IAccount>) => {
      state.currentAccont = action.payload;
    },
    setAccounts: (state, action: PayloadAction<IAccount[]>) => {
      state.accounts = action.payload;
    },
  },
});

export default accountSlice.reducer;

export const { reset, setAccounts, setCurrentAccount } = accountSlice.actions;
