import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Container, Toolbar, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
// import { useLocation, useNavigate } from "react-router-dom";
import { object, string, TypeOf } from "zod";
import DomainList, { ListType } from "../components/CustomList";
import Dropdown, { BarSize, BarVariant } from "../components/Dropdown";
import FullScreenLoader from "../components/FullScreenLoader";
import { domainApi } from "../redux/api/domainApi";
import {
  IDomain,
  IInviteUserRequest,
  IListUsersAdminResponse,
} from "../redux/api/types";
import { userApi } from "../redux/api/userApi";
import { useAppDispatch } from "../redux/store";
import { LoadingButton as _LoadingButton } from "@mui/lab";

import "./domain-management-admin.scss";
import { accountsApi } from "../redux/api/accountsApi";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 60,
    paddingBottom: 14,
  },
}));

const DomainManagementAdmin = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const accountStr = sessionStorage.getItem("currentAccount");
  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;

  const { data, isLoading, isFetching, refetch } =
    domainApi.endpoints.listDomainsSu.useQuery(
      {},
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );

  const handleOnSwitchDomain = async (domain: IDomain, value: string) => {
    if (domain) {
      setLoading(true);
      let newStatus = value ? "enabled" : "disabled";
      await dispatch(
        accountsApi.endpoints.adminAction.initiate(
          {
            object_type: "domains",
            object_name: domain.domain_id,
            status_su: newStatus,
          },
          { forceRefetch: true }
        )
      );
      refetch();
      setLoading(false);
    }
  };

  if (loading || isLoading || isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <Container
      maxWidth={false}
      className="domain"
      sx={{
        backgroundColor: "#ECF2F9",
        overflow: "hidden",
        paddingTop: 10,
        paddingRight: "10% !important",
        paddingLeft: "5% !important",
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          paddingTop: "30px",
          paddingLeft: "8% !important",
          height: "100vh",
        }}
      >
        <StyledToolbar disableGutters>
          <span className="header_txt">
            {/* {`${t("news-create.create-audio")}`} */}
            Gestión de dominios SuperAdmin
          </span>
        </StyledToolbar>
        <Box className="box_container">
          {data && (
            <DomainList
              values={data.domains}
              type={ListType.domainSu}
              onSwitch={handleOnSwitchDomain}
            />
          )}
        </Box>
      </Container>
    </Container>
  );
};

export default withTranslation()(DomainManagementAdmin);
