import { createApi } from "@reduxjs/toolkit/query/react";
import { setAccounts } from "../features/accountSlice";
import customFetchBase from "./customFetchBase";
import {
  IAccount,
  IChangeStatusSuRequest,
  IGetAccountsRequest,
  IListAccountsSuResponse,
  ISetAccountParamsRequest,
} from "./types";

export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getAccountsSu: builder.query<IListAccountsSuResponse, any>({
      query(body) {
        return {
          url: `/su/list_accounts`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    getAccounts: builder.query<IAccount[], IGetAccountsRequest>({
      query(body) {
        return {
          url: `/user_account_list`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
      transformResponse: (result: IAccount[]) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // console.log(data);
          dispatch(setAccounts(data));
        } catch (err: any) {
          if (err.error.status === 401) {
            window.location.href = "/login";
          }
          console.error(err);
        }
      },
    }),
    adminAction: builder.query<any, IChangeStatusSuRequest>({
      query(body) {
        return {
          url: `/su/change_status_su`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    setAccountParams: builder.query<any, ISetAccountParamsRequest>({
      query(body) {
        return {
          url: `/set_account_default_params`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAccountsSuQuery,
  useAdminActionQuery,
  useSetAccountParamsQuery,
} = accountsApi;
