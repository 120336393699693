import { AppBar, Box, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useLogoutUserMutation } from "../redux/api/authApi";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { LoadingButton as _LoadingButton } from "@mui/lab";

import { ReactComponent as Voice2YouLogo } from "../assets/voicetoyou.svg";
import Dropdown, { BarSize, BarVariant } from "./Dropdown";
import {
  IAccount,
  IDomain,
  InputDropdown,
  userHeaderAdminOptions,
  userHeaderOptions,
  userHeaderOptionsBasic,
} from "../redux/api/types";
import { setCurrentDomain } from "../redux/features/domainSlice";
import { setCurrentAccount } from "../redux/features/accountSlice";
import { accountsApi } from "../redux/api/accountsApi";
import { domainApi } from "../redux/api/domainApi";
import { userApi } from "../redux/api/userApi";

// const LoadingButton = styled(_LoadingButton)`
//   padding: 0.4rem;
//   color: #222;
//   font-weight: 500;
//   border: 2px solid #222;
//   margin-right: 1rem;

//   &:hover {
//     transform: translateY(-2px);
//   }
// `;

const Header = () => {
  const [currentAccountState, setCurrentAccountState] = useState<IAccount>();
  const [currentDomainState, setCurrentDomainState] = useState<IDomain>();

  const [domains, setDomains] = useState<InputDropdown[]>([]);
  const [accounts, setAccounts] = useState<InputDropdown[]>([]);

  const navigate = useNavigate();
  const domainsSelector = useAppSelector((state) => state.domainState.domains);
  const accountsSelector = useAppSelector(
    (state) => state.accountState.accounts
  );

  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector((state): any => state.userState.isAdmin);
  const [currentHeaderValues, setCurrentHeaderValues] = useState<
    InputDropdown[]
  >([]);
  const userId = sessionStorage.getItem("user_id");
  const accountStr = sessionStorage.getItem("currentAccount");
  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;
  const domainStr = sessionStorage.getItem("currentDomain");
  const domainJson =
    domainStr && domainStr !== "" ? JSON.parse(domainStr) : null;

  const queryMultiple = () => {
    const res1 = !isAdmin
      ? accountsApi.endpoints.getAccounts.useQuery(
        {
          user_id: userId ? userId : "",
        },
        {
          skip: false,
          refetchOnMountOrArgChange: true,
        }
      )
      : {};

    const res2 = !isAdmin
      ? domainApi.endpoints.listDomainsHeader.useQuery(
        {
          account_id: accountJson?.id,
          origin_request: "header",
        },
        {
          skip: false,
          refetchOnMountOrArgChange: true,
        }
      )
      : {};
    const res3 = !isAdmin
      ? userApi.endpoints.getUserRole.useQuery(
        {
          account_id: accountJson?.id,
          domain_id: domainJson?.id,
        },
        {
          skip: !accountJson?.id || !domainJson?.id,
          refetchOnMountOrArgChange: true,
        }
      )
      : {};
    return [res1 as any, res2 as any, res3 as any];
  };

  const [
    {
      isLoading: loading1,
      isFetching: isFetching1,
      data: res1,
      refetch: refetchAccounts,
    },
    {
      isLoading: loading2,
      isFetching: isFetching2,
      data: res2,
      refetch: refetchDomains,
    },
    {
      isLoading: loading3,
      isFetching: isFetching3,
      data: res3,
      refetch: refetchRole,
    },
  ] = queryMultiple();

  useEffect(() => {
    const mappedDomains = domainsSelector as IDomain[];
    if (!sessionStorage.getItem("currentDomain") && mappedDomains?.length) {
      const defaultDomain = mappedDomains[0];
      sessionStorage.setItem(
        "currentDomain",
        JSON.stringify({
          id: defaultDomain.domain_id,
          name: defaultDomain.domain_name,
        })
      );
      dispatch(
        setCurrentDomain({
          domain_id: defaultDomain.domain_id,
          domain_name: defaultDomain.domain_name,
          domain_status: defaultDomain.domain_status,
        })
      );
    }
    const mappedNames = mappedDomains?.map((d) => {
      var response = {
        id: d.domain_id,
        name: d.domain_name,
      };
      return response;
    });
    if (mappedNames?.length) {
      setDomains(mappedNames);
    }
  }, [domainsSelector]);

  useEffect(() => {
    const mappedAccounts = accountsSelector as IAccount[];
    const mappedNames = mappedAccounts?.map((d) => {
      var response = {
        id: d.account_id,
        name: d.account_name,
      };
      return response;
    });
    if (mappedNames?.length) {
      setAccounts(mappedNames);
    }
  }, [accountsSelector]);

  useEffect(() => {
    if (!isAdmin && res1 && res1.length) {
      if (!sessionStorage.getItem("currentAccount")) {
        const accountValue = {
          id: res1[0].account_id,
          name: res1[0].account_name,
          owner_names: res1[0].owner_names,
          owner_email: res1[0].owner_email,
        };
        sessionStorage.setItem("currentAccount", JSON.stringify(accountValue));
        dispatch(
          setCurrentAccount({
            account_id: accountValue.id,
            account_name: accountValue.name,
            owner_names: accountValue.owner_names,
            owner_email: accountValue.owner_email,
            account_status: "",
          })
        );
        refetchDomains();
        // sessionStorage.setItem('currentAccount', res2)
      }
    }
  }, [res1]);

  useEffect(() => {
    if (isAdmin) {
      setCurrentHeaderValues(userHeaderAdminOptions);
    } else {
      if (res3?.role && (res3.role === "viewer" || res3.role === "editor")) {
        setCurrentHeaderValues(userHeaderOptionsBasic);
      } else {
        setCurrentHeaderValues(userHeaderOptions);
      }
    }
  }, [isAdmin, res3]);

  const [logoutUser, { isLoading, isSuccess, error, isError }] =
    useLogoutUserMutation();

  useEffect(() => {
    if (isSuccess) {
      window.location.href = "/login";
    }

    if (isError) {
      if (Array.isArray((error as any).data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          })
        );
      } else {
        toast.error((error as any).data?.message, {
          position: "top-right",
        });
      }
      window.location.href = "/login";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  function createAccountObject(value: InputDropdown): IAccount {
    return {
      account_id: value.id,
      account_name: value.name,
      account_status: "",
    };
  }

  function createDomainObject(value: InputDropdown): IDomain {
    return {
      domain_id: value.id,
      domain_name: value.name,
      domain_status: "",
    };
  }

  const handleChangeDomain = (value: InputDropdown) => {
    let domainValue = createDomainObject(value);
    sessionStorage.setItem("currentDomain", JSON.stringify(value));
    dispatch(setCurrentDomain(domainValue));
    setCurrentDomainState(domainValue);
  };

  const handleChangeAccount = (value: InputDropdown) => {
    let accountValue = createAccountObject(value);
    sessionStorage.setItem("currentAccount", JSON.stringify(value));
    dispatch(setCurrentAccount(accountValue));
  };

  const handleChangeUserOptions = (value: InputDropdown) => {
    console.log(isAdmin);
    if (isAdmin) {
      switch (value.id) {
        case "1":
          navigate("/account-admin");
          break;
        case "2":
          navigate("/users-admin");
          break;
        case "3":
          navigate("/domains-admin");
          break;
        case "4":
          logoutUser();
          break;
        default:
          break;
      }
    } else {
      if (res3?.role && (res3.role === "viewer" || res3.role === "editor")) {
        switch (value.id) {
          case "1":
            navigate("/");
            break;
          case "2":
            navigate("/news");
            break;
          case "3":
            navigate("/users");
            break;
          case "4":
            logoutUser();
            break;
          default:
            break;
        }
      } else {
        switch (value.id) {
          case "1":
            navigate("/");
            break;
          case "2":
            navigate("/news");
            break;
          case "3":
            navigate("/account");
            break;
          case "4":
            navigate("/users");
            break;
          case "5":
            logoutUser();
            break;
          default:
            break;
        }
      }
    }
  };

  const getImageIcon = () => {
    return (
      <img
        style={{
          width: 48,
          height: 48,
          backgroundColor: "white",
          borderRadius: 99,
          cursor: "pointer",
        }}
        src={require("../../src/assets/avatar.png")}
        alt="new"
      />
    );
  };
  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#001834", paddingInline: "30px" }}
      >
        <Toolbar>
          <Voice2YouLogo
            onClick={() =>
              isAdmin ? navigate("/account-admin") : navigate("/")
            }
            style={{ cursor: "pointer" }}
          />
          <Box display="flex" sx={{ ml: "auto" }} alignItems="center">
            {!isAdmin ? (
              <Box display={"flex"} alignItems={"center"}>
                <Dropdown
                  label="Cuentas"
                  onChange={handleChangeAccount}
                  values={accounts}
                  key={1}
                  multiple={false}
                  size={BarSize.small}
                  variant={BarVariant.account}
                />
                <Dropdown
                  label="Dominios"
                  onChange={handleChangeDomain}
                  values={domains}
                  key={2}
                  multiple={false}
                  size={BarSize.medium}
                  variant={BarVariant.header}
                />
              </Box>
            ) : null}

            <Dropdown
              multiple={false}
              key={3}
              values={currentHeaderValues}
              onChange={handleChangeUserOptions}
              variant={BarVariant.img}
              size={BarSize.xsmall}
              deselect={true}
              icon={getImageIcon}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
