import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Container,
  Toolbar,
  styled,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
// import { useLocation, useNavigate } from "react-router-dom";
import { object, string, TypeOf } from "zod";
import UserList, { ListType } from "../components/CustomList";
import Dropdown, { BarSize, BarVariant } from "../components/Dropdown";
import FullScreenLoader from "../components/FullScreenLoader";
import { domainApi } from "../redux/api/domainApi";
import {
  IInviteUserRequest,
  IListUsersResponse,
  InputDropdown,
} from "../redux/api/types";
import { userApi } from "../redux/api/userApi";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";

import "./user-management.scss";
import FormInput, { FormInputType } from "../components/FormInput";
import { accountsApi } from "../redux/api/accountsApi";
import { toast } from "react-toastify";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 60,
    paddingBottom: 14,
  },
}));

const modalStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  paddingTop: 4,
  paddingLeft: 4,
  paddingRight: 4,
  bgcolor: "#FFFFFF",
  boxShadow: "0px 2px 8px rgba(39, 65, 95, 0.2)",
  borderRadius: "8px",
};

const LoadingButton = styled(_LoadingButton)`
  border-radius: 4px;
  padding: 8px 8px;
  margin-top: 20px;
  background-color: #001834;
  color: #ffffff;
  font-weight: 500;
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  border: 0.75px solid #001834;
  &:hover {
    background-color: #ffc700;
    box-shadow: none;
    color: #001834;
  }
  ,
  &:active {
    background-color: #27415f;
    box-shadow: none;
    color: #ffffff;
  }
`;

function createUserData(value: IListUsersResponse): any {
  return {
    id: value.user_id,
    name: value.user_name,
    role: value.user_access[0]?.role,
  };
}
const inviteSchema = object({
  user_name: string().min(1, "Debes introducir el nombre del usuario").max(100),
  user_email: string()
    .min(1, "Debes introducir un correo electrónico")
    .email("Este correo electrónico no es válido"),
});

const editSchema = object({});

export type InviteInput = TypeOf<typeof inviteSchema>;
export type InviteEditInput = TypeOf<typeof editSchema>;

const UserManagement = () => {
  const methods = useForm<InviteInput>({
    resolver: zodResolver(inviteSchema),
  });

  const methodsEdit = useForm<InviteEditInput>({
    resolver: zodResolver(editSchema),
  });
  // const { t } = useTranslation();
  // const navigate = useNavigate();
  // const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [users, setUsers] = useState<IListUsersResponse[]>([]);
  const [roles, setRoles] = useState<InputDropdown[]>([]);

  const [userEdited, setUserEdited] = useState<IListUsersResponse>();
  const userId = sessionStorage.getItem("user_id");
  const [selectedRole, setSelectedRole] = useState<InputDropdown>();
  const [openModal, setOpenModal] = useState(false);

  const accountStr = sessionStorage.getItem("currentAccount");
  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;
  const dispatch = useAppDispatch();
  // const domains = useAppSelector((state) => state.domainState.domains);
  const domainStr = sessionStorage.getItem("currentDomain");
  const domainJson =
    domainStr && domainStr !== "" ? JSON.parse(domainStr) : null;
  const currentDomain = useAppSelector(
    (state) => state.domainState.currentDomain
  );
  const currentAccount = useAppSelector(
    (state) => state.accountState.currentAccont
  );

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const { reset: resetEdit, handleSubmit: handleSubmitEdit } = methodsEdit;

  const queryMultiple = () => {
    const res1 = userApi.endpoints.listUsers.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );

    const res2 = userApi.endpoints.getRoles.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res3 = userApi.endpoints.getUserRole.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    return [res1 as any, res2 as any, res3 as any];
  };

  const [
    { isLoading: loading1, data: res1, refetch: refetchListUsers },
    { isLoading: loading2, data: res2, refetch: refetchRoles },
    { isLoading: loading3, data: res3, refetch: refetchUserRoles },
  ] = queryMultiple();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    setUsers([]);
    if (res1?.length) {
      buildUsers(res1);
    }
  }, [res1]);

  useEffect(() => {
    if (res2 && res2.valid_roles) {
      buildRoles(res2.valid_roles);
    }
  }, [res2]);

  const buildUsers = (usersArray: IListUsersResponse[]) => {
    const mapped = usersArray.map((val) => {
      return createUserData(val);
    });
    setUsers(mapped);
  };

  const buildRoles = (rolesArray: any[]) => {
    const mapped = rolesArray.map((val, index) => {
      return {
        id: index.toString(),
        name: val,
      } as InputDropdown;
    });
    setRoles(mapped);
  };

  const handleInviteUser: SubmitHandler<any> = async (values) => {
    setButtonLoading(true);
    const res = await dispatch(
      userApi.endpoints.inviteUser.initiate({
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
        user_role: selectedRole ? selectedRole?.name : "",
        user_email: values.user_email,
        user_name: values.user_name,
      })
    );
    refetchListUsers();
    setButtonLoading(false);

    if (res.isSuccess) {
      toast.success("Usuario invitado correctamente", {
        position: "top-right",
      });
    } else {
      console.log(res.error)
      const error = res?.error as any
      toast.error("No se ha podido invitar al usuario. Error: " + error?.data?.message
        , {
          position: "top-right",
        });
    }
  };

  const handleOpen = (user: any) => {
    if (res1 && res1.length) {
      const res = res1.filter(
        (u: IListUsersResponse) => u.user_id === user.id
      )[0];
      setUserEdited(res);
      setOpenModal(true);
    }
  };
  const removeUserPriv = async (user: InputDropdown) => {
    if (user) {
      setLoading(true);
      const res = await dispatch(
        userApi.endpoints.removeUserPriv.initiate({
          user_id: user.id,
          account_id: accountJson?.id,
          domain_id: domainJson?.id,
        })
      );
      await refetchListUsers();
      setLoading(false);

      if (res.isSuccess) {
        toast.success("Usuario eliminado correctamente", {
          position: "top-right",
        });
      } else {
        toast.error("No se ha podido eliminar el usuario", {
          position: "top-right",
        });
      }
    }
  };

  const getRoleEdited = () => {
    return {
      id: "",
      name:
        userEdited && userEdited.user_access
          ? userEdited.user_access[0].role
          : "",
    } as InputDropdown;
  };
  const handleClose = () => setOpenModal(false);

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    if (userEdited) {
      setButtonLoading(true);
      const res = await dispatch(
        userApi.endpoints.updateUser.initiate({
          user_id: userEdited.user_id,
          account_id: accountJson?.id,
          domain_id: domainJson?.id,
          user_role: userEdited.user_access[0]?.role,
        })
      );
      refetchListUsers();
      if (res.isSuccess) {
        toast.success("Permisos actualizados correctamente", {
          position: "top-right",
        });
      } else {
        toast.error("No se han podido actualizado los permisos", {
          position: "top-right",
        });
      }
      setButtonLoading(false);
      handleClose();
    }
  };

  if (loading || loading3) {
    return <FullScreenLoader />;
  }
  return (
    <Container
      maxWidth={false}
      className="user"
      sx={{
        backgroundColor: "#ECF2F9",
        overflow: "hidden",
        paddingTop: 10,
        paddingRight: "10% !important",
        paddingLeft: "5% !important",
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          paddingTop: "30px",
          paddingLeft: "8% !important",
          height: "100vh",
        }}
      >
        <StyledToolbar disableGutters>
          <span className="header_txt">
            {/* {`${t("news-create.create-audio")}`} */}
            Gestión de usuarios
          </span>
        </StyledToolbar>

        <Box
          sx={{
            display: "flex",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
          }}
        >
          <Box className="box_bottom_container">
            <span className="subheader_txt">
              {/* {`${t("news-create.create-audio")}`} */}
              Invitar nuevo usuario
            </span>
            <FormProvider key={1} {...methods}>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "100%" },
                }}
                noValidate
                pt={"20px"}
                pb={"20px"}
                autoComplete="off"
                onSubmit={handleSubmit(handleInviteUser)}
              >
                <FormInput
                  formType={FormInputType.input}
                  name="user_name"
                  label="Nombre del usuario"
                  className="input_txt"
                  type="user_name"
                  placeholder="Nombre"
                />

                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Box width={"100%"} mr={"20px"}>
                    <FormInput
                      formType={FormInputType.input}
                      name="user_email"
                      label="E-mail"
                      className="input_txt"
                      type="user_email"
                      placeholder="E-mail"
                    />
                  </Box>
                  <Box width={"100%"}>
                    <span className="top_label">Rol</span>
                    <Dropdown
                      label="Asignar Rol"
                      onChange={(roleInput: InputDropdown) => {
                        setSelectedRole(roleInput);
                      }}
                      defaultSelected={selectedRole}
                      values={roles}
                      key={2}
                      multiple={false}
                      size={BarSize.full}
                      variant={BarVariant.form}
                    />
                  </Box>
                </Box>
                <Box display={"flex"} mt={"30px"} width={"50%"}>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    disableElevation
                    type="submit"
                    loading={buttonLoading}
                  >
                    Invitar usuario
                  </LoadingButton>
                </Box>
              </Box>
            </FormProvider>
          </Box>

          <Box className="box_bottom_container right">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              pb={2}
            >
              <Box mb={2}>
                <span className="subheader_txt">Listado de usuarios</span>
              </Box>
            </Box>
            <UserList
              values={users}
              onEdit={
                res3 && res3.role !== "editor" && res3.role !== "viewer"
                  ? handleOpen
                  : null
              }
              type={ListType.user}
              onDelete={
                res3 && res3.role !== "editor" && res3.role !== "viewer"
                  ? removeUserPriv
                  : null
              }
            />
          </Box>
        </Box>
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disableAutoFocus={true}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={modalStyle}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box>
                <span className="subheader_txt">Editar usuario</span>
              </Box>
              <Box>
                <CloseIcon className="close_icon" onClick={handleClose} />
              </Box>
            </Box>
            <FormProvider key={2} {...methodsEdit}>
              <Box
                component="form"
                onSubmit={handleSubmitEdit(onSubmitHandler)}
                noValidate
                autoComplete="off"
                alignItems={"center"}
                maxWidth="27rem"
                width="100%"
                pt={"20px"}
                pb={"20px"}
              >
                <FormInput
                  formType={FormInputType.input}
                  name="user_name"
                  label="Nombre"
                  className="input_txt"
                  type="user_name"
                  placeholder="Escribe el nombre"
                  value={userEdited?.user_name}
                  disabled={true}
                />
                <FormInput
                  formType={FormInputType.input}
                  name="user_email"
                  label="E-mail"
                  className="input_txt"
                  type="user_email"
                  placeholder="Introduce el correo electrónico"
                  value={userEdited?.user_email}
                  disabled={true}
                />
                <FormInput
                  formType={FormInputType.input}
                  name="user_domain"
                  label="Dominio"
                  className="input_txt"
                  type="user_domain"
                  value={domainJson?.name}
                  disabled={true}
                />
                <FormInput
                  name="role"
                  label="Rol"
                  className="input_txt"
                  type="role"
                  formType={FormInputType.dropdown}
                  selectedDropdown={getRoleEdited()}
                  valuesDropdown={roles}
                  onChangeAction={(roleInput: InputDropdown) =>
                    setUserEdited(
                      (prevState) =>
                      ({
                        ...prevState,
                        user_access: [{ id: "", role: roleInput.name }],
                      } as IListUsersResponse)
                    )
                  }
                />
                <LoadingButton
                  variant="contained"
                  fullWidth
                  disableElevation
                  type="submit"
                  loading={buttonLoading}
                >
                  Actualizar permisos
                </LoadingButton>
              </Box>
            </FormProvider>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default withTranslation()(UserManagement);
