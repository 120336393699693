import { string } from "zod";

export interface appAction {
  type: string;
  payload: {};
}
export interface GenericResponse {
  data: string;
  message: string;
}

export interface IListNewsRequest {
  account_id: string;
  news_id?: string;
  domain_filter?: string;
  limit?: number;
  page?: number;
  orderBy?: string;
  status_filter?: string;
  sortingOrder?: string;
}

export interface IGetNewsRequest {
  news_id: string;
}

export interface ICreateAudioRequest {
  account_id: string;
  news_id: string;
  news_text: string;
  render_voice_type: string;
  render_voice_name: string;
  render_pitch: string;
  render_speed: string;
}

export interface IResetPasswordRequest {
  resetToken: string;
  password: string;
  passwordConfirm: string;
}

export interface IPostRequest {
  title: string;
  content: string;
  image: string;
  user: string;
}

export interface IUser {
  name: string;
  email: string;
  role: string;
  photo: string;
  _id: string;
  id: string;
  created_at: string;
  updated_at: string;
  __v: number;
}

export interface IPostResponse {
  id: string;
  title: string;
  content: string;
  image: string;
  category: string;
  user: IUser;
  created_at: string;
  updated_at: string;
}
export interface IDrafting {
  name: string;
  status: string;
  text: string;
}
export interface INewResponse {
  domain_id: string;
  domain_name: string;
  draftings: IDrafting[];
  news_audio_id: string;
  news_audio_status: string;
  news_created_at: string;
  news_last_update: string;
  news_id: string;
  news_name: string;
  news_status: string;
  news_reproductions: number;
  news_title: string;
  active_drafting: string;
}
export interface IPagination {
  page: number;
  limit: number;
}
export interface INewsApiResponse {
  next: IPagination;
  results: INewResponse[];
}

export interface ITypesApiResponse {
  result: {
    default_values: {};
    voice: ITypesVoice[];
  };
}

export interface ITypesVoice {
  voice_type: string;
  voice_name: string[];
}

export interface ITypesApiRequest {
  account_id: string;
  domain_id?: string;
}

export interface IAddNewRequest {
  account_id: string;
  domain_id: string;
  news_id: string;
  news_name: string;
  news_text: string;
  news_status: string;
  render_voice_type: string;
  render_voice_name: string;
  render_pitch: string;
  render_speed: string;
}

export interface ISaveAsDraftRequest {
  news_id: string;
  drafting_text: string;
}

export interface IUpdateTaxDataRequest {
  domain_id: string;
  account_id: string;
  cif: string;
  nombre_compania: string;
  direccion_fiscal: string;
  representante_legal: string;
  telefono: string;
  email: string;
  ciudad: string;
  provincia: string;
  pais: string;
  codigo_postal: string;
}

export interface IGetUserPaymentDataRequest {
  account_id: string;
}

export interface IGetTaxDataRequest {
  account_id: string;
  domain_id: string;
}

export interface IGetUserRoleRequest {
  account_id: string;
  domain_id: string;
}

export interface IDrafting {
  name: string;
  status: string;
  text: string;
}

export interface TableData {
  title: string;
  id: string;
  created: Date;
  updated: Date;
  state: string;
  plays: number;
}

export interface IAccount {
  account_id: string;
  account_name: string;
  account_status: string;
  subcription_plan?: string;
  owner_names?: string;
  owner_email?: string;
}
export interface IDomain {
  domain_id: string;
  domain_name: string;
  domain_status: string;
  automatic_publication?: boolean;
  extraction_object?: string;
  extraction_type?: string;
  pitch?: string;
  speed?: string;
  strip_tags?: boolean;
  voice_name?: string;
  voice_type?: string;
}
export interface IDomainListRequest {
  account_id: string;
  origin_request?: string;
}

export interface IGetAccountsRequest {
  user_id: string;
}
export interface IUpdateDomainRequest {
  account_id: string;
  domain_id: string;
  domain_status?: string;
  domain_name: string;
  domain_extraction_type?: string;
  domain_text_object_id?: string;
  remove_tags_html?: boolean;
  automatic_publication?: boolean;
  domain_default_voice_name?: string;
  domain_default_voice_type?: string;
  domain_default_pitch?: string;
  domain_default_speed?: string;
}
export interface IUpdateDomainResponse {
  domain_status: string;
  domain_name: string;
}

export interface IUserUpdateRequest {
  user_id: string;
  account_id: string;
  domain_id: string;
  user_role: string;
}
export interface IRemoveUserPriv {
  user_id: string;
  account_id: string;
  domain_id: string;
}
export interface IDomainListResponse {
  domains: IDomain[];
}

export interface IListUsersResponse {
  user_id: string;
  user_name: string;
  user_status: string;
  user_email: string;
  user_role: string;
  user_access: [
    {
      id: string;
      type: string;
      role: string;
    }
  ];
}
export interface IListUsersAdminResponse {
  users: [
    {
      user_id: string;
      user_status: string;
    }
  ];
}
export interface IListUsersRequest {
  account_id: string;
  domain_id: string;
}

export interface IInviteUserResponse {
  user_id: string;
}

export interface IListAccountsSuResponse {
  accounts: [
    {
      account_id: string;
      account_status: string;
      plan: string;
    }
  ];
}
export interface IUpdatePricesRequest {
  plan_id: string;
  pricing: number;
}
export interface IUpdateUserStatusSuRequest {
  user_id: string;
  user_status: string;
}
export interface IChangeStatusSuRequest {
  object_type: string;
  object_name: string;
  status_su: string;
}

export interface IRemoveUserSuRequest {
  user_id: string;
}

export interface IInviteUserRequest {
  account_id: string;
  domain_id: string;
  user_name: string;
  user_email: string;
  user_role: string;
}

export interface IGetMetricsRequest {
  account_id: string;
  account_filter: string;
  domain_filter: string;
  news_id?: string;
  metrics_fecha_inicio?: string;
  metrics_fecha_fin?: string;
  page?: number;
  limit?: number;
  orderBy?: string;
  sortingOrder?: string;
}
export interface IGetRolesRequest {
  account_id: string;
  domain_id: string;
}
export interface IGetUserData {
  account_id: string;
}
export interface KpiByNewsId {
  news_id: string;
  news_title: string;
  loads: number;
  plays: number;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  total_listening_time: number;
}

export interface KpiBigNumbers {
  loads: number;
  plays: number;
  news_with_player: number;
  most_listened_news: string;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  total_listening_time: number;
}

export interface ICheckAdminResponse {
  is_su: string;
}
export interface IGetMetricsResponse {
  result: {
    kpis_by_news_id: KpiByNewsId[];
    kpis_big_numbers: KpiBigNumbers;
  };
}
export interface ISubscriptionPlansRequest {
  account_id: string;
  domain_id: string;
}

export interface ISubscriptionPlan {
  plan_id: string;
  plan_alias: string;
}
export interface ISubscriptionPlansResponse {
  plans: ISubscriptionPlan[];
  actual_plan_id: string;
}

export interface ISubscriptionPlansSuResponse {
  subscription_plans: [
    {
      plan_id: string;
      plan_name: string;
      plan_status: string;
      monthly_cost: string;
      image: string;
    }
  ];
}

export interface IAddDomainRequest {
  account_id: string;
  domain_id: string;
  domain_name: string;
  domain_extraction_type?: string;
  domain_text_object_id?: string;
  remove_tags_html?: boolean;
  automatic_publication?: boolean;
  domain_default_voice_name?: string;
  domain_default_voice_type?: string;
  domain_default_pitch?: number;
  domain_default_speed?: number;
}
export interface IAddDomainResponse {
  domain_id: string;
  domain_status: string;
}

export interface IUpdateSubscriptionRequest {
  plan_id: string;
  account_id: string;
  domain_id?: string;
  stripe_card_number?: string;
  stripe_card_name?: string;
  stripe_card_exp_month?: string;
  stripe_card_exp_year?: string;
  stripe_card_cvc?: string;
}
export interface ISetAccountParamsRequest {
  account_id: string;
  default_voice_name: string;
  default_voice_type: string;
  default_pitch: string;
  default_speed: string;
}
export interface IValidateAccountRequest {
  account_name: string;
}

export interface IValidateAccountResponse {
  account_exists: boolean;
  suggestion: string;
}

export interface InputDropdown {
  id: string;
  name: string;
}

export const stateValues: InputDropdown[] = [
  { id: "1", name: "--" },
  { id: "2", name: "Activo" },
  { id: "3", name: "Inactivo" },
];

export const userHeaderOptions: InputDropdown[] = [
  { id: "1", name: "Inicio" },
  { id: "2", name: "Listado de Audios" },
  { id: "3", name: "Gestión de Cuenta" },
  { id: "4", name: "Gestión de Usuarios" },
  { id: "5", name: "Salir" },
];

export const userHeaderOptionsBasic: InputDropdown[] = [
  { id: "1", name: "Inicio" },
  { id: "2", name: "Listado de Audios" },
  { id: "3", name: "Gestión de Usuarios" },
  { id: "4", name: "Salir" },
];

export const userHeaderAdminOptions: InputDropdown[] = [
  { id: "0", name: "Admin" },
  { id: "1", name: "Gestión de Cuenta" },
  { id: "2", name: "Gestión de Usuarios" },
  { id: "3", name: "Gestión de Dominios" },
  { id: "4", name: "Salir" },
];

export const extractionTypes: InputDropdown[] = [
  { id: "1", name: "auto" },
  { id: "2", name: "variable" },
  { id: "3", name: "html_object" },
  { id: "4", name: "json_ld" },
];
