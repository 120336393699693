import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDomain } from "../api/types";

interface IDomainState {
  domains: IDomain[] | [];
  currentDomain: IDomain | null;
}

const initialState: IDomainState = {
  domains: [],
  currentDomain: null,
};

export const domainSlice = createSlice({
  initialState,
  name: "domainSlice",
  reducers: {
    reset: () => initialState,
    setCurrentDomain: (state, action: PayloadAction<IDomain>) => {
      state.currentDomain = action.payload;
    },
    setDomains: (state, action: PayloadAction<IDomain[]>) => {
      state.domains = action.payload;
    },
  },
});

export default domainSlice.reducer;

export const { reset, setDomains, setCurrentDomain } = domainSlice.actions;
