import {
  Box,
  Container,
  Toolbar,
  styled,
  TextField,
  Backdrop,
  Fade,
  Modal,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import CustomButton, {
  ButtonSize,
  ButtonVariant,
} from "../components/button/CustomButton";
import UserList, { ListType } from "../components/CustomList";
import { domainApi } from "../redux/api/domainApi";
import CloseIcon from "@mui/icons-material/Close";

import { useAppDispatch } from "../redux/store";
import "./account-management-admin.scss";
import { ReactComponent as EditIcon } from "../assets/Edit.svg";

import { subscriptionApi } from "../redux/api/subscriptionApi";
import { accountsApi } from "../redux/api/accountsApi";
import FullScreenLoader from "../components/FullScreenLoader";
import { toast } from "react-toastify";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 60,
    paddingBottom: 14,
  },
}));

const modalStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  paddingTop: 4,
  paddingLeft: 4,
  paddingRight: 4,
  bgcolor: "#FFFFFF",
  boxShadow: "0px 2px 8px rgba(39, 65, 95, 0.2)",
  borderRadius: "8px",
};

const AccountManagementAdmin = () => {
  // const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [monthlyCosts, setMonthlyCosts] = useState<any>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>({
    account: null,
    plan_name: null,
    plan_id: null,
  });
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<any>([]);
  const [changePlanModal, setChangePlanModal] = useState<boolean>(false);
  const accountStr = sessionStorage.getItem("currentAccount");
  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;

  const handleClose = () => {
    setChangePlanModal(false);
  };

  const queryMultiple = () => {
    const res1 = subscriptionApi.endpoints.getSubscriptionPlansSu.useQuery(
      {
        account_id: accountJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res2 = accountsApi.endpoints.getAccountsSu.useQuery(
      {},
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    return [res1 as any, res2 as any];
  };

  const [
    { isLoading: loading1, data: res1, refetch: refetchPlans },
    { isLoading: loading2, data: res2, refetch: refetchStatus },
  ] = queryMultiple();

  useEffect(() => {
    if (res1 && res1.subscription_plans?.length) {
      setMonthlyCosts(res1.subscription_plans);
    } else {
      setMonthlyCosts([]);
    }
  }, [res1]);

  const handleOnSwitchAccount = async (account: any, value: string) => {
    if (account) {
      setLoading(true);
      let newStatus = value ? "enabled" : "disabled";
      console.log("hey", {
        object_type: "accounts",
        object_name: account.account_id,
        status_su: newStatus,
      });
      await dispatch(
        accountsApi.endpoints.adminAction.initiate(
          {
            object_type: "accounts",
            object_name: account.account_id,
            status_su: newStatus,
          },
          { forceRefetch: true }
        )
      );
      await refetchStatus();
      setLoading(false);
    }
  };

  const handleChangePlan = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (monthlyCosts && monthlyCosts.length) {
      const plan_id = monthlyCosts.filter(
        (m: any) => m?.plan_name === (event.target as HTMLInputElement).value
      )[0]?.plan_id;
      setSelectedPlan((prevState: any) => ({
        ...prevState,
        plan_name: (event.target as HTMLInputElement).value,
        plan_id: plan_id,
      }));
    }
  };

  const handleUpdateSubscription = async () => {
    setLoading(true);

    const res = await Promise.all(
      monthlyCosts.map((mc: any) => {
        return dispatch(
          subscriptionApi.endpoints.updatePrices.initiate({
            plan_id: mc.plan_id,
            pricing: mc.monthly_cost,
          })
        );
      })
    );
    await refetchPlans();
    const anyFailed = res.filter((q) => q.isSuccess === false);
    if (!anyFailed.length) {
      toast.success("Planes actualizados correctamente", {
        position: "top-right",
      });
    } else {
      toast.error("No se han podido actualizar los planes", {
        position: "top-right",
      });
    }
    setLoading(false);

    console.log(res);
  };

  const handleUpdateSubPlan = async () => {
    setLoading(true);

    const res = await dispatch(
      subscriptionApi.endpoints.updateSubscriptionSu.initiate({
        plan_id: selectedPlan.plan_id,
        account_id: selectedPlan.account,
      })
    );
    refetchStatus();
    if (res.isSuccess) {
      toast.success("Suscripción actualizada correctamente", {
        position: "top-right",
      });
    } else {
      toast.error("No se ha podido actualizar la suscripción", {
        position: "top-right",
      });
    }
    setLoading(false);
    setChangePlanModal(false);
  };

  if (loading || loading1 || loading2) {
    return <FullScreenLoader />;
  }
  const handleCostChange = (cost: string, plan: any) => {
    const resultArray: any = [];
    const filtered = monthlyCosts.filter(
      (mc: any) => mc.plan_id === plan.plan_id
    )[0];

    monthlyCosts.forEach((mc: any) => {
      if (mc.plan_id !== plan.plan_id) {
        resultArray.push(mc);
      } else {
        const copy = Object.assign({}, filtered, { monthly_cost: cost });
        resultArray.push(copy);
      }
    });
    setMonthlyCosts(resultArray);
  };

  const handleUpdateAccount = (e: any) => {
    setSelectedPlan({
      account: e.account_id,
      plan_name: e.plan_name,
      plan_id: e.plan_id,
    });
    setChangePlanModal(true);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "#ECF2F9",
        overflow: "hidden",
        paddingTop: 10,
        paddingRight: "10% !important",
        paddingLeft: "5% !important",
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          paddingTop: "30px",
          paddingLeft: "8% !important",
          height: "100vh",
        }}
      >
        <StyledToolbar disableGutters>
          <span className="header_txt">
            {/* {`${t("news-create.create-audio")}`} */}
            Gestión de cuenta SuperAdmin
          </span>
        </StyledToolbar>

        <Box
          sx={{
            display: "flex",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
          }}
        >
          <Box className="box_container">
            <Box pb={2}>
              <span className="subheader_txt">Gestión de cuentas</span>
            </Box>

            {res2?.accounts && res2?.accounts.length ? (
              <UserList
                values={res2.accounts}
                type={ListType.accounts}
                onSwitch={handleOnSwitchAccount}
                updateAccount={handleUpdateAccount}
              />
            ) : null}
          </Box>

          <Box className="box_bottom_container right">
            <span className="subheader_txt">
              {/* {`${t("news-create.create-audio")}`} */}
              Gestión de suscripciones
            </span>

            {monthlyCosts && monthlyCosts.length
              ? monthlyCosts.map((pt: any) => {
                return (
                  <Box
                    display={"flex"}
                    pt={"20px"}
                    pb={"20px"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      width={"50%"}
                    >
                      <span className="top_label">Tipo de plan</span>
                      <span className="non_editable_field">
                        {pt.plan_name}
                      </span>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      width={"50%"}
                    >
                      <span className="top_label">Precio</span>
                      <Box display={"flex"} alignItems={"center"}>
                        <EditIcon
                          fontSize="small"
                          className="edit_icon"
                          onClick={() => console.log("sd")}
                        />

                        <TextField
                          value={pt.monthly_cost}
                          margin={"none"}
                          variant={"standard"}
                          InputProps={{
                            disableUnderline: true,
                            inputMode: "numeric",
                          }}
                          onChange={(event) =>
                            handleCostChange(event.target.value, pt)
                          }
                          sx={{
                            width: "35px",
                            top: "4px",
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            "& .MuiOutlinedInput-root": {
                              height: "45px",
                            },
                          }}
                        />
                        <span className="non_editable_field_ma">€/mes</span>
                      </Box>
                    </Box>
                  </Box>
                );
              })
              : null}
            <Box display={"flex"} mt={"30px"} width={"50%"}>
              <CustomButton
                variant={ButtonVariant.primary}
                size={ButtonSize.large}
                fullSize={true}
                text={"Actualizar precios"}
                action={handleUpdateSubscription}
              />
            </Box>
          </Box>
        </Box>
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disableAutoFocus={true}
        open={changePlanModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={changePlanModal}>
          <Box sx={modalStyle}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box>
                <span className="subheader_txt">
                  Actualizar suscripción de cuenta
                </span>
              </Box>

              <Box>
                <CloseIcon className="close_icon" onClick={handleClose} />
              </Box>
            </Box>
            <FormControl>
              <RadioGroup
                value={selectedPlan.plan_name}
                name="radio-buttons-group"
                onChange={handleChangePlan}
              >
                {monthlyCosts && monthlyCosts.length
                  ? monthlyCosts.map((pt: any, index: number) => {
                    return (
                      <>
                        <Box
                          display={"flex"}
                          pt={"20px"}
                          pb={"20px"}
                          width={"100%"}
                        >
                          <FormControlLabel
                            value={pt.plan_name}
                            control={<Radio />}
                            label={""}
                          />

                          <Box display={"flex"} flexDirection={"column"}>
                            <span className="non_editable_field">
                              {pt.plan_name}
                            </span>
                            <Box display={"flex"} alignItems={"center"}>
                              <EditIcon
                                fontSize="small"
                                className="edit_icon"
                                onClick={() => console.log("sd")}
                              />
                              <span className="non_editable_field_ma">
                                {pt.monthly_cost}€/mes
                              </span>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    );
                  })
                  : null}
              </RadioGroup>
            </FormControl>

            <Box display={"flex"} mb={"30px"} width={"50%"}>
              <CustomButton
                variant={ButtonVariant.primary}
                size={ButtonSize.large}
                fullSize={true}
                text={"Actualizar suscripción"}
                action={handleUpdateSubPlan}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default withTranslation()(AccountManagementAdmin);
