import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import { ITypesApiRequest, ITypesApiResponse } from "./types";

export const typesApi = createApi({
  reducerPath: "typesApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getT2params: builder.query<ITypesApiResponse, ITypesApiRequest>({
      query(body) {
        return {
          url: `/get_t2s_params`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
  }),
});

export const { useGetT2paramsQuery } = typesApi;
