import React from "react";
import "./CustomTable.styles.scss";

import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import TitleIcon from "@mui/icons-material/Title";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import { Paper } from "@mui/material";
import { KpiByNewsId, TableData } from "../redux/api/types";
import { useNavigate } from "react-router-dom";

export enum TableType {
  dashboard,
  newList,
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof TableData | keyof KpiByNewsId;
  label: string;
  numeric: boolean;
}

const headCellsNews: readonly HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Id",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Título",
  },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Creado",
  },
  {
    id: "updated",
    numeric: true,
    disablePadding: false,
    label: "Actualizado",
  },
  {
    id: "state",
    numeric: true,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "plays",
    numeric: true,
    disablePadding: false,
    label: "Reproducciones",
  },
];

const headCellsDashboard: readonly HeadCell[] = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "Id",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Título",
  },
  {
    id: "loads",
    numeric: true,
    disablePadding: false,
    label: "Loads Totales",
  },
  {
    id: "plays",
    numeric: true,
    disablePadding: false,
    label: "Plays Totales",
  },
  {
    id: "q1",
    numeric: true,
    disablePadding: false,
    label: "Totales Q1",
  },
  {
    id: "q2",
    numeric: true,
    disablePadding: false,
    label: "Totales Q2",
  },
  {
    id: "q3",
    numeric: true,
    disablePadding: false,
    label: "Totales Q3",
  },
  {
    id: "q4",
    numeric: true,
    disablePadding: false,
    label: "Totales Q4",
  },
  {
    id: "total_listening_time",
    numeric: true,
    disablePadding: false,
    label: "Tiempo de escucha",
  },
];

const getHeaderIcon = (value: string) => {
  switch (value) {
    case "title":
      return <TitleIcon className="icon" fontSize="inherit" />;
    case "created":
      return <EditOutlinedIcon className="icon" fontSize="inherit" />;
    case "updated":
      return <AccessTimeOutlinedIcon className="icon" fontSize="inherit" />;
    case "state":
      return <SettingsOutlinedIcon className="icon" fontSize="inherit" />;
    case "plays":
      return (
        <SignalCellularAltOutlinedIcon className="icon" fontSize="inherit" />
      );
    default:
      return <TitleIcon className="icon" fontSize="inherit" />;
  }
};

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TableData | keyof KpiByNewsId
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  type: TableType;
  checkbox: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    type,
    checkbox,
  } = props;
  const createSortHandler =
    (property: keyof TableData | keyof KpiByNewsId) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

  return (
    <TableHead>
      <TableRow>
        {checkbox ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        ) : null}

        {type === TableType.newList
          ? headCellsNews.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <span>{getHeaderIcon(headCell.id)}</span>
                <span className="header_text">
                  {headCell.label.toUpperCase()}
                </span>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))
          : headCellsDashboard.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <span className="header_text">
                  {headCell.label.toUpperCase()}
                </span>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

type ITableProps = {
  type: TableType;
  values: TableData[] | KpiByNewsId[] | any;
  checkbox: boolean;
};

export const CustomTable: React.FC<ITableProps> = ({
  type,
  values,
  checkbox,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<
    keyof TableData | keyof KpiByNewsId
  >("plays");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TableData | keyof KpiByNewsId
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (values && event.target.checked) {
      const newSelected =
        type === TableType.newList
          ? values.map((n: TableData) => n.id)
          : values.map((n: KpiByNewsId) => n.news_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const id = row.id ? row.id : row.news_id;
    navigate("/news/edit/" + id, { state: row });
  };

  const handleClickCheckBox = (
    event: React.MouseEvent<unknown>,
    title: string
  ) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getState = (state: any) => {
    switch (state) {
      case "enabled":
        return <span className="state_block active">{state}</span>;
      case "disabled":
        return <span className="state_block inactive">{state}</span>;
      default:
        return null;
    }
  };

  const isSelected = (title: string) => selected.indexOf(title) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - values.length) : 0;

  if (values) {
    return (
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", overflow: "hidden", mb: 2 }}>
          <TableContainer
            sx={{
              maxHeight: "550px",
            }}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
              stickyHeader
            >
              <EnhancedTableHead
                numSelected={selected.length}
                type={type}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={values.length}
                checkbox={checkbox}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(values, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected =
                      type === TableType.newList
                        ? isSelected(row.id as string)
                        : isSelected(row.news_id as string);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    if (type === TableType.newList) {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {checkbox ? (
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                onClick={(event) =>
                                  handleClickCheckBox(event, row.id as string)
                                }
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                          ) : null}

                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding={checkbox ? "none" : "normal"}
                          >
                            <span className="row_text">{row.id}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">{row.title}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">
                              {row.created.toLocaleString()}
                            </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">
                              {row.updated.toLocaleString()}{" "}
                            </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">
                              {getState(row.state)}
                            </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">{row.plays}</span>
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.news_id}
                          selected={isItemSelected}
                        >
                          {checkbox ? (
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                onClick={(event) =>
                                  handleClickCheckBox(
                                    event,
                                    row.news_id as string
                                  )
                                }
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                          ) : null}

                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding={checkbox ? "none" : "normal"}
                          >
                            <span className="row_text">{row.news_id}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">{row.news_title}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">{row.loads}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">{row.plays} </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">{row.q1} </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">{row.q2} </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">{row.q3} </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">{row.q4} </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={(event) => handleClick(event, row)}
                          >
                            <span className="row_text">
                              {row.total_listening_time}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={values.length}
            sx={{
              "& .MuiInputBase-root": {
                position: "absolute",
                left: "165px",

                "& .MuiTablePagination-select": {
                  // position: 'fixed'
                },
              },
              "& .MuiTablePagination-selectLabel": {
                position: "absolute",
              },
              "& .MuiTablePagination-select": {
                // position: 'fixed',
              },
            }}
            labelRowsPerPage={"Mostrar filas por página"}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  } else {
    return <></>;
  }
};
