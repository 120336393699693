import {
  FormHelperText,
  Typography,
  FormControl,
  Input as _Input,
  InputProps,
  Slider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Dropdown, { BarSize, BarVariant } from "./Dropdown";
import "./FormInput.scss";
const Input = styled(_Input)`
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 3px;
  gap: 6px;
  background: #ffffff;
  border: 0.5px solid #27415f;
  border-radius: 4px;
`;
export enum FormInputType {
  input,
  dropdown,
  switch,
  slider,
}

type IFormInputProps = {
  name: string;
  label: string;
  placeholder?: string;
  formType: FormInputType;
  onChangeAction?: any;
  valuesDropdown?: any;
  selectedDropdown?: any;
  valuePitch?: any;
  maxValue?: number;
  minValue?: number;
} & InputProps;

const FormInput: FC<IFormInputProps> = ({
  name,
  label,
  placeholder,
  formType,
  onChangeAction,
  valuesDropdown,
  selectedDropdown,
  valuePitch,
  maxValue,
  minValue,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <FormControl fullWidth sx={{ mb: 2 }} className="form_input">
          {formType && FormInputType.dropdown === formType ? (
            <>
              <Typography className="top_label">{label}</Typography>

              <Dropdown
                label={label}
                onChange={onChangeAction}
                values={valuesDropdown}
                defaultSelected={selectedDropdown}
                key={1}
                multiple={false}
                size={BarSize.full}
                variant={BarVariant.form}
              />
            </>
          ) : formType && formType === FormInputType.slider ? (
            <>
              <Typography className="top_label">{label}</Typography>

              <Slider
                value={valuePitch}
                max={maxValue}
                min={minValue}
                valueLabelDisplay="auto"
                onChange={onChangeAction}
                size={"small"}
                sx={{
                  color: "#27415F",
                }}
              />
            </>
          ) : (
            <>
              <Typography className="top_label">{label}</Typography>
              <Input
                {...field}
                placeholder={placeholder}
                fullWidth
                disableUnderline
                error={!!errors[name]}
                // inputProps={{
                //   style: { backgroundColor: 'red', alignItems: 'center', verticalAlign: 'center' }
                // }}
                {...otherProps}
              />
              <FormHelperText error={!!errors[name]}>
                {errors[name] ? errors[name].message : ""}
              </FormHelperText>
            </>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormInput;
