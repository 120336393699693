import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import {
  GenericResponse,
  IAddNewRequest,
  ICreateAudioRequest,
  IGetNewsRequest,
  IListNewsRequest,
  INewResponse,
  INewsApiResponse,
  ISaveAsDraftRequest,
} from "./types";

export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    listNews: builder.query<INewsApiResponse, IListNewsRequest>({
      query(body) {
        return {
          url: `/news_list`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    listNewsM: builder.mutation<INewsApiResponse, IListNewsRequest>({
      query(body) {
        return {
          url: `/news_list`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    getNews: builder.query<INewResponse[], IGetNewsRequest>({
      query(body) {
        return {
          url: `/get_news`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    createAudio: builder.query<GenericResponse, ICreateAudioRequest>({
      query(body) {
        return {
          url: `/drafting_render`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    addNew: builder.query<GenericResponse, IAddNewRequest>({
      query(body) {
        return {
          url: `/news_add`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    saveAsDraft: builder.query<GenericResponse, ISaveAsDraftRequest>({
      query(body) {
        return {
          url: `/drafting_add`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //   'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
  }),
});

export const {
  useListNewsQuery,
  useGetNewsQuery,
  useCreateAudioQuery,
  useAddNewQuery,
  useSaveAsDraftQuery,
  useListNewsMMutation,
} = newsApi;
