import {
  Box,
  Container,
  Slider,
  styled,
  TextField,
  Toolbar,
} from "@mui/material";
import CustomButton from "../components/button/CustomButton";
import { ButtonSize, ButtonVariant } from "../components/button/CustomButton";
import "./news-create.scss";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink } from "react-router-dom";
import Dropdown, { BarSize, BarVariant } from "../components/Dropdown";
import { useEffect, useState } from "react";
// import { init } from '../../public/widget/js/AudioPlayer';
import { withTranslation } from "react-i18next";
import { InputDropdown, ITypesVoice } from "../redux/api/types";
import { typesApi } from "../redux/api/typesApi";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { newsApi } from "../redux/api/newsApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { userApi } from "../redux/api/userApi";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 60,
    paddingBottom: 14,
  },
}));

declare global {
  interface Window {
    AP: (value: any) => void;
  }
}

const LoadingButton = styled(_LoadingButton)`
  border-radius: 4px;
  padding: 8px 14px 5px 14px;
  background-color: #001834;
  color: #ffffff;
  font-weight: 500;

  &:hover {
    background-color: #ffc700;
    box-shadow: none;
    color: #001834;
  }
  ,
  &:active {
    background-color: #27415f;
    box-shadow: none;
    color: #ffffff;
  }
`;

const NewsCreate = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [newId, setNewId] = useState("");
  const [text, setText] = useState("");
  const [pitch, setPitch] = useState(0);
  const [speed, setSpeed] = useState(1);
  const [voices, setVoices] = useState<InputDropdown[]>([]);
  const [voiceTypes, setVoiceTypes] = useState<InputDropdown[]>([]);
  const [loading, setLoading] = useState(false);
  // const { t } = useTranslation();
  const breadcrumbs = useBreadcrumbs();
  const [random, setRandom] = useState(0);

  // const navigate = useNavigate();
  const domainStr = sessionStorage.getItem("currentDomain");
  const [iframeHtml, setIFrameHtml] = useState("");
  const domainJson =
    domainStr && domainStr !== "" ? JSON.parse(domainStr) : null;
  const [selectedVoiceType, setSelectedVoiceType] = useState<InputDropdown>();
  const [selectedVoice, setSelectedVoice] = useState<InputDropdown>();
  const dispatch = useAppDispatch();
  const accountStr = sessionStorage.getItem("currentAccount");
  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;
  const currentDomain = useAppSelector(
    (state) => state.domainState.currentDomain
  );
  const currentAccount = useAppSelector(
    (state) => state.accountState.currentAccont
  );

  const queryMultiple = () => {
    const res1 = typesApi.endpoints.getT2params.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res2 = userApi.endpoints.getUserRole.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    return [res1 as any, res2 as any];
  };

  const [
    { isLoading: loading1, data: res1, refetch: refetchT2Params },
    { isLoading: loading2, data: res2, refetch: refetchRol },
  ] = queryMultiple();

  useEffect(() => {
    setVoiceTypes([]);
    if (res1?.result?.voice?.length) {
      buildVoiceTypes(res1?.result?.voice);
    }
    if (res1?.result?.default_values) {
      const val = res1?.result?.default_values as any;
      setSelectedVoiceType({ id: "", name: val.default_voice_type });
      setSelectedVoice({ id: "", name: val.default_voice_name });
    }
  }, [res1]);

  useEffect(() => {
    setVoices([]);
    if (selectedVoiceType) {
      buildVoices(selectedVoiceType);
    }
  }, [selectedVoiceType]);

  const buildVoiceTypes = (voicesArray: ITypesVoice[]) => {
    const mapped = voicesArray.map((val, index) => {
      return createVoiceType(val, index);
    });
    setVoiceTypes(mapped);
  };

  const buildVoices = (voice: InputDropdown) => {
    const filtered = res1?.result?.voice.filter(
      (vt: any) => vt.voice_type === voice?.name
    );

    if (filtered?.length) {
      const mapped = filtered[0].voice_name.map((val: any, index: any) => {
        return createVoice(val, index);
      });

      setVoices(mapped);
    } else {
      setVoices([]);
    }
  };

  function createVoiceType(value: ITypesVoice, index: number): InputDropdown {
    return {
      id: index.toString(),
      name: value.voice_type,
    };
  }

  function createVoice(value: string, index: number): InputDropdown {
    return {
      id: index.toString(),
      name: value,
    };
  }

  const getBreadcrumbName = (breadcrumb: any) => {
    switch (breadcrumb) {
      case "/news":
        return "Listado de audios";
      case "/news/create":
        return "Crear audio";
      default:
        break;
    }
    return "";
  };

  const handlePublish = async () => {
    const res = await dispatch(
      newsApi.endpoints.addNew.initiate({
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
        news_id: newId,
        news_name: title,
        news_text: text,
        news_status: "enabled",
        render_voice_name: selectedVoice?.name ? selectedVoice?.name : "",
        render_voice_type: selectedVoiceType?.name
          ? selectedVoiceType?.name
          : "",
        render_pitch: pitch ? pitch.toString() : "",
        render_speed: speed ? speed.toString() : "",
      })
    );
    navigate("/news");
  };

  const handleSaveDraft = async () => {
    // const res = await dispatch(
    //   newsApi.endpoints.saveAsDraft.initiate({
    //     drafting_text: text,
    //     news_id: newId
    //   })
    // );
    await dispatch(
      newsApi.endpoints.addNew.initiate({
        account_id: accountJson?.id,
        domain_id: domainJson.id,
        news_id: newId,
        news_name: title,
        news_text: text,
        news_status: "disabled",
        render_voice_name: selectedVoice?.name ? selectedVoice?.name : "",
        render_voice_type: selectedVoiceType?.name
          ? selectedVoiceType?.name
          : "",
        render_pitch: pitch ? pitch.toString() : "",
        render_speed: speed ? speed.toString() : "",
      })
    );
    navigate("/news");
  };

  const handleGenerate = async () => {
    if (newId !== "" && text !== "" && selectedVoiceType && selectedVoice) {
      console.log("IN");
      setLoading(true);
      const res = await dispatch(
        newsApi.endpoints.createAudio.initiate({
          account_id: accountJson?.id,
          news_id: newId,
          news_text: text,
          render_voice_type: selectedVoiceType?.name,
          render_voice_name: selectedVoice?.name,
          render_pitch: pitch.toString(),
          render_speed: speed.toString(),
        })
      );
      const result = res.data as any;

      setIFrameHtml(
        `
        <div class="player" ></div>
        <link rel="stylesheet" href="` +
        window.location.origin +
        `/widget/css/AudioPlayer.css" />

        <script src="` +
        window.location.origin +
        `/widget/js/AudioPlayer.js"></script>

        <script>
        let path = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
        window.AP.init({
          container: ".player",
          newsId: "` +
        newId +
        `",
          accountId: ` +
        accountJson?.id +
        `,
          newsName: "` +
        title +
        `",
          host: '` +
        window.location.origin +
        `',
          domainName: 'https://` +
        domainJson.name +
        `',
          auth: true,
          audio_title: '` +
        title +
        `',
          audio_url: '` +
        result?.audio_url +
        `'
        });
      </script>
        `
      );
      setRandom(random + 1);
      setLoading(false);
    }
  };

  const handleChangeSpeed = (event: any, value: any) => {
    setSpeed(value);
  };
  const handleChangePitch = (event: any, value: any) => {
    setPitch(value);
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "#ECF2F9",
        overflow: "hidden",
        paddingTop: 10,
        paddingRight: "10% !important",
        paddingLeft: "5% !important",
      }}
    >
      {breadcrumbs.map(({ match }, index) => (
        <NavLink
          key={match.pathname}
          to={match.pathname}
          className={"breadcrumb"}
        >
          <span
            className={
              match.pathname === "/news/create"
                ? "breadcrumb current"
                : "breadcrumb"
            }
          >
            {getBreadcrumbName(match.pathname)}
          </span>
          {index === 0 || index === breadcrumbs.length - 1
            ? null
            : breadcrumbs.length > index
              ? " > "
              : null}
        </NavLink>
      ))}
      <Container
        maxWidth={false}
        sx={{
          paddingTop: "30px",
          paddingLeft: "8% !important",
          height: "100%",
          paddingBottom: "80px",
        }}
      >
        <StyledToolbar disableGutters>
          <span className="header_txt">
            {/* {`${t("news-create.create-audio")}`} */}
            Crear audio
          </span>
        </StyledToolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
            marginBottom: "32px",
            boxShadow: "0px 2px 8px rgba(39, 65, 95, 0.2)",
            borderRadius: "8px",
          }}
        >
          <Box className="box txt">
            <span className="subheader_txt">Texto</span>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "100%" },
              }}
              noValidate
              pb={"20px"}
              autoComplete="off"
            >
              <span className="top_label">Título</span>
              <TextField
                placeholder="Título"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "20px",
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                    "& fieldset": {
                      borderColor: "#27415F",
                    },
                    // '&:hover fieldset': {
                    //   borderColor: 'white',
                    // },
                    // '&.Mui-focused fieldset': {
                    //   borderColor: 'yellow',
                    // },
                  },
                }}
                value={title}
                autoComplete={"off"}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />{" "}
              <span className="top_label">News ID</span>
              <TextField
                placeholder="Identificador de la noticia"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "20px",
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                    "& fieldset": {
                      borderColor: "#27415F",
                    },
                    // '&:hover fieldset': {
                    //   borderColor: 'white',
                    // },
                    // '&.Mui-focused fieldset': {
                    //   borderColor: 'yellow',
                    // },
                  },
                }}
                autoComplete={"off"}
                value={newId}
                onChange={(e) => {
                  setNewId(e.target.value);
                }}
              />
            </Box>

            <span className="top_label">Texto</span>

            <TextField
              placeholder="Artículo con 5000 caracteres"
              inputProps={{ maxLength: 5000 }}
              multiline={true}
              rows={10}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                paddingBottom: "20px",
                minHeight: "300px",
                boxSizing: "border-box",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#27415F",
                  },
                  // '&:hover fieldset': {
                  //   borderColor: 'white',
                  // },
                  // '&.Mui-focused fieldset': {
                  //   borderColor: 'yellow',
                  // },
                },
              }}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </Box>
          <Box className="box audio">
            <span className="subheader_txt">Audio</span>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Box className="box_selector" pb={"20px"}>
                <Box className="selector" mr={"20px"}>
                  <span className="top_label">Tipo de voz</span>
                  <Dropdown
                    label="Voz"
                    onChange={(voiceTypeInput: InputDropdown) =>
                      setSelectedVoiceType(voiceTypeInput)
                    }
                    defaultSelected={selectedVoiceType}
                    values={voiceTypes}
                    key={2}
                    multiple={false}
                    size={BarSize.full}
                    variant={BarVariant.form}
                  />
                </Box>
                <Box className="selector">
                  <span className="top_label">Voz</span>
                  <Dropdown
                    label="Voz"
                    onChange={(voiceInput: InputDropdown) =>
                      setSelectedVoice(voiceInput)
                    }
                    values={voices}
                    defaultSelected={selectedVoice}
                    key={1}
                    multiple={false}
                    size={BarSize.full}
                    variant={BarVariant.form}
                  />
                </Box>
              </Box>

              <Box className="box_selector">
                <Box className="selector" mr={"20px"}>
                  <span className="top_label">Pitch</span>
                  <Slider
                    value={pitch}
                    valueLabelDisplay="auto"
                    max={20}
                    min={-20}
                    onChange={(event, value) => handleChangePitch(event, value)}
                    size={"small"}
                    sx={{
                      color: "#27415F",
                    }}
                  />
                </Box>
                <Box className="selector">
                  <span className="top_label">Velocidad</span>
                  <Slider
                    value={speed}
                    max={4}
                    valueLabelDisplay="auto"
                    min={0.25}
                    onChange={(event, value) => handleChangeSpeed(event, value)}
                    size={"small"}
                    sx={{
                      color: "#27415F",
                    }}
                  />
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"end"} mt={"30px"}>
                <LoadingButton
                  variant="contained"
                  disableElevation
                  type="button"
                  onClick={() => handleGenerate()}
                  loading={loading}
                >
                  Regenerar audio
                </LoadingButton>

                {/* <CustomButton
                  variant={ButtonVariant.primary}
                  size={ButtonSize.small}
                  text={"Regenerar audio"}
                  action={() => handleGenerate()}
                /> */}
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              {iframeHtml && iframeHtml !== "" ? (
                <iframe
                  title="player"
                  style={{ border: 0, width: "100%" }}
                  srcDoc={iframeHtml}
                ></iframe>
              ) : null}
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <span className="header_bottom_txt">Historial de versiones</span>
              <span className="subheader_bottom_txt">
                Ninguna versión de audio en el Historial.
              </span>
              <span className="bottom_txt">
                Para crear una versión configura los parámetros de audio y
                guarda como borrador.
              </span>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent={"end"} sx={{ ml: "auto" }}>
          <CustomButton
            variant={ButtonVariant.secondary}
            mr={2}
            size={ButtonSize.medium}
            width={200}
            text={"Guardar como borrador"}
            action={handleSaveDraft}
          />

          {!loading2 && res2?.role !== "viewer" ? (
            <CustomButton
              variant={ButtonVariant.primary}
              size={ButtonSize.medium}
              width={200}
              text={"Publicar"}
              action={handlePublish}
            />
          ) : null}
        </Box>
      </Container>
    </Container>
  );
};

export default withTranslation()(NewsCreate);
