import { Box, CircularProgress, Container } from "@mui/material";

const ComponentLoader = () => {
  return (
    <Container sx={{}}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
};

export default ComponentLoader;
