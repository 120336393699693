import * as React from "react";
import Box from "@mui/material/Box";
import "./KpiCard.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
type ICardProps = {
    topText: string;
    bottomText?: string;
    bottomNumber?: number;
    cards?: ISubCard[];
    type: CardType;
    action?: any;
    icon?: CardIconType;
    quarterValues?: any[];
};

export type ISubCard = {
    id: string;
    topText: string;
    bottomText?: string;
};

export enum CardIconType {
    arrow,
}

enum FilterType {
    daily,
    weekly,
    monthly,
}

export enum CardType {
    kpiNumber,
    quarters,
    timers,
    kpiString,
    button,
    group,
}

const KpiCard: React.FC<ICardProps> = ({
    topText,
    bottomText,
    bottomNumber,
    cards,
    type,
    action,
    icon,
    quarterValues,
}) => {
    const [currentFilter, setCurrentFilter] = React.useState<FilterType>(
        FilterType.daily
    );

    const handleChangeFilter = (current: FilterType) => {
        setCurrentFilter(current);
    };
    const getSpacer = () => {
        return <div className="separator" />;
    };

    const getWidth = () => {
        let divider = cards?.length ? cards?.length : 1;
        return 100 / divider + "%";
    };
    const getIcon = () => {
        switch (icon) {
            case CardIconType.arrow:
                return <KeyboardArrowRightIcon className="card_icon" />;

            default:
                break;
        }
    };

    const getRadioGroup = () => {
        return (
            <Box display={"flex"} alignSelf={"flex-start"}>
                <Box
                    className={
                        currentFilter === FilterType.daily ? "filter_selected" : "filter"
                    }
                >
                    <span
                        className={
                            currentFilter === FilterType.daily
                                ? "filter_selected_txt"
                                : "filter_txt"
                        }
                        onClick={() => handleChangeFilter(FilterType.daily)}
                    >
                        24h
                    </span>
                </Box>
                <Box
                    ml={0.5}
                    className={
                        currentFilter === FilterType.weekly ? "filter_selected" : "filter"
                    }
                >
                    <span
                        className={
                            currentFilter === FilterType.weekly
                                ? "filter_selected_txt"
                                : "filter_txt"
                        }
                        onClick={() => handleChangeFilter(FilterType.weekly)}
                    >
                        Semanal
                    </span>
                </Box>
                <Box
                    ml={0.5}
                    className={
                        currentFilter === FilterType.monthly ? "filter_selected" : "filter"
                    }
                >
                    <span
                        className={
                            currentFilter === FilterType.monthly
                                ? "filter_selected_txt"
                                : "filter_txt"
                        }
                        onClick={() => handleChangeFilter(FilterType.monthly)}
                    >
                        Mensual
                    </span>
                </Box>
            </Box>
        );
    };

    const getCard = (type: CardType) => {
        switch (type) {
            case CardType.kpiNumber:
                return (
                    <Box className="kpi_card">
                        <span className="kpi_top_text">{topText}</span>

                        <span className="kpi_number_bottom_text">{bottomNumber}</span>
                    </Box>
                );
            case CardType.kpiString:
                return (
                    <Box className="kpi_card">
                        <span className="kpi_top_text">{topText}</span>

                        <span className="kpi_str_bottom_text">{bottomText}</span>
                    </Box>
                );
            case CardType.quarters:
                return (
                    <Box className="kpi_card">
                        <span className="kpi_top_text">{topText}</span>

                        <Box display={"flex"} flexDirection={"column"}>
                            {quarterValues?.map((qv, index) => {
                                return (
                                    <Box
                                        key={index}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <span className="quarter_pre_txt">Q{++index}: </span>
                                        <span className="quarter_txt">{qv}</span>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                );
            case CardType.timers:
                return (
                    <Box className="kpi_card">
                        <span className="kpi_top_text">{topText}</span>
                        <Box display={"flex"} flexDirection={"column"}>
                            {quarterValues?.map((qv, index) => {
                                return (
                                    <Box
                                        key={index}
                                        display={"flex"}
                                        width={"100%"}
                                        alignItems={"center"}
                                    >
                                        <span className="quarter_txt">{qv}</span>
                                        <span className="quarter_pre_txt">
                                            {index === 0
                                                ? "hora"
                                                : index === 1
                                                    ? "minutos"
                                                    : index === 2
                                                        ? "segundos"
                                                        : null}
                                        </span>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                );
            case CardType.button:
                return (
                    <Box className="kpi_card_button">
                        <Box>
                            <span className="kpi_top_button_txt">{topText}</span>
                        </Box>
                        <Box
                            display={"flex"}
                            width={"100%"}
                            justifyContent={"space-between"}
                        >
                            <Box>
                                <span onClick={action} className="kpi_bottom_button_txt">
                                    {bottomText}
                                </span>
                            </Box>
                            <Box>
                                <span onClick={action}>{getIcon()}</span>
                            </Box>
                        </Box>
                    </Box>
                );
            case CardType.group:
                return (
                    <Box className="kpi_card_macro">
                        <Box display={"flex"}>
                            <Box
                                display={"flex"}
                                p={0}
                                flexDirection={"row"}
                                alignItems={"center"}
                                gap={"16px"}
                            >
                                {cards?.map((card, index) => {
                                    return (
                                        <Box
                                            display={"flex"}
                                            width={getWidth()}
                                            minHeight={"142px"}
                                            mt={4}
                                            key={index}
                                        >
                                            <Box
                                                display={"flex"}
                                                flexDirection={"column"}
                                                width={"100%"}
                                                alignItems={"flex-start"}
                                                justifyContent={"space-between"}
                                                p={1}
                                                gap={1}
                                            >
                                                <Box>
                                                    <span className="mini_card_top">{card.topText}</span>
                                                </Box>
                                                <Box>
                                                    <span className="mini_card_bot" onClick={action}>
                                                        {card.bottomText}
                                                    </span>
                                                </Box>
                                            </Box>
                                            {index !== cards.length - 1 ? (
                                                <Box>{getSpacer()}</Box>
                                            ) : (
                                                <></>
                                            )}
                                        </Box>
                                    );
                                })}
                                {getRadioGroup()}
                            </Box>
                        </Box>
                    </Box>
                );
            default:
                break;
        }
    };
    return <>{getCard(type)}</>;
};
export default KpiCard;
