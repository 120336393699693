import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Container, Toolbar, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
// import { useLocation, useNavigate } from "react-router-dom";
import { object, string, TypeOf } from "zod";
import UserList, { ListType } from "../components/CustomList";
import Dropdown, { BarSize, BarVariant } from "../components/Dropdown";
import FullScreenLoader from "../components/FullScreenLoader";
import { domainApi } from "../redux/api/domainApi";
import {
  IInviteUserRequest,
  IListUsersAdminResponse,
} from "../redux/api/types";
import { userApi } from "../redux/api/userApi";
import { useAppDispatch } from "../redux/store";
import { LoadingButton as _LoadingButton } from "@mui/lab";

import "./user-management-admin.scss";
import { toast } from "react-toastify";
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 60,
    paddingBottom: 14,
  },
}));

const UserManagementAdmin = () => {
  // const { t } = useTranslation();
  // const navigate = useNavigate();
  // const location = useLocation();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const accountStr = sessionStorage.getItem("currentAccount");
  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;

  const { data, isLoading, isFetching, refetch } =
    userApi.endpoints.listUsersSu.useQuery(
      {
        account_id: accountJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );

  const updateStatus = async (value: any) => {
    if (value) {
      let newStatus = value.user_status === "enabled" ? "disabled" : "enabled";
      setLoading(true);
      await dispatch(
        userApi.endpoints.updateUserStatusSu.initiate(
          {
            user_id: value.user_id,
            user_status: newStatus,
          },
          { forceRefetch: true }
        )
      );
      await refetch();

      setLoading(false);
    }
  };

  const remove = async (value: any) => {
    if (value) {
      setLoading(true);
      const res = await dispatch(
        userApi.endpoints.removeUser.initiate({
          user_id: value.user_id,
        })
      );
      refetch();
      setLoading(false);
      if (res.isSuccess) {
        toast.success("Usuario eliminado correctamente", {
          position: "top-right",
        });
      } else {
        toast.error("No se ha podido eliminar el usuario", {
          position: "top-right",
        });
      }
    }
  };

  if (loading || isLoading || isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <Container
      maxWidth={false}
      className="user"
      sx={{
        backgroundColor: "#ECF2F9",
        overflow: "hidden",
        paddingTop: 10,
        paddingRight: "10% !important",
        paddingLeft: "5% !important",
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          paddingTop: "30px",
          paddingLeft: "8% !important",
          height: "100vh",
        }}
      >
        <StyledToolbar disableGutters>
          <span className="header_txt">
            {/* {`${t("news-create.create-audio")}`} */}
            Gestión de usuarios SuperAdmin
          </span>
        </StyledToolbar>
        <Box className="box_container">
          {data && (
            <UserList
              values={data.users}
              type={ListType.userSu}
              onSwitch={updateStatus}
              onDelete={remove}
            />
          )}
        </Box>
      </Container>
    </Container>
  );
};

export default withTranslation()(UserManagementAdmin);
