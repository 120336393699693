import {
  Box,
  CircularProgress,
  Container,
  Slider,
  styled,
  TextField,
  Toolbar,
} from "@mui/material";
import CustomButton from "../components/button/CustomButton";
import { ButtonSize, ButtonVariant } from "../components/button/CustomButton";
import "./news-edit.scss";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink, useLocation } from "react-router-dom";
import Dropdown, { BarSize, BarVariant } from "../components/Dropdown";
import { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  IDrafting,
  INewResponse,
  InputDropdown,
  ITypesVoice,
  KpiByNewsId,
  stateValues,
  TableData,
} from "../redux/api/types";
import { newsApi } from "../redux/api/newsApi";
import FullScreenLoader from "../components/FullScreenLoader";
import { typesApi } from "../redux/api/typesApi";
import DraftingList, { ListType } from "../components/CustomList";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { useIframeLoaded } from "../hooks/useIframeLoaded";
import { userApi } from "../redux/api/userApi";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 60,
    paddingBottom: 14,
  },
}));

declare global {
  interface Window {
    AP: (value: any) => void;
  }
}

function createVoiceType(value: ITypesVoice, index: number): InputDropdown {
  return {
    id: index.toString(),
    name: value.voice_type,
  };
}

function createVoice(value: string, index: number): InputDropdown {
  return {
    id: index.toString(),
    name: value,
  };
}

const LoadingButton = styled(_LoadingButton)`
  border-radius: 4px;
  padding: 8px 14px 5px 14px;
  background-color: #001834;
  color: #ffffff;
  font-weight: 500;

  &:hover {
    background-color: #ffc700;
    box-shadow: none;
    color: #001834;
  }
  ,
  &:active {
    background-color: #27415f;
    box-shadow: none;
    color: #ffffff;
  }
`;

const NewsEdit = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [newId, setNewId] = useState("");
  const [text, setText] = useState("");
  const [status, setStatus] = useState("");
  const [pitch, setPitch] = useState(0);
  const [speed, setSpeed] = useState(1);
  const [random, setRandom] = useState(0);
  const [iframeHtml, setIframeHtml] = useState<string>("");
  const [newResponse, setNewResponse] = useState<INewResponse>();
  const [voices, setVoices] = useState<InputDropdown[]>([]);
  const [voiceTypes, setVoiceTypes] = useState<InputDropdown[]>([]);
  const [selectedVoiceType, setSelectedVoiceType] = useState<InputDropdown>();
  const [selectedVoice, setSelectedVoice] = useState<InputDropdown>();
  // const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const breadcrumbs = useBreadcrumbs();
  // const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as TableData | KpiByNewsId | any;
  const domainStr = sessionStorage.getItem("currentDomain");
  const [loading, setLoading] = useState(false);
  const [loadingFrame, setLoadingFrame] = useState(true);
  const domainJson =
    domainStr && domainStr !== "" ? JSON.parse(domainStr) : null;
  const [selectedDraft, setSelectedDraft] = useState<IDrafting>();
  const accountStr = sessionStorage.getItem("currentAccount");
  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const isIFrameLoaded = useIframeLoaded(iframeRef);

  const currentDomain = useAppSelector(
    (state) => state.domainState.currentDomain
  );
  const currentAccount = useAppSelector(
    (state) => state.accountState.currentAccont
  );

  const { isLoading, isFetching, refetch } = newsApi.endpoints.getNews.useQuery(
    {
      news_id: !state
        ? window.location.pathname.substring(
          window.location.pathname.indexOf("edit/") + 5
        )
        : state.id
          ? state.id
          : state.news_id,
    },
    {
      skip: false,
      refetchOnMountOrArgChange: true,
    }
  );

  const newsApiResponse = newsApi.endpoints.getNews.useQueryState(
    {
      news_id: !state
        ? window.location.pathname.substring(
          window.location.pathname.indexOf("edit/") + 5
        )
        : state.id
          ? state.id
          : state.news_id,
    },
    {
      selectFromResult: ({ data }) => data,
    }
  );

  const queryMultiple = () => {
    const res1 = typesApi.endpoints.getT2params.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res2 = userApi.endpoints.getUserRole.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    return [res1 as any, res2 as any];
  };

  const [
    { isLoading: loading1, data: res1, refetch: refetchT2Params },
    { isLoading: loading2, data: res2, refetch: refetchRol },
  ] = queryMultiple();

  useEffect(() => {
    if (isLoading || isFetching || loading1 || loading2) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading, isFetching, loading1, loading2]);
  useEffect(() => {
    setVoiceTypes([]);
    if (res1?.result?.voice?.length) {
      buildVoiceTypes(res1?.result?.voice);
    }
    if (res1?.result?.default_values) {
      const val = res1?.result?.default_values as any;
      setSelectedVoiceType({ id: "", name: val.default_voice_type });
      setSelectedVoice({ id: "", name: val.default_voice_name });
    }
  }, [res1]);

  useEffect(() => {
    setVoices([]);
    if (selectedVoiceType) {
      buildVoices(selectedVoiceType);
    }
  }, [selectedVoiceType]);

  const handlePublish = async () => {
    const res = await dispatch(
      newsApi.endpoints.addNew.initiate({
        account_id: accountJson?.id,
        domain_id: domainJson.id,
        news_id: newId,
        news_name: title,
        news_text: text,
        news_status: "enabled",
        render_voice_name: selectedVoice?.name ? selectedVoice?.name : "",
        render_voice_type: selectedVoiceType?.name
          ? selectedVoiceType?.name
          : "",
        render_pitch: pitch ? pitch.toString() : "",
        render_speed: speed ? speed.toString() : "",
      })
    );
    navigate("/news");
  };

  const handleSaveDraft = async () => {
    // const res = await dispatch(
    //   newsApi.endpoints.saveAsDraft.initiate({
    //     drafting_text: text,
    //     news_id: newId
    //   })
    // );
    setLoading(true);
    await dispatch(
      newsApi.endpoints.addNew.initiate({
        account_id: accountJson?.id,
        domain_id: domainJson.id,
        news_id: newId,
        news_name: title,
        news_text: text,
        news_status: "disabled",
        render_voice_name: selectedVoice?.name ? selectedVoice?.name : "",
        render_voice_type: selectedVoiceType?.name
          ? selectedVoiceType?.name
          : "",
        render_pitch: pitch ? pitch.toString() : "",
        render_speed: speed ? speed.toString() : "",
      })
    );
    navigate("/news");
  };

  const buildVoiceTypes = (voicesArray: ITypesVoice[]) => {
    const mapped = voicesArray.map((val, index) => {
      return createVoiceType(val, index);
    });
    setVoiceTypes(mapped);
  };

  const buildVoices = (voice: InputDropdown) => {
    const filtered = res1?.result?.voice?.filter(
      (vt: any) => vt.voice_type === voice.name
    );

    if (filtered?.length) {
      const mapped = filtered[0].voice_name.map((val: any, index: any) => {
        return createVoice(val, index);
      });

      setVoices(mapped);
    } else {
      setVoices([]);
    }
  };

  useEffect(() => {
    if (newsApiResponse?.length) {
      setNewResponse(newsApiResponse[0]);
    }
  }, [newsApiResponse]);

  // console.log(window.location)
  useEffect(() => {
    if (newResponse) {
      const current = newResponse?.draftings?.filter(
        (draft) => draft.name === newResponse?.news_audio_id
      )[0];
      setSelectedDraft(current);
      setTitle(newResponse?.news_title);
      setNewId(newResponse?.news_id);
      setStatus(newResponse?.news_status);
      setText(current?.text);
      setIframeHtml(
        `
  <div class="player" ></div>
  <link rel="stylesheet" href="` +
        window.location.origin +
        `/widget/css/AudioPlayer.css" />

  <script src="` +
        window.location.origin +
        `/widget/js/AudioPlayer.js"></script>

  <script>
  let path = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
  window.AP.init({
    container: ".player",
    newsId: "` +
        newResponse?.news_id +
        `",
    accountId: ` +
        accountJson?.id +
        `,
    newsName: "` +
        newResponse?.news_name +
        `",
    host: '` +
        window.location.origin +
        `',
    domainName: 'https://` +
        domainJson?.name +
        `',
    auth: true,
  });
</script>
  `
      );
    }
  }, [newResponse]);

  useEffect(() => {
    if (selectedDraft) {
      setText(selectedDraft.text);
    }
  }, [selectedDraft]);

  const getBreadcrumbName = (breadcrumb: any) => {
    switch (breadcrumb) {
      case "/news":
        return "Listado de audios";
      case "/news/create":
        return "Crear audio";
      case "/news/edit":
        return "Editar audio";
      default:
        break;
    }
    return "";
  };

  const handleGenerate = async () => {
    if (newId !== "" && text !== "" && selectedVoiceType && selectedVoice) {
      setLoading(true);
      setLoadingFrame(true);
      const res = await dispatch(
        newsApi.endpoints.createAudio.initiate({
          account_id: accountJson?.id,
          news_id: newId,
          news_text: text,
          render_voice_type: selectedVoiceType?.name,
          render_voice_name: selectedVoice?.name,
          render_pitch: pitch.toString(),
          render_speed: speed.toString(),
        })
      );
      const result = res.data as any;
      setIframeHtml(
        `
        <div class="player" ></div>
        <link rel="stylesheet" href="` +
        window.location.origin +
        `/widget/css/AudioPlayer.css" />

        <script src="` +
        window.location.origin +
        `/widget/js/AudioPlayer.js"></script>

        <script>
        let path = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
        window.AP.init({
          container: ".player",
          newsId: "` +
        newResponse?.news_id +
        `",
          accountId: ` +
        accountJson?.id +
        `,
          newsName: "` +
        newResponse?.news_name +
        `",
          host: '` +
        window.location.origin +
        `',
          domainName: 'https://` +
        domainJson.name +
        `',
          auth: true,
          audio_title: '` +
        title +
        `',
          audio_url: '` +
        result?.audio_url +
        `'
        });
      </script>
        `
      );
      setRandom(random + 1);
      setLoading(false);
    }
  };

  const handleChangeStatus = () => {
    if (status) {
      if (status === "enabled") {
        setStatus("disabled");
      } else if (status === "disabled") {
        setStatus("enabled");
      }
    }
  };

  const getDefaultStatus = () => {
    if (status === "enabled") {
      return { id: "1", name: "Activo" };
    } else if (status === "disabled") {
      return { id: "2", name: "Inactivo" };
    }
  };

  const handleChangeSpeed = (event: any, value: any) => {
    setSpeed(value);
  };
  const handleChangePitch = (event: any, value: any) => {
    setPitch(value);
  };
  const handleChangeDraft = (draft: IDrafting) => {
    // console.log(draft);
    setSelectedDraft(draft);
  };
  // if (loading) {
  //   return <FullScreenLoader />;
  // }
  const handleLoadFrame = () => {
    setLoadingFrame(false);
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "#ECF2F9",
        overflow: "hidden",
        paddingTop: 10,
        paddingRight: "10% !important",
        paddingLeft: "5% !important",
      }}
    >
      {breadcrumbs.map(({ match }, index) => (
        <NavLink
          key={match.pathname}
          to={match.pathname}
          className={"breadcrumb"}
        >
          <span
            className={
              match.pathname === "/news/create" ||
                match.pathname.includes("/news/edit")
                ? "breadcrumb current"
                : "breadcrumb"
            }
          >
            {getBreadcrumbName(match.pathname)}
          </span>
          {index === 0 || index === breadcrumbs.length - 1
            ? null
            : breadcrumbs.length > index
              ? " > "
              : null}
        </NavLink>
      ))}
      <Container
        maxWidth={false}
        sx={{
          paddingTop: "30px",
          paddingLeft: "8% !important",
          height: "100%",
          paddingBottom: "80px",
        }}
      >
        <StyledToolbar disableGutters>
          <span className="header_txt">
            {/* {`${t("news-create.create-audio")}`} */}
            Editar audio
          </span>
        </StyledToolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
            marginBottom: "32px",
            boxShadow: "0px 2px 8px rgba(39, 65, 95, 0.2)",
            borderRadius: "8px",
          }}
        >
          <Box className="box txt">
            <span className="subheader_txt">Texto</span>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "100%" },
              }}
              noValidate
              pb={"20px"}
              autoComplete="off"
            >
              <span className="top_label">Título</span>

              <TextField
                placeholder="Título"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "20px",
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                    "& fieldset": {
                      borderColor: "#27415F",
                    },
                    // '&:hover fieldset': {
                    //   borderColor: 'white',
                    // },
                    // '&.Mui-focused fieldset': {
                    //   borderColor: 'yellow',
                    // },
                  },
                }}
              />
              <span className="top_label">News ID</span>

              <TextField
                placeholder="Identificador de la noticia"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "20px",
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                    "& fieldset": {
                      borderColor: "#27415F",
                    },
                    // '&:hover fieldset': {
                    //   borderColor: 'white',
                    // },
                    // '&.Mui-focused fieldset': {
                    //   borderColor: 'yellow',
                    // },
                  },
                }}
                disabled={true}
                autoComplete={"off"}
                value={newId}
              />

              <Box className="box_selector">
                <Box className="selector" mr={"20px"}>
                  <span className="top_label">Estado</span>
                  <Dropdown
                    label="Estado"
                    onChange={handleChangeStatus}
                    values={stateValues}
                    defaultSelected={getDefaultStatus()}
                    key={2}
                    multiple={true}
                    size={BarSize.full}
                    variant={BarVariant.form}
                  />
                </Box>
              </Box>
            </Box>

            <span className="top_label">Texto</span>

            <TextField
              placeholder="Artículo con 5000 caracteres"
              inputProps={{ maxLength: 5000 }}
              multiline={true}
              rows={10}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                paddingBottom: "20px",
                minHeight: "300px",
                boxSizing: "border-box",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#27415F",
                  },
                  // '&:hover fieldset': {
                  //   borderColor: 'white',
                  // },
                  // '&.Mui-focused fieldset': {
                  //   borderColor: 'yellow',
                  // },
                },
              }}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </Box>
          <Box className="box audio">
            <span className="subheader_txt">Audio</span>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Box className="box_selector" pb={"20px"}>
                <Box className="selector" mr={"20px"}>
                  <span className="top_label">Tipo de voz</span>
                  <Dropdown
                    label="Voz"
                    onChange={(voiceTypeInput: InputDropdown) =>
                      setSelectedVoiceType(voiceTypeInput)
                    }
                    defaultSelected={selectedVoiceType}
                    values={voiceTypes}
                    key={2}
                    multiple={false}
                    size={BarSize.full}
                    variant={BarVariant.form}
                  />
                </Box>
                <Box className="selector">
                  <span className="top_label">Voz</span>
                  <Dropdown
                    label="Voz"
                    onChange={(voiceInput: InputDropdown) =>
                      setSelectedVoice(voiceInput)
                    }
                    values={voices}
                    defaultSelected={selectedVoice}
                    key={1}
                    multiple={false}
                    size={BarSize.full}
                    variant={BarVariant.form}
                  />
                </Box>
              </Box>

              <Box className="box_selector">
                <Box className="selector" mr={"20px"}>
                  <span className="top_label">Pitch</span>
                  <Slider
                    value={pitch}
                    max={20}
                    min={-20}
                    valueLabelDisplay="auto"
                    onChange={(event, value) => handleChangePitch(event, value)}
                    size={"small"}
                    sx={{
                      color: "#27415F",
                    }}
                  />
                </Box>
                <Box className="selector">
                  <span className="top_label">Velocidad</span>
                  <Slider
                    value={speed}
                    max={4}
                    valueLabelDisplay="auto"
                    min={0.25}
                    onChange={(event, value) => handleChangeSpeed(event, value)}
                    size={"small"}
                    sx={{
                      color: "#27415F",
                    }}
                  />
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"end"} mt={"30px"}>
                <LoadingButton
                  variant="contained"
                  disableElevation
                  type="button"
                  onClick={() => handleGenerate()}
                  loading={loading}
                >
                  Regenerar audio
                </LoadingButton>
                {/* <CustomButton
                  variant={ButtonVariant.primary}
                  size={ButtonSize.small}
                  text={"Regenerar audio"}
                  action={() => handleGenerate()}
                /> */}
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              {loadingFrame ? (
                <Box mt={2} display={"flex"} justifyContent={"center"}>
                  <CircularProgress />
                </Box>
              ) : null}
              <iframe
                ref={iframeRef}
                onLoad={handleLoadFrame}
                title="player"
                key={random}
                style={{ border: 0, width: "100%" }}
                srcDoc={iframeHtml}
              ></iframe>
            </Box>
            {newResponse?.draftings?.length ? (
              <>
                <Box mt={-3} display={"flex"} flexDirection={"column"}>
                  <span className="version_history_txt">
                    Historial de versiones
                  </span>
                  <DraftingList
                    values={newResponse.draftings}
                    type={ListType.drafts}
                    onEdit={handleChangeDraft}
                    newId={newResponse.active_drafting}
                  />
                </Box>
              </>
            ) : (
              <Box display={"flex"} flexDirection={"column"}>
                <span className="header_bottom_txt">
                  Historial de versiones
                </span>
                <span className="subheader_bottom_txt">
                  Ninguna versión de audio en el Historial.
                </span>
                <span className="bottom_txt">
                  Para crear una versión configura los parámetros de audio y
                  guarda como borrador.
                </span>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent={"end"} sx={{ ml: "auto" }}>
          <CustomButton
            variant={ButtonVariant.secondary}
            mr={2}
            size={ButtonSize.medium}
            width={200}
            text={"Guardar como borrador"}
            action={handleSaveDraft}
          />
          {res2?.role !== "viewer" ? (
            <CustomButton
              variant={ButtonVariant.primary}
              size={ButtonSize.medium}
              width={200}
              text={"Publicar"}
              action={handlePublish}
            />
          ) : null}
        </Box>
      </Container>
    </Container>
  );
};

export default withTranslation()(NewsEdit);
