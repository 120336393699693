import { Box, Container, Toolbar, styled, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import CustomButton, {
  ButtonSize,
  ButtonVariant,
} from "../components/button/CustomButton";
import FullScreenLoader from "../components/FullScreenLoader";
import { InputDropdown, ISubscriptionPlan } from "../redux/api/types";
import "./change-subscription.scss";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useAppDispatch } from "../redux/store";

import Dropdown, { BarSize, BarVariant } from "../components/Dropdown";
import { subscriptionApi } from "../redux/api/subscriptionApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { userApi } from "../redux/api/userApi";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 60,
    paddingBottom: 14,
  },
}));

function createPlanType(value: ISubscriptionPlan): InputDropdown {
  return {
    id: value.plan_id,
    name: value.plan_alias,
  };
}

const ChangeSubscription = () => {
  // const { t } = useTranslation();
  // const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cif, setCif] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [company, setCompany] = useState("");
  const [legalRep, setLegalRep] = useState("");
  const [email, setEmail] = useState("");
  const [fiscAdd, setFiscAdd] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [nameCard, setNameCard] = useState("");
  const [numCard, setNumCard] = useState("");
  const [caducity, setCaducity] = useState("");
  const [cvv, setCvv] = useState("");
  const [selectedPlan, setSelectedPlan] = useState<InputDropdown>();
  const breadcrumbs = useBreadcrumbs();
  const [planTypes, setPlanTypes] = useState<InputDropdown[]>([]);
  // const [selectedPlanType, setSelectedPlanType] = useState<InputDropdown>();
  const accountStr = sessionStorage.getItem("currentAccount");
  const accountJson =
    accountStr && accountStr !== "" ? JSON.parse(accountStr) : null;
  const domainStr = sessionStorage.getItem("currentDomain");
  const domainJson =
    domainStr && domainStr !== "" ? JSON.parse(domainStr) : null;

  const buildPlanTypes = (planArray: ISubscriptionPlan[]) => {
    const mapped = planArray.map((val) => {
      return createPlanType(val);
    });
    setPlanTypes(mapped);
  };

  const queryMultiple = () => {
    const res1 = subscriptionApi.endpoints.getSubscriptionPlans.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res2 = subscriptionApi.endpoints.getAccountTaxData.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res3 = subscriptionApi.endpoints.getUserPaymentData.useQuery(
      {
        account_id: accountJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    const res4 = userApi.endpoints.getUserRole.useQuery(
      {
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
      },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
    return [res1 as any, res2 as any, res3 as any, res4 as any];
  };

  const [
    { isLoading: loading1, data: res1 },
    { isLoading: loading2, data: res2, refetch: refetchStatus },
    { isLoading: loading3, data: res3 },
    { isLoading: loading4, data: res4, refetch: refetchUserRole },
  ] = queryMultiple();

  useEffect(() => {
    setPlanTypes([]);
    if (res1) {
      const filtered = res1?.plans.filter(
        (p: any) => p.plan_id === res1.actual_plan_id
      )[0];

      setSelectedPlan({
        id: filtered?.plan_id,
        name: filtered?.plan_alias,
      } as InputDropdown);
      if (res1.plans?.length) {
        buildPlanTypes(res1.plans);
      }
    }
  }, [res1]);

  useEffect(() => {
    if (res2) {
      setCif(res2.cif);
      setCity(res2.ciudad);
      setPostalCode(res2.codigo_postal);
      setEmail(res2.email);
      setCompany(res2.nombre_compania);
      setCountry(res2.pais);
      setProvince(res2.provincia);
      setLegalRep(res2.representante_legal);
      setPhoneNumber(res2.telefono);
      setFiscAdd(res2.direccion_fiscal);
    }
  }, [res2]);

  useEffect(() => {
    if (res3 && res3.card_data) {
      setCaducity(
        res3.card_data.card_exp_month + "/" + res3.card_data.card_exp_year
      );
      setNumCard(res3.card_data.card_number);
    }
  }, [res3]);

  useEffect(() => {
    if (res4?.role === "admin") {
      navigate("/account");
    }
  }, [res4]);

  if (loading || loading1 || loading2 || loading3 || loading4) {
    return <FullScreenLoader />;
  }

  const getCurrentPlan = () => {
    if (res1) {
      const filtered = res1.plans.filter(
        (p: any) => p.plan_id === res1.actual_plan_id
      )[0];
      setSelectedPlan(filtered);
      return filtered?.plan_alias;
    }
  };

  const handleSaveSubscription = async () => {
    setLoading(true);

    const res = await dispatch(
      subscriptionApi.endpoints.updateTaxData.initiate({
        domain_id: domainJson?.id,
        account_id: accountJson?.id,
        cif: cif,
        ciudad: city,
        codigo_postal: postalCode,
        direccion_fiscal: fiscAdd,
        email: email,
        nombre_compania: company,
        pais: country,
        provincia: province,
        representante_legal: legalRep,
        telefono: phoneNumber,
      })
    );
    setLoading(false);
    if (res.isSuccess) {
      toast.success("Datos guardados correctamente", {
        position: "top-right",
      });
    } else {
      toast.error("No se han podido guardar los datos", {
        position: "top-right",
      });
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    const res = await dispatch(
      subscriptionApi.endpoints.updateSubscription.initiate({
        account_id: accountJson?.id,
        domain_id: domainJson?.id,
        plan_id: selectedPlan ? selectedPlan.id : "",
        stripe_card_cvc: cvv,
        stripe_card_exp_month: caducity.split("/")[0],
        stripe_card_exp_year: caducity.split("/")[1],
        stripe_card_number: numCard,
        stripe_card_name: nameCard,
      })
    );
    setLoading(false);
    if (res.isSuccess) {
      toast.success("Pago actualizado correctamente", {
        position: "top-right",
      });
    } else {
      toast.error("No se ha podido actualizado el pago", {
        position: "top-right",
      });
    }
  };
  const handleChangePlan = (value: InputDropdown) => {
    setSelectedPlan(value);
  };

  const getBreadcrumbName = (breadcrumb: any) => {
    switch (breadcrumb) {
      case "/account/subscription":
        return "Cambio de suscripción";
      case "/account":
        return "Gestión de cuenta";
      default:
        break;
    }
    return "";
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "#ECF2F9",
        height: "100vh",
        overflow: "hidden",
        paddingTop: 10,
        paddingRight: "10% !important",
        paddingLeft: "5% !important",
      }}
    >
      {breadcrumbs.map(({ match }, index) => (
        <NavLink
          key={match.pathname}
          to={match.pathname}
          className={"breadcrumb"}
        >
          <span
            className={
              match.pathname === "/account/subscription"
                ? "breadcrumb current"
                : "breadcrumb"
            }
          >
            {getBreadcrumbName(match.pathname)}
          </span>
          {index === 0 || index === breadcrumbs.length - 1
            ? null
            : breadcrumbs.length > index
              ? " > "
              : null}
        </NavLink>
      ))}
      <Container
        maxWidth={false}
        sx={{
          paddingTop: "30px",
          paddingLeft: "8% !important",
          height: "100%",
        }}
      >
        <StyledToolbar disableGutters>
          <span className="header_txt">
            {/* {`${t("news-create.create-audio")}`} */}
            Cambio de suscripción
          </span>
        </StyledToolbar>

        <Box
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          mb={"100px"}
          justifyContent={"space-between"}
        >
          <Box
            display={"flex"}
            width={"48%"}
            borderRadius={"8px"}
            boxShadow={" 0px 2px 8px rgba(39, 65, 95, 0.2)"}
            sx={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <Box className="box_sub_bottom_container">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "100%" },
                }}
                noValidate
                pb={"20px"}
                autoComplete="off"
              >
                <Box>
                  <span className="subheader_txt">
                    {/* {`${t("news-create.create-audio")}`} */}
                    Datos fiscales
                  </span>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Box width={"100%"} mr={"20px"}>
                      <span className="top_label">CIF</span>
                      <TextField
                        placeholder="CIF"
                        value={cif}
                        onChange={(e) => {
                          setCif(e.target.value);
                        }}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          marginBottom: "20px",
                          "& .MuiOutlinedInput-root": {
                            height: "45px",
                            "& fieldset": {
                              borderColor: "#27415F",
                            },
                            // '&:hover fieldset': {
                            //   borderColor: 'white',
                            // },
                            // '&.Mui-focused fieldset': {
                            //   borderColor: 'yellow',
                            // },
                          },
                        }}
                      />
                    </Box>
                    <Box width={"100%"}>
                      <span className="top_label">Teléfono</span>
                      <TextField
                        placeholder="Teléfono"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          marginBottom: "20px",
                          "& .MuiOutlinedInput-root": {
                            height: "45px",
                            "& fieldset": {
                              borderColor: "#27415F",
                            },
                            // '&:hover fieldset': {
                            //   borderColor: 'white',
                            // },
                            // '&.Mui-focused fieldset': {
                            //   borderColor: 'yellow',
                            // },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Box width={"100%"} mr={"20px"}>
                      <span className="top_label">Nombre de la empresa</span>
                      <TextField
                        placeholder="Nombre de la empresa"
                        value={company}
                        onChange={(e) => {
                          setCompany(e.target.value);
                        }}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          marginBottom: "20px",
                          "& .MuiOutlinedInput-root": {
                            height: "45px",
                            "& fieldset": {
                              borderColor: "#27415F",
                            },
                            // '&:hover fieldset': {
                            //   borderColor: 'white',
                            // },
                            // '&.Mui-focused fieldset': {
                            //   borderColor: 'yellow',
                            // },
                          },
                        }}
                      />
                    </Box>
                    <Box width={"100%"}>
                      <span className="top_label">Representante legal</span>
                      <TextField
                        placeholder="Representante legal"
                        value={legalRep}
                        onChange={(e) => {
                          setLegalRep(e.target.value);
                        }}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          marginBottom: "20px",
                          "& .MuiOutlinedInput-root": {
                            height: "45px",
                            "& fieldset": {
                              borderColor: "#27415F",
                            },
                            // '&:hover fieldset': {
                            //   borderColor: 'white',
                            // },
                            // '&.Mui-focused fieldset': {
                            //   borderColor: 'yellow',
                            // },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <span className="top_label">E-mail</span>
                  <TextField
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "20px",
                      "& .MuiOutlinedInput-root": {
                        height: "45px",
                        "& fieldset": {
                          borderColor: "#27415F",
                        },
                        // '&:hover fieldset': {
                        //   borderColor: 'white',
                        // },
                        // '&.Mui-focused fieldset': {
                        //   borderColor: 'yellow',
                        // },
                      },
                    }}
                  />
                  <span className="top_label">Dirección Fiscal</span>
                  <TextField
                    placeholder="Dirección Fiscal"
                    value={fiscAdd}
                    onChange={(e) => {
                      setFiscAdd(e.target.value);
                    }}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "20px",
                      "& .MuiOutlinedInput-root": {
                        height: "45px",
                        "& fieldset": {
                          borderColor: "#27415F",
                        },
                        // '&:hover fieldset': {
                        //   borderColor: 'white',
                        // },
                        // '&.Mui-focused fieldset': {
                        //   borderColor: 'yellow',
                        // },
                      },
                    }}
                  />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Box width={"100%"} mr={"20px"}>
                    <span className="top_label">País</span>
                    <TextField
                      placeholder="País"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "20px",
                        "& .MuiOutlinedInput-root": {
                          height: "45px",
                          "& fieldset": {
                            borderColor: "#27415F",
                          },
                          // '&:hover fieldset': {
                          //   borderColor: 'white',
                          // },
                          // '&.Mui-focused fieldset': {
                          //   borderColor: 'yellow',
                          // },
                        },
                      }}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <span className="top_label">Provincia</span>
                    <TextField
                      placeholder="Provincia"
                      value={province}
                      onChange={(e) => {
                        setProvince(e.target.value);
                      }}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "20px",
                        "& .MuiOutlinedInput-root": {
                          height: "45px",
                          "& fieldset": {
                            borderColor: "#27415F",
                          },
                          // '&:hover fieldset': {
                          //   borderColor: 'white',
                          // },
                          // '&.Mui-focused fieldset': {
                          //   borderColor: 'yellow',
                          // },
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Box width={"100%"} mr={"20px"}>
                    <span className="top_label">Ciudad</span>
                    <TextField
                      placeholder="Ciudad"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "20px",
                        "& .MuiOutlinedInput-root": {
                          height: "45px",
                          "& fieldset": {
                            borderColor: "#27415F",
                          },
                          // '&:hover fieldset': {
                          //   borderColor: 'white',
                          // },
                          // '&.Mui-focused fieldset': {
                          //   borderColor: 'yellow',
                          // },
                        },
                      }}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <span className="top_label">Código Postal</span>
                    <TextField
                      placeholder="Código Postal"
                      value={postalCode}
                      onChange={(e) => {
                        setPostalCode(e.target.value);
                      }}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "20px",
                        "& .MuiOutlinedInput-root": {
                          height: "45px",
                          "& fieldset": {
                            borderColor: "#27415F",
                          },
                          // '&:hover fieldset': {
                          //   borderColor: 'white',
                          // },
                          // '&.Mui-focused fieldset': {
                          //   borderColor: 'yellow',
                          // },
                        },
                      }}
                    />
                  </Box>
                </Box>

                <Box display={"flex"} mt={"30px"} width={"100%"}>
                  <CustomButton
                    variant={ButtonVariant.primary}
                    size={ButtonSize.large}
                    fullSize={true}
                    text={"Guardar"}
                    action={handleSaveSubscription}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"48%"}
            borderRadius={"8px"}
            boxShadow={" 0px 2px 8px rgba(39, 65, 95, 0.2)"}
            alignSelf={"flex-start"}
            pb={2}
            sx={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <Box className="box_sub_bottom_container">
              <span className="subheader_txt">
                {/* {`${t("news-create.create-audio")}`} */}
                Actualizar suscripción
              </span>
              <Box>
                <span className="top_label">Tipo de Plan</span>
                <Dropdown
                  label="Plan"
                  onChange={handleChangePlan}
                  defaultSelected={selectedPlan}
                  values={planTypes}
                  key={2}
                  multiple={false}
                  size={BarSize.full}
                  variant={BarVariant.form}
                />
              </Box>
            </Box>
            <Box className="box_sub_bottom_container">
              <span className="subheader_txt">
                {/* {`${t("news-create.create-audio")}`} */}
                Datos de pago
              </span>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "100%" },
                }}
                noValidate
                pt={"20px"}
                pb={"20px"}
                autoComplete="off"
              >
                <span className="top_label">Nombre de la tarjeta</span>
                <TextField
                  placeholder="Nombre"
                  value={nameCard}
                  onChange={(e) => {
                    setNameCard(e.target.value);
                  }}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "20px",
                    "& .MuiOutlinedInput-root": {
                      height: "45px",
                      "& fieldset": {
                        borderColor: "#27415F",
                      },
                      // '&:hover fieldset': {
                      //   borderColor: 'white',
                      // },
                      // '&.Mui-focused fieldset': {
                      //   borderColor: 'yellow',
                      // },
                    },
                  }}
                />
              </Box>
              <Box display={"flex"} width={"100%"}>
                <Box width={"60%"} mr={"20px"}>
                  <span className="top_label">Número de la tarjeta</span>
                  <TextField
                    placeholder="Número"
                    value={numCard}
                    onChange={(e) => {
                      setNumCard(e.target.value);
                    }}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "20px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        height: "45px",
                        "& fieldset": {
                          borderColor: "#27415F",
                        },
                        // '&:hover fieldset': {
                        //   borderColor: 'white',
                        // },
                        // '&.Mui-focused fieldset': {
                        //   borderColor: 'yellow',
                        // },
                      },
                    }}
                  />
                </Box>
                <Box width={"25%"} mr={"20px"}>
                  <span className="top_label">Caducidad</span>
                  <TextField
                    placeholder="Fecha"
                    value={caducity}
                    onChange={(e) => {
                      if (
                        e.target.value.length === 2 &&
                        !e.target.value.includes("/")
                      ) {
                        setCaducity(e.target.value + "/");
                      } else {
                        setCaducity(e.target.value);
                      }
                    }}
                    inputProps={{ maxLength: 5 }}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "20px",
                      "& .MuiOutlinedInput-root": {
                        height: "45px",

                        "& fieldset": {
                          borderColor: "#27415F",
                        },
                        // '&:hover fieldset': {
                        //   borderColor: 'white',
                        // },
                        // '&.Mui-focused fieldset': {
                        //   borderColor: 'yellow',
                        // },
                      },
                    }}
                  />
                </Box>
                <Box width={"15%"}>
                  <span className="top_label">CVV</span>
                  <TextField
                    placeholder="CVV"
                    value={cvv}
                    onChange={(e) => {
                      setCvv(e.target.value);
                    }}
                    inputProps={{ maxLength: 3 }}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "20px",
                      "& .MuiOutlinedInput-root": {
                        height: "45px",
                        "& fieldset": {
                          borderColor: "#27415F",
                        },
                        // '&:hover fieldset': {
                        //   borderColor: 'white',
                        // },
                        // '&.Mui-focused fieldset': {
                        //   borderColor: 'yellow',
                        // },
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box display={"flex"} mt={1} width={"100%"}>
                <CustomButton
                  variant={ButtonVariant.primary}
                  size={ButtonSize.large}
                  fullSize={true}
                  text={"Pagar"}
                  action={handlePayment}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default withTranslation()(ChangeSubscription);
