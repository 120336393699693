import { createApi } from "@reduxjs/toolkit/query/react";
// import { setUser } from '../features/userSlice';
import customFetchBase from "./customFetchBase";
import {
  IGetRolesRequest,
  IGetUserData,
  IGetUserRoleRequest,
  IInviteUserRequest,
  IInviteUserResponse,
  IListUsersAdminResponse,
  IListUsersRequest,
  IListUsersResponse,
  IRemoveUserPriv,
  IRemoveUserSuRequest,
  IUpdateUserStatusSuRequest,
  IUserUpdateRequest,
} from "./types";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: customFetchBase,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    listUsers: builder.query<IListUsersResponse[], IListUsersRequest>({
      query(body) {
        return {
          url: `/account_list`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    updateUserStatusSu: builder.query<any, IUpdateUserStatusSuRequest>({
      query(body) {
        return {
          url: `/su/user_update_status`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    updateUser: builder.query<any, IUserUpdateRequest>({
      query(body) {
        return {
          url: `/user_update`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    getRoles: builder.query<any, IGetRolesRequest>({
      query(body) {
        return {
          url: `/get_roles`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    getUserRole: builder.query<any, IGetUserRoleRequest>({
      query(body) {
        return {
          url: `/get_user_role`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "application/json",
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    getUserData: builder.query<any, IGetUserData>({
      query(body) {
        return {
          url: `/get_owner_data`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),

    removeUser: builder.query<any, IRemoveUserSuRequest>({
      query(body) {
        return {
          url: `/su/delete_user`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),

    removeUserPriv: builder.query<any, IRemoveUserPriv>({
      query(body) {
        return {
          url: `/user_delete`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    listUsersSu: builder.query<IListUsersAdminResponse, any>({
      query(body) {
        return {
          url: `/su/list_users`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    inviteUser: builder.query<IInviteUserResponse[], IInviteUserRequest>({
      query(body) {
        return {
          url: `/user_invite`,
          method: "POST",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            //    'Content-Type': 'application/json',
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "*"
          },
          body,
        };
      },
    }),
    getMe: builder.query<any, null>({
      query() {
        return {
          url: "users/me",
          credentials: "include",
        };
      },
      transformResponse: (result: { data: { user: any } }) => result.data.user,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(setUser(data));
        } catch (error) {}
      },
    }),
  }),
});
