import { Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput, { FormInputType } from "../components/FormInput";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoadingButton as _LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import "./forgot-password.page.scss";
import { ReactComponent as Logo } from "../assets/voicetoyou_2.svg";
import { useResetPasswordMutation } from "../redux/api/authApi";

const LoadingButton = styled(_LoadingButton)`
  border-radius: 0px 0px 8px 8px;
  padding: 0.6rem 0;
  background-color: #001834;
  color: #ffffff;
  font-weight: 500;

  &:hover {
    background-color: #ffc700;
    box-shadow: none;
    color: #001834;
  }
  ,
  &:active {
    background-color: #27415f;
    box-shadow: none;
    color: #ffffff;
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: #2363eb;
  &:hover {
    text-decoration: underline;
  }
`;
const forgotPasswordSchema = object({
  email: string()
    .nonempty("Debes introducir tu correo electrónico")
    .email("Correo electrónico no válido"),
});

export type ForgotPasswordInput = TypeOf<typeof forgotPasswordSchema>;

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);

  const methods = useForm<ForgotPasswordInput>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  // 👇 API Login Mutation
  const [resetPassword, { isLoading, isError, error, isSuccess, data }] =
    useResetPasswordMutation();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
    }
    if (isError) {
      console.log(error);
      if (Array.isArray((error as any).error)) {
        (error as any).error.forEach((el: any) =>
          toast.error(el, {
            position: "top-right",
          })
        );
      } else {
        toast.error((error as any).error, {
          position: "top-right",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<ForgotPasswordInput> = ({ email }) => {
    // 👇 Executing the forgotPassword Mutation
    // forgotPassword({ email });
    setEmail(email);
    resetPassword({ email: email }).catch((err) => console.error(err));
  };

  if (isSuccess || show) {
    return (
      <Container maxWidth={false} className={"verify_page"}>
        <Box className="logo">
          <Logo />
        </Box>
        <Box className="inside_container">
          <Typography
            textAlign="center"
            component="h1"
            className="verify_header"
          >
            Verifica tu correo
          </Typography>

          <Typography
            textAlign="center"
            component="h2"
            className="verify_subheader"
          >
            Hemos enviado un enlace para restablecer la contraseña al correo:
          </Typography>
          <Typography textAlign="center" className="email_typo" component="h2">
            {email}
          </Typography>

          <Box maxWidth="27rem" width="100%" mt={3}>
            <Box className="login_box">
              <Typography className="received">
                ¿No has recibido el correo?
              </Typography>
            </Box>
            <LoadingButton
              variant="contained"
              fullWidth
              disableElevation
              type="submit"
              loading={isLoading}
            >
              Enviar de nuevo
            </LoadingButton>
          </Box>
          <Typography className="no_account" sx={{ mt: "1rem" }}>
            Volver a la pantalla de{" "}
            <LinkItem className="login_here" to="/login">
              Inicio de sesión
            </LinkItem>
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth={false} className="forgot_page">
      <Box className="logo">
        <Logo />
      </Box>
      <Box className="inside_container">
        <Typography textAlign="center" component="h1" className="verify_header">
          Recuperar contraseña
        </Typography>

        <Typography
          textAlign="center"
          component="h2"
          className="verify_subheader"
        >
          Enviaremos instrucciones a tu correo para restablecer la contraseña.
        </Typography>

        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
            maxWidth="27rem"
            width="100%"
            mt={3}
          >
            <Box className="login_box">
              <FormInput
                formType={FormInputType.input}
                name="email"
                label="E-mail"
                className="input_txt"
                type="email"
                placeholder="Introduce tu correo electrónico"
              />
            </Box>

            <LoadingButton
              variant="contained"
              fullWidth
              disableElevation
              type="submit"
              loading={isLoading}
            >
              Recuperar contraseña
            </LoadingButton>
          </Box>

          <Typography className="no_account" sx={{ mt: "1rem" }}>
            Volver a la pantalla de{" "}
            <LinkItem className="register_here" to="/login">
              Inicio de sesión
            </LinkItem>
          </Typography>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage;
